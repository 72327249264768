import { useState } from "react";

import { InfoBoxComponent } from "@components";
import { IDescription } from "@interfaces";

interface IProps {
    data: IDescription[];
}
export const InfoBoxModule = (props: IProps) => {
    const [currentTagIndex, setCurrentTagIndex] = useState(0);
    const { data } = props;
    const handleEnter = (index: number) => {
        setCurrentTagIndex(index);
    };

    return (
        <InfoBoxComponent data={data} currentTagIndex={currentTagIndex} _onEnter={handleEnter} />
    );
};
