import styled from "styled-components";
import { useDispatch } from "react-redux";
import { memo } from "react";

import { BreadcrumbsCommon } from "@components";
import { PATH_CART, PATH_HOME } from "@routes";
import { breakpoint } from "@utilities";
import { selectCart, useAppSelector, setStep, AppDispatch } from "@redux";
import { CartConstants } from "@constants";
import { IBreadcrumbs } from "@interfaces";
interface IProps {
    currentPoint: number;
}

interface IPoint {
    active: boolean;
}

interface IProcessOrderHeaderProps {
    stepLabels: string[],
    activeStepIndex: number,
    breadcrumbs: IBreadcrumbs[],
    // allow changing step
    changeStep: (newStepIndex: number) => void,
}

export const ProcessOrderHeader = memo((props: IProcessOrderHeaderProps) => {
    const { breadcrumbs, stepLabels, activeStepIndex, changeStep } = props;
    return (
        <StyledContainer>
            <div className="main">
                <div className="breadcrumbs">
                    <BreadcrumbsCommon data={breadcrumbs} />
                </div>
                {stepLabels.map((stepLabel, stepLabelIndex) => {
                    const isActiveStep = stepLabelIndex === activeStepIndex;
                return (
                    <StyledPoint key={stepLabelIndex} active={!isActiveStep}>
                        <button
                            className="number"
                            disabled={isActiveStep}
                            // disable pointer cusor if is active step or higher steps than active step
                            style={{
                                cursor: isActiveStep
                                    ? 'auto'
                                    : stepLabelIndex > activeStepIndex ? 'not-allowed' : 'pointer',
                            }}
                            onClick={() => {
                                if (stepLabelIndex < activeStepIndex) { // only allow going back stpe
                                    changeStep(stepLabelIndex);
                                }
                    }}>
                            {stepLabelIndex + 1}
                            <span className="title">{stepLabel}</span>
                        </button>
                        {stepLabelIndex !== stepLabels.length - 1 && <div className="line"></div>}
                    </StyledPoint>
                );
                })}
            </div>
            
        </StyledContainer>
    );
});

export const ProcessOrderComponent = memo((props: IProps) => {
    //prop
    const { currentPoint } = props;
    //redux state
    const cartRedux = useAppSelector(selectCart);
    const currentStep = cartRedux.currentStep;
    //page hook
    const dispatch = useDispatch<AppDispatch>();
    //page variable
    const breadcrumbsModel = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Cart",
            link: PATH_CART,
        },
    ];
    const points = [
        { number: CartConstants.CART, title: "Cart" },
        { number: CartConstants.CHECKOUT, title: "Check out" },
        { number: CartConstants.PAYMENT, title: "Payment" },
        { number: CartConstants.ORDER_SUCCESS, title: "Order" },
    ];

    const handleClickPoint = (step: number) => {
        if (currentPoint !== points.length) dispatch(setStep(step));
    };

    return (
        <StyledContainer>
            <div className="main">
                <div className="breadcrumbs">
                    <BreadcrumbsCommon data={breadcrumbsModel} />
                </div>
                {points.map((item, index) => {
                    const active = item.number <= currentPoint;
                    const disabled =
                        cartRedux.carts.length > 0
                            ? item.number === CartConstants.CART
                                ? false
                                : item.number === CartConstants.CHECKOUT &&
                                    (currentStep === CartConstants.CHECKOUT ||
                                        currentStep === CartConstants.PAYMENT)
                                  ? false
                                  : item.number === CartConstants.PAYMENT &&
                                      currentStep === CartConstants.PAYMENT
                                    ? !cartRedux.canCheckout
                                    : true
                            : true;

                    return (
                        <StyledPoint key={item.number} active={active}>
                            <button
                                className="number"
                                onClick={() => handleClickPoint(item.number)}
                                disabled={disabled}
                            >
                                {item.number}
                                <span className="title">{item.title}</span>
                            </button>
                            {index !== points.length - 1 && <div className="line"></div>}
                        </StyledPoint>
                    );
                })}
            </div>
        </StyledContainer>
    );
});

const StyledContainer = styled.div`
    ${breakpoint.breakTablet`
        background-color: #F2F2F2;
    `}

    .main {
        display: flex;
        justify-content: center;
        margin: 50px auto;
        position: relative;

        ${breakpoint.breakTablet`
            width: ${(p) => p.theme.widths.wide_700};
            margin: 90px auto;
        `}

        ${breakpoint.breakIpadPro`
            width: ${(p) => p.theme.widths.wide_900};
        `}

        ${breakpoint.breakLarge`
            width: ${(p) => p.theme.widths.wide_1110};
        `}

        .breadcrumbs {
            position: absolute;
            left: 0;
            top: -40px;

            ${breakpoint.breakOnlyMobile`
                left: 16px;
            `}
        }
    }
`;

const StyledPoint = styled.div<IPoint>`
    display: flex;
    align-items: center;

    .number {
        cursor: pointer;
        position: relative;
        width: 24px;
        height: 24px;
        text-align: center;
        background-color: ${(props) =>
            props.active ? props.theme.colors.main : props.theme.colors.gray_1};
        border-radius: 50%;
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        margin: 0 4px;
    }

    .line {
        width: 60px;
        border-bottom: 1px dashed #bdbdbd;

        ${breakpoint.breakTablet`
            width: 133px;
        `}
    }

    .title {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: ${(props) => (props.active ? props.theme.colors.main : props.theme.colors.gray_1)};
        font-weight: 500;
        font-size: 14px;
        white-space: nowrap;
        line-height: 22px;
        margin-top: 5px;
    }
`;
