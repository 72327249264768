import { IAccount, ICountryData } from "@interfaces";
import { PATH_MY_ACCOUNT } from "@routes";
import { breakpoint } from "@utilities";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

interface MyDetailsProps {
    countriesData: ICountryData[];
    account: IAccount;
}

export const MyDetailsComponent = ({ countriesData, account }: MyDetailsProps) => {
    const getCountryName = (targetCode: string | undefined) =>
        _.get(_.find(countriesData, { countryCode: targetCode }), "name", "Not Found");

    return (
        <Container>
            <Title>My Details</Title>
            <DetailsBox>
                <Header>
                    <Name>
                        {account?.first_name} {account?.last_name}
                    </Name>
                    <EditLink to={PATH_MY_ACCOUNT} type="button">
                        Edit
                    </EditLink>
                </Header>
                <InfoContainer>
                    <Row>
                        <IconText>
                            <img src="/images/phone_22.svg" alt="phone" />
                            <p>{account?.phone_number}</p>
                        </IconText>
                        <IconText>
                            <img src="/images/mail_22.svg" alt="mail" />
                            <p className="email">{account?.email}</p>
                        </IconText>
                    </Row>
                    <IconText>
                        <img src="/images/location_22.svg" alt="location" />
                        <p>
                            {account?.post_code}, {getCountryName(account?.country_code)}
                        </p>
                    </IconText>
                </InfoContainer>
            </DetailsBox>
        </Container>
    );
};

const Container = styled.div`
    background-color: #fff;
    padding: 28px 28px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
    border-radius: 4px;
    ${breakpoint.breakOnlyMobile`
        padding : 18px;
    `}
`;

const Title = styled.h1`
    font-size: 20px;
    font-weight: 600;
`;

const DetailsBox = styled.div`
    border: 1px solid #ade1e3;
    border-radius: 10px;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    ${breakpoint.breakOnlyMobile`
        padding: 14px;
    `}
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Name = styled.h1`
    color: #131214;
    font-size: 16px;
    font-weight: bold;
`;

const EditLink = styled(NavLink)`
    color: #31b4b9;
    font-size: 14px;
    font-weight: 600;
`;

const InfoContainer = styled.div`
    color: #5a5a5a;
`;

const Row = styled.div`
    display: flex;
    gap: 16px;
`;

const IconText = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    height: 30px;

    ${breakpoint.breakOnlyMobile`
        font-size: 14px;
    `}

    .email {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        ${breakpoint.breakSmall`
            width : 80px;
        `}
        ${breakpoint.breakMobile`
            width : 130px;
        `}
        ${breakpoint.breakMobileMedium`
            width : 200px;
        `}
    }
`;
