export const IconUser = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.9865 21C18.5809 21 19.1312 20.6866 19.4345 20.1754V20.1754C19.7777 19.597 19.7394 18.8703 19.2375 18.4226C18.1675 17.4684 15.9427 16 12.3828 16C8.83257 16 6.71641 17.4604 5.71251 18.4149C5.23329 18.8705 5.21385 19.5827 5.55123 20.1514V20.1514"
            stroke="#898D8F"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M12.5 12.1111C14.5711 12.1111 16.25 10.5192 16.25 8.55556C16.25 6.59188 14.5711 5 12.5 5C10.4289 5 8.75 6.59188 8.75 8.55556C8.75 10.5192 10.4289 12.1111 12.5 12.1111Z"
            stroke="#898D8F"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
