import styled from "styled-components";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { ClipLoaderCommon, ConfirmComponent, ProcessOrderHeader } from "@components";
import { CartStepLabels, QuoteStepLabels } from "@constants";
import { CartModule, CheckoutModule, OrderSuccessModule, PaymentModule } from "@module";
import {
    useAppSelector,
    AppDispatch,
    fetchCart,
    fetchCustomer,
} from "@redux";
import { AppModuleLayout } from "@layouts";
import { breakpoint } from "@utilities";
import { NavLink, useParams } from "react-router-dom";
import { ErrorToast } from "src/Components/toasts/ErrorToast";
import { QuotesBreadcrumbs } from "@routes";
import { IPayNowResponseData, ISetCartBookingData, ISetCustomerData } from "@interfaces";
import _ from "lodash";
import { loadQuoteCustomers, loadQuoteDetails, removeQuoteItemAndFetch, selectQuotes, submitQuoteItemsAndFetch, updateQuoteCustomersAndReload } from "src/Redux/Slices/quotes";
import { quoteApi } from "src/Api/quoteApi";

export const QuoteDetails = () => {
    //page hook
    const dispatch = useDispatch<AppDispatch>();
    const { quoteId: quoteIdFromParam } = useParams<{ quoteId: string }>();
    const quoteId = quoteIdFromParam!;
    const { quotesDetails, quotesCustomers } = useAppSelector(selectQuotes);
    const quoteDetails = _.get(quotesDetails, quoteId);
    const quoteCustomers = _.get(quotesCustomers, quoteId);
    //page state
    const [activeStepIndex, setActiveStep] = useState(0);
    const [isDetailsLoading, setDetailsLoading] = useState(true);
    const [isCustomersLoading, setCustomersLoading] = useState(true);
    const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
    const [cartDeleteId, setCartDeleteId] = useState<string | number>("");

    //scroll on top
    useEffect(() => {

        const scrollToTop = () => {
            window.scrollTo(0, 0);
        };
        const fetchQuoteDetails = async () => {
            try {
                setDetailsLoading(true);
                await dispatch(loadQuoteDetails(quoteId)).unwrap();
            } catch (error) {
                console.error(error);
                ErrorToast({ Message: 'Failed to load items' });
            } finally {
                setDetailsLoading(false);
            }
        };
        const fetchQuoteCustomers = async () => {
            try {
                setCustomersLoading(true);
                await dispatch(loadQuoteCustomers(quoteId)).unwrap();
            } catch (error) {
                console.error(error);
                ErrorToast({ Message: 'Failed to load customers' });
            } finally {
                setCustomersLoading(false);
            }
        };

        fetchQuoteDetails();
        fetchQuoteCustomers();
        scrollToTop();
    }, []);

    const handleItemsSave = async (items: ISetCartBookingData[]) => {
        await dispatch(submitQuoteItemsAndFetch({
            quoteId: quoteId,
            items: items,
        }));
    };
    const handleCustomersSave = async (customers: ISetCustomerData[]) => {
        await dispatch(updateQuoteCustomersAndReload({
            quoteId: quoteId,
            customers: customers,
        }));
    };
    const handleOrderCreate = async () => {
        const createOrderResponse = await quoteApi.createOrder(quoteId);
        if (createOrderResponse.status !== 200) {
            console.error(createOrderResponse);
            throw new Error();
        }
        const quoteUrl = (
            createOrderResponse.data.data as IPayNowResponseData
        ).payNow.redirectUrl.replaceAll("\\", "");
        window.location.href = quoteUrl;
    };
    const onOrderCreateSuccess = () => {
        dispatch(fetchCart());
        dispatch(fetchCustomer());
    };

    const handleHeaderBack = () => {
        if (activeStepIndex > 0) {
            setActiveStep(activeStepIndex - 1);
            return;
        }
        window.history.back();
    };

    const handleDeletePopUp = (cartDeleteId?: string | number) => {
        setIsShowPopup(!isShowPopup);
        setCartDeleteId(cartDeleteId || "");
    };

    const handleDeleteProduct = () => {
        handleDeletePopUp();
        if (cartDeleteId !== "") dispatch(removeQuoteItemAndFetch({
            quoteId: quoteId,
            itemId: cartDeleteId,
        }));
    };

    const renderComponent = () => {
        const activeStep = CartStepLabels[activeStepIndex];
        switch(activeStep) {
            case 'Order':
                return <OrderSuccessModule />;
            case 'Payment':
                return <PaymentModule
                    items={quoteDetails!.items}
                    handleOrderCreate={handleOrderCreate}
                    onOrderCreateSuccess={onOrderCreateSuccess}
                />;
            case 'Checkout':
                return <CheckoutModule
                    items={quoteDetails!.items}
                    customers={quoteCustomers!.customers}
                    handleCustomersSave={handleCustomersSave}
                    handleSwitchToPayment={() => {
                        setActiveStep(_.findIndex(CartStepLabels, label => label === 'Payment'));
                    }}
                />;
            default:
                return <CartModule
                    items={quoteDetails!.items}
                    moveTo={setActiveStep}
                    handleDeletePopUp={handleDeletePopUp}
                    handleItemsSave={handleItemsSave}
                    handleSwitchToCheckout={() => {
                        setActiveStep(_.findIndex(CartStepLabels, label => label === 'Checkout'));
                    }}
                />;
        }
    };

    return (
        <AppModuleLayout onBack={handleHeaderBack}>
            <ProcessOrderHeader
                stepLabels={QuoteStepLabels}
                activeStepIndex={activeStepIndex}
                breadcrumbs={QuotesBreadcrumbs}
                changeStep={setActiveStep}
            />
            <Main>
                {isDetailsLoading || isCustomersLoading || _.isNil(quoteDetails) ? (
                    <Container className="wrapper">
                        <Content>
                            <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
                        </Content>
                    </Container>
                ) : quoteDetails!.items.length === 0 ? (
                    <Container className="wrapper">
                        <Content>
                            <img
                                width={65}
                                height={65}
                                src="/images/cart/emptyCart.svg"
                                alt="emptyCartLogo"
                            />
                            <p>There are no items in the quote</p>
                            <NavLink to="/">
                                <Button>Continue Browsing</Button>
                            </NavLink>
                        </Content>
                    </Container>
                ) : renderComponent()}
                <ConfirmComponent
                    isShowPopup={isShowPopup}
                    onClickOverlay={handleDeletePopUp}
                    onClickBtnYes={handleDeleteProduct}
                    onClickBtnNo={handleDeletePopUp}
                >
                    Are you sure you want to delete this fare?
                </ConfirmComponent>
            </Main>
        </AppModuleLayout>
    );
};

const Main = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    ${breakpoint.breakTablet`
        padding-bottom: 30px;
    `}

    .clipLoader {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.2);

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .loader {
            z-index: 1;
        }
    }
`;

const Container = styled.div`
    background-color: white;
    display: flex;
    flex: 1;
    ${breakpoint.breakMobile`
        width: ${(p) => p.theme.widths.wide_700}
    `}

    ${breakpoint.breakTablet`
        width: ${(p) => p.theme.widths.wide_700};
    `}

    ${breakpoint.breakIpadPro`
        width: ${(p) => p.theme.widths.wide_900};
    `}

    ${breakpoint.breakLarge`
        width: ${(p) => p.theme.widths.wide_1110};
    `}
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 14px;
`;

const Button = styled.button`
    border: 1px solid #31b4b9;
    padding: 12px 24px;
    border-radius: 4px;
    color: #31b4b9;
    background: none;
    cursor: pointer;

    &:hover {
        background-color: #e0f7f8;
    }
`;
