import styled from "styled-components";
import { StyledButton, TotalPriceComponent } from "@components";
import { breakpoint } from "@utilities";
import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import { IFareCart, ISetCartBookingData } from "@interfaces";
import NewCartItem from "./NewCartItemModule";
import { useEffect, useState } from "react";
import _, { every, values } from "lodash";
import { ErrorToast } from "src/Components/toasts/ErrorToast";

interface IProps {
    items: IFareCart[];
    moveTo: (step: number) => void;
    handleDeletePopUp: (cartDeleteId?: string | number) => void;
    handleItemsSave: (items: ISetCartBookingData[]) => Promise<void>;
    handleSwitchToCheckout: () => void;
}

export const CartModule = (props: IProps) => {
    //prop
    const { items, handleDeletePopUp, handleItemsSave, handleSwitchToCheckout } = props;
    const [isFormValid, setIsFormValid] = useState(false); // State to track form validity

    const handleSubmit = async (
        values: { cartItems: IFareCart[] },
        { setSubmitting, validateForm }: FormikHelpers<{ cartItems: IFareCart[] }>,
    ) => {
        try {
            const validationResult = await validateForm();
            if (!_.isEmpty(validationResult)) {
                return;
            }
            setSubmitting(true);
            const payload = values.cartItems.map((ci) => {
                const { optionalData, ...restBookingData } = ci.bookingData;
                const bookingData = {
                    ...restBookingData,
                    ...(optionalData || {}),
                };
                return {
                    itemId: ci.id,
                    bookingData,
                };
            });
            await handleItemsSave(payload);
        } catch (error) {
            console.error(error);
            ErrorToast({ Message: "Failed to update items" });
        } finally {
            setSubmitting(false);
        }
    };

    const initialValues = {
        cartItems: items.map((c) => ({
            ...c,
            bookingData: {
                optionalData:
                    c.booking_details?.optionalFields
                        ?.filter((optionalData) => optionalData.optionalFieldId !== null)
                        ?.reduce((acc, optionalData) => {
                            const fieldId = String(optionalData.optionalFieldId);
                            return {
                                ...acc,
                                [fieldId]: _.get(c.booking_data?.optionalData, fieldId),
                            };
                        }, {}) || {},
            },
        })),
    };

    useEffect(() => {
        // TODO: should be validated by schema
        const isFormValid = every(
            items,
            (cartItem) =>
                cartItem.booking_data?.optionalData &&
                every(
                    values(cartItem.booking_data.optionalData),
                    (value) =>
                        value !== null &&
                        value !== "" &&
                        value !== "Not Specified" &&
                        value !== "Not Given",
                ),
        );

        setIsFormValid(isFormValid);
    }, [items]);

    return (
        <Container>
            <div>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({ values, isSubmitting, resetForm, submitForm }) => (
                        <Form className="form">
                            <ListProducts>
                                <FieldArray
                                    name="cartItems"
                                    render={() => (
                                        <div>
                                            {values.cartItems.map((cartItem, index) => {
                                                return (
                                                    <NewCartItem
                                                        key={index}
                                                        item={cartItem}
                                                        arrayIndex={index}
                                                        arrayName="cartItems"
                                                        handleDeletePopUp={handleDeletePopUp}
                                                    />
                                                );
                                            })}
                                            {values.cartItems.map((cartItem) => {
                                                const hasOptionalFields =
                                                    cartItem?.booking_details?.optionalFields
                                                        ?.length > 0;
                                                const hasPickupLocation =
                                                    cartItem?.booking_details?.pickupLocations
                                                        ?.length > 0;

                                                return hasOptionalFields && hasPickupLocation ? (
                                                    <>
                                                        <div className="secondaryActions">
                                                            <div
                                                                className="btnRefresh"
                                                                onClick={() => {
                                                                    resetForm({
                                                                        values: initialValues,
                                                                    });
                                                                }}
                                                            >
                                                                <img
                                                                    src="/images/fi_refresh-ccw.svg"
                                                                    alt="fi_refresh-ccw"
                                                                />
                                                                <span>Reset</span>
                                                            </div>
                                                        </div>
                                                        <StyledButton
                                                            className="saveButton"
                                                            color={["#436CB2 0%", "#28B7C3 100%"]}
                                                            borderRadius="4px"
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                        >
                                                            {isSubmitting ? "Saving ..." : "Save"}
                                                        </StyledButton>
                                                    </>
                                                ) : null;
                                            })}
                                        </div>
                                    )}
                                />
                            </ListProducts>
                            <div className="totalPriceContainer">
                                {values.cartItems.map((cartItem) => {
                                    const hasOptionalFields =
                                        cartItem?.booking_details?.optionalFields?.length > 0;
                                    const hasPickupLocation =
                                        cartItem?.booking_details?.pickupLocations?.length > 0;

                                    const disableNext =
                                        hasOptionalFields && hasPickupLocation
                                            ? items.length === 0 || isSubmitting || !isFormValid
                                            : false;

                                    return (
                                        <TotalPriceComponent
                                            btnTitle="Checkout"
                                            disableNext={disableNext}
                                            cartItems={values.cartItems}
                                            onBtnClick={handleSwitchToCheckout}
                                            page="cart"
                                            onSaveClick={submitForm}
                                            isSubmitting={isSubmitting}
                                        />
                                    );
                                })}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;

    .form {
        ${breakpoint.breakOnlyMobile`
            display: flex;
            flex-direction: column;
        `}

        .totalPriceContainer {
            display: flex;
            justify-content: flex-end;

            ${breakpoint.breakOnlyMobile`
            position: fixed;
            bottom: 0;
            left: 0;
            background-color: white;
            z-index: 100; 
            width: 100%;
        `}
        }
    }

    ${breakpoint.breakOnlyMobile`
        flex: 1;
        display: flex;
        flex-direction: column;
    `}

    & > div {
        margin: 0 auto;

        ${breakpoint.breakOnlyMobile`
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
        `}
    }

    ${breakpoint.breakTablet`
        & form {
            display: flex;
        }
    `}

    .secondaryActions {
        display: flex;
        justify-content: right;
        margin-top: 19px;

        span {
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
        }

        .btnRemove,
        .btnRefresh {
            display: flex;
            align-items: center;

            span {
                margin-left: 13px;
            }
        }

        .btnRemove {
            color: ${(p) => p.theme.colors.red};

            ${breakpoint.breakTablet`
                opacity: 0;
                visibility: hidden;
            `}
        }

        .btnRefresh {
            color: ${(props) => props.theme.colors.black};
            cursor: pointer;
            ${breakpoint.breakOnlyMobile`
                margin-bottom :18px;
            `}
        }
    }

    ${breakpoint.breakTablet`
        background-color: #F2F2F2;

        & > div {
            display: flex;
            width: ${(p) => p.theme.widths.wide_700};
        }
    `}

    ${breakpoint.breakIpadPro`
        & > div {
            width: ${(p) => p.theme.widths.wide_900};
        }
    `}
    
    ${breakpoint.breakLarge`
        & > div {
            width: ${(p) => p.theme.widths.wide_1110};
        }
    `}
`;

const ListProducts = styled.div`
    background-color: ${(props) => props.theme.colors.gray_6};
    padding: 16px 16px 0 16px;
    .saveButton {
        ${breakpoint.breakOnlyMobile`
            display: none;
        `}
    }

    .heading {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 7px;
    }

    .infoWrapper {
        padding-bottom: 16px;
    }

    .MuiSelect-icon {
        color: ${(props) => props.theme.colors.main} !important;
    }

    .MuiSelect-select.MuiSelect-select {
        font-weight: 700 !important;
        font-size: 16px !important;
        width: 85px;
    }

    .MuiInput-underline:after {
        display: none !important;
    }

    .MuiInput-underline:before {
        border: none !important;
    }

    ${breakpoint.breakTablet`
        background-color: #F2F2F2;
        padding: 0 16px 44px 0;
        flex: 698;

        .infoWrapper {
            background-color: #F2F2F2;
        }

        .MuiInput-root {
            display: none;
        }
    `}

    ${breakpoint.breakIpadPro`
        padding: 0 33px 44px 0;
    `}
    
    ${breakpoint.breakLarge`
        padding: 0 62px 84px 0;
    `}
`;
