import { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { IconFilter, IconSearch, StyledButton, StyledSearchDesktopComponent } from "@components";
import { useWindowDimensions } from "@utilities";
import { FilterModule } from "@module";
import { AppDispatch, selectSearchCategories, setName, useAppSelector } from "@redux";
import { PATH_SEARCH_RESULT } from "@routes";

interface IProps {
    onChangeIsIncreasePaddingTop: () => void;
}

export const SearchModule = (props: IProps) => {
    const { width } = useWindowDimensions();
    const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const searchCategories = useAppSelector(selectSearchCategories);
    const nameValue = searchCategories.name ? searchCategories.name : "";

    const handleToggleShowFilter = () => {
        setIsShowFilter(!isShowFilter);
        props.onChangeIsIncreasePaddingTop();
    };

    const handleSearch = () => {
        dispatch(setName(nameValue));
        navigate(PATH_SEARCH_RESULT);
    };

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setName(e.target.value));
    };

    const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode === 13) {
            dispatch(setName(nameValue));
            navigate(PATH_SEARCH_RESULT);
        }
    };

    return (
        <StyledSearchDesktopComponent isShowFilter={isShowFilter} width={width}>
            <div className="searchBox">
                <div className="searchIcon">
                    <IconSearch />
                </div>
                <div className="inputBox">
                    <span className="searchIconMobile mobile">
                        <IconSearch />
                    </span>
                    <input
                        type="text"
                        value={nameValue}
                        onChange={handleChangeName}
                        onKeyDown={handleEnter}
                        placeholder={
                            width > 767
                                ? "Search by keyword and/or using the options in the filter."
                                : "Search by keyword"
                        }
                    />
                </div>
                <div className="filterBtn" onClick={handleToggleShowFilter}>
                    <button>
                        <IconFilter />
                        <span>Filter</span>
                    </button>
                </div>
                <div className="searchBtn">
                    <StyledButton color={["#436CB2 0%", "#28B7C3 100%"]} onClick={handleSearch}>Search</StyledButton>
                </div>
            </div>
            <div className="searchBtnMobile mobile">
                <StyledButton borderRadius="4px" color={["#436CB2 0%", "#28B7C3 100%"]} onClick={handleSearch}>Search</StyledButton>
            </div>
            <div className="filterBox">
                <FilterModule onToggleShowFilter={handleToggleShowFilter} />
            </div>
        </StyledSearchDesktopComponent>
    );
};
