import { useEffect } from "react";
import { ClipLoaderCommon } from "../Common";
import { PromoTourModule } from "@module";
import {
    fetchHomePageProduct,
    selectHomePageProduct,
    useAppDispatch,
    useAppSelector,
} from "@redux";
import styled from "styled-components";
import { breakpoint } from "@utilities";
import _ from "lodash";

export const ToursCategory = () => {
    const dispatch = useAppDispatch();
    const { homePageProducts, loading } = useAppSelector(selectHomePageProduct);

    useEffect(() => {
        dispatch(fetchHomePageProduct());
    }, [dispatch]);
    if (loading && _.keys(homePageProducts).length === 0) {
        return (
            <div className="category">
                <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
            </div>
        );
    }

    return (
        <div className="category">
            {_.map(homePageProducts, (category, name) => (
                <div key={name}>
                    <CategoryHeading className="topDestinations">
                        {name}
                    </CategoryHeading>
                    <PromoTourModule data={category.tours} />
                </div>
            ))}
        </div>
    );
};

const CategoryHeading = styled.h2`
    ${breakpoint.breakMobile`
        display: flex;
        text-align: left;
    `}
`;
