import { DatePickerCommon, StyledTourDetail } from "@components";
import { ICheckAvailDateRange, ICheckPriceAvailQueryParam, ITourFare } from "@interfaces";
import { useCallback, useEffect, useState } from "react";
import { getFarePrice, useAppDispatch } from "@redux";
import styled from "styled-components";
import { breakpoint } from "@utilities";
import _ from "lodash";
import { faresPricesGroups } from "src/Utilities/cartHelper";
import { PriceGroup } from "./ProductPricings";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { PATH_DETAIL_TOUR } from "@routes";
import { FARE_PRICE_TRAVEL_DATE_FORMAT } from "@constants";

interface IProps {
    productClass: string | null;
    tourFare: ITourFare[];
    tourId: number | string;
}

export const PriceDetailTourModule = ({ productClass, tourFare, tourId }: IProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const bookingSystem = tourFare?.find((f) => f.hasBookingSystem)?.bookingSystem ?? null;
    const firstFare = _.first(tourFare);
    const now = new Date();

    // Determine the travel end date from `firstFare` if it exists
    const travelEnd = !_.isNil(firstFare?.travelEnd)
        ? moment(firstFare!.travelEnd!, FARE_PRICE_TRAVEL_DATE_FORMAT).toDate()
        : null;

    // Set a default "to" date for availability checking
    // If `travelEnd` exists and is within 14 days from today, use `travelEnd`
    // Otherwise, set `to` date as 14 days from today
    const defaultToDate =
        travelEnd && moment(travelEnd).isBefore(moment().add(14, "days"))
            ? travelEnd
            : moment(now).add(14, "days").toDate();

    // Define state to manage the date range for availability checking
    const [checkAvailDateRange, setDate] = useState<ICheckAvailDateRange>({
        from: now, // Default "from" date is today
        to: defaultToDate, // Default "to" date is `defaultToDate`
    });

    // Function to change the "from" date, ensuring it does not exceed the "to" date
    const changeDateFrom = useCallback((dateCheck: Date) => {
        setDate((prevDate) => ({
            ...prevDate,
            from: dateCheck,
            to: dateCheck > prevDate.to ? dateCheck : prevDate.to,
        }));
    }, []);

    // Function to change the "to" date, ensuring it does not precede the "from" date
    const changeDateTo = useCallback((dateCheck: Date) => {
        setDate((prevDate) => ({
            ...prevDate,
            to: dateCheck,
            from: dateCheck < prevDate.from ? dateCheck : prevDate.from,
        }));
    }, []);

    // Define constraints for date selection
    const limitDate = {
        from: {
            minDate: now, // "from" date cannot be before today
            maxDate: checkAvailDateRange.to, // "from" date cannot be after "to" date
        },
        to: {
            minDate: checkAvailDateRange.from, // "to" date cannot be before "from" date
            maxDate: null, // No upper limit for "to" date
        },
    };

    useEffect(() => {
        dispatch(getFarePrice(tourFare[0].productPricesDetailsId, tourFare[0].fareName));
    }, []);

    return (
        <StyledTourDetail>
            <div className="heading">
                <h3>Price</h3>
                <DateFIeld>
                    <div className="datePicker">
                        <DatePickerCommon
                            label="From"
                            value={checkAvailDateRange.from}
                            onChange={changeDateFrom}
                            limitDate={limitDate.from}
                        />
                    </div>
                    <div className="datePicker">
                        <DatePickerCommon
                            label="To"
                            value={checkAvailDateRange.to}
                            onChange={changeDateTo}
                            limitDate={limitDate.to}
                        />
                    </div>
                </DateFIeld>
            </div>
            <div>
                {!_.isNil(tourFare) ? (
                    faresPricesGroups(tourFare).map((priceGroup) => {
                        return (
                            <PriceGroup
                                priceGroup={priceGroup}
                                checkFrom={checkAvailDateRange.from}
                                checkTo={checkAvailDateRange.to}
                                bookingSystem={bookingSystem}
                                productClass={productClass}
                                checkAvailability={(from, to, selectedBookingTimeId) => {
                                    const queryData: ICheckPriceAvailQueryParam = {
                                        startDate: from.toISOString(),
                                        days: moment(to).diff(moment(from), "days").toString(),
                                        bookingTimeId: selectedBookingTimeId,
                                    };
                                    const queryString = new URLSearchParams(
                                        queryData as any,
                                    ).toString();
                                    navigate(
                                        `${PATH_DETAIL_TOUR}/${tourId}/${priceGroup.id}?${queryString}`,
                                    );
                                }}
                            />
                        );
                    })
                ) : (
                    <p className="noFare">No fare available!</p>
                )}
            </div>
        </StyledTourDetail>
    );
};

const DateFIeld = styled.div`
    display: flex;
    gap: 20px;

    .datePicker {
        .MuiInputLabel-animated {
            color: #fff;
        }

        .MuiFormControl-marginNormal {
            margin: 0;
        }

        .MuiInputBase-root {
            background-color: ${(p) => p.theme.colors.gray_1} !important;
            height: 36px;
            width: 135px;

            ${breakpoint.breakIpadPro`
                width: 160px;
            `}

            ${breakpoint.breakLarge`
                width: 214px;
            `}
        }

        .MuiInputBase-input {
            color: #fff;
        }
    }
`;
