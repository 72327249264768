import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { categoriesApi } from "@api";
import { RootState } from ".";
import { ITour } from "@interfaces";
import { entries, isArray } from "lodash";
export interface CategoryTours {
    categoryName: string;
    tours: ITour[];
}

interface CategoryData {
    labels: string[];
    products: Array<{
        tdms_product_id: string;
        json: {
            name: string;
            country: string;
            durationDays: number;
            durationNight: number;
            rrp: number;
            productLargeSizeImagePath: string;
        };
    }>;
}

interface HomePageProductState {
    homePageProducts: Record<string, CategoryTours>;
    loading: boolean;
    error?: string;
}

const initialState: HomePageProductState = {
    homePageProducts: {},
    loading: false,
    error: undefined,
};

export const fetchHomePageProduct = createAsyncThunk<
    Record<string, CategoryTours>,
    void,
    { rejectValue: string }
>("HomePageProduct/fetchHomePageProduct", async (_, thunkAPI) => {
    try {
        const res = await categoriesApi.getHomepageProduct();
        const data: { [key: string]: CategoryData }[] = res.data.data;
        const transformedData: Record<string, CategoryTours> = {};
        data.forEach((categoryObj) => {
            const [categoryName, categoryData] = entries(categoryObj)[0];
            let tours: ITour[] = [];
            if (categoryData) {
                if (isArray(categoryData.products)) {
                    tours = categoryData.products.map((tour) => ({
                        productId: tour.tdms_product_id,
                        name: tour.json?.name,
                        location: tour.json?.country,
                        durationDays: tour.json?.durationDays,
                        durationNight: tour.json?.durationNight,
                        rrp: Number(tour.json?.rrp.toFixed(2)),
                        productImagePath: tour.json?.productLargeSizeImagePath,
                    }));
                }
                transformedData[categoryName] = {
                    categoryName,
                    tours,
                };
            }
        });
        return transformedData;
    } catch (error) {
        console.error(error);
        return thunkAPI.rejectWithValue("Failed to fetch categories");
    }
});

const homePageProductSlice = createSlice({
    name: "homePageProduct",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchHomePageProduct.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(
            fetchHomePageProduct.fulfilled,
            (state, action: PayloadAction<Record<string, CategoryTours>>) => {
                state.loading = false;
                state.homePageProducts = action.payload;
            },
        );
        builder.addCase(fetchHomePageProduct.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload ?? "An unknown error occurred";
        });
    },
});

export const selectHomePageProduct = (state: RootState) => state.homePageProduct;
export default homePageProductSlice.reducer;
