import { Link } from "react-router-dom";

import {
    StyledNavigator,
    IconCart,
    IconUser,
    MenuHeaderComponent,
    IconFileText,
    IconHeart,
    IconLock,
    IconSearch,
} from "@components";
import {
    PATH_BOOKING,
    PATH_CART,
    PATH_FAVORITES,
    PATH_HOME,
    PATH_MY_ACCOUNT,
    PATH_QUOTES,
    PATH_SEARCH_RESULT,
    PATH_UPDATE_PASSWORD,
} from "@routes";
import { useAppSelector, selectAuth, selectAccount } from "@redux";
import _ from "lodash";
import { useEffect, useState } from "react";

interface IProps {
    onBackToHome: () => void;
    productCount: number;
    toggleShowMenu: () => void;
    isShowMenu: boolean;
}

export const HeaderNavigatorComponent = (props: IProps) => {
    const { productCount, toggleShowMenu, isShowMenu } = props;
    const { jwt } = useAppSelector(selectAuth);
    const { account } = useAppSelector(selectAccount);
    const [isSignedIn, setIsSignedIn] = useState(!_.isNil(jwt) && !_.isNil(account));
    const pathname = window.location.pathname;
    const listMenuItems = [
        {
            icon: IconUser,
            title: "Update My Details",
            link: PATH_MY_ACCOUNT,
        },
        {
            icon: IconHeart,
            title: "Favourites",
            link: PATH_FAVORITES,
        },
        {
            icon: IconFileText,
            title: "Bookings",
            link: PATH_BOOKING,
        },
        {
            icon: IconCart,
            title: "Quotes",
            link: PATH_QUOTES,
        },
        {
            icon: IconLock,
            title: "Update Password",
            link: PATH_UPDATE_PASSWORD,
        },
    ];

    useEffect(() => {
        setIsSignedIn(!_.isNil(jwt) && !_.isNil(account));
    }, [jwt, account]);

    return (
        <StyledNavigator {...props}>
            <Link className="backBtn logo" to={PATH_HOME}>
                <img
                    src="/images/logos/FreelanceTravel_Primary_Logo.svg"
                    alt="logo"
                    className="logoItem logoColor"
                />
            </Link>
            <div className="menu">
                {!pathname.includes(PATH_HOME && PATH_SEARCH_RESULT) && (
                    <Link to={PATH_SEARCH_RESULT} className="iconWrapper">
                        <IconSearch />
                    </Link>
                )}
                <Link to={PATH_CART} className="cartIconWrapper iconWrapper">
                    {productCount ? <span className="number">{productCount}</span> : <></>}
                    <IconCart />
                </Link>
                {pathname.includes("detail-tour") && isSignedIn && (
                    <div className="userIconWrapper iconWrapper" onClick={toggleShowMenu}>
                        <IconUser />
                        {isShowMenu && <MenuHeaderComponent listMenuItems={listMenuItems} />}
                    </div>
                )}
            </div>
        </StyledNavigator>
    );
};
