import { useEffect, useState } from "react";
import styled from "styled-components";

import { ToursCategory, LoadingCommon, StyledBanner } from "@components";
import { AppModuleLayout } from "@layouts";
import { SearchModule } from "@module";
import { breakpoint } from "@utilities";

export const Home = () => {
    // const favoritesTour = useAppSelector(selectFavoriteTours).tours;
    //page state
    const [isIncreasePaddingTop, setIsIncreasePaddingTop] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const handleChangeIsIncreasePaddingTop = () => {
        setIsIncreasePaddingTop(!isIncreasePaddingTop);
    };
    if (!isLoading) {
        return <LoadingCommon />;
    }
    return (
        <AppModuleLayout>
            <Container>
                <StyledBanner>
                    <div className="bannerMobile">
                        <img src="/images/banner/Banner-home-50.png" alt="" />
                        <h2 className="heading">
                            Travel & live your <br /> best life
                        </h2>
                    </div>
                    <div className="bannerDesktop">
                        <img src="/images/website/banner/banner1.png" alt="" />
                        <h2 className="heading">Travel & live your best life</h2>
                    </div>
                </StyledBanner>
                <div className="search">
                    <SearchModule onChangeIsIncreasePaddingTop={handleChangeIsIncreasePaddingTop} />
                </div>
                {/* we may need this code for future when we add favorites */}
                {/* {favoritesTour?.length > 0 && (
                    <div className="listDestinations">
                        <h2 className="topDestinations">My Favourites</h2>
                        {favoritesTour.slice(0, 4).map((tour) => {
                            return <TourElementModule data={tour} key={tour.productId} />;
                        })}
                    </div>
                )} */}
                <ToursCategory />
                {/* we may need this code for future when we add favorites */}
                {/* {favoritesTour?.length > 0 && (
                    <div className="listDestinationsWebsite">
                        <h2 className="topDestinations">My Favourites</h2>
                        <PromoTourModule data={favoritesTour.slice(0, 4)} type="favorite" />
                    </div>
                )} */}
            </Container>
            {/* {isShowWelComePopup ? <WelcomePopupModule onClosePopup={handleClosePopup} /> : <></>} */}
        </AppModuleLayout>
    );
};

const Container = styled.div`
    background-color: ${(props) => props.theme.colors.gray_6};
    width: 100vw;
    padding-bottom: 60px;

    .search {
        position: relative;
        ${breakpoint.breakTablet`
            z-index: 2;
        `}/* ${breakpoint.breakOnlyMobile`
            z-index: 2;
        `} */
    }

    .category {
        padding-top: 30px;
        ${breakpoint.breakOnlyMobile`
            padding: 0 16px 30px 16px;
        `}
    }

    .topDestinations {
        line-height: 36.46px;
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        margin-top: 34px;
        color: ${(props) => props.theme.colors.black};
        margin-bottom: 20px;
    }

    @media (min-width: 1110px) {
        .topDestinations {
            margin-top: 75px;

            &.topTour {
                margin-top: 80px;
            }
        }
    }
`;
