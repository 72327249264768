import { toursApi } from "@api";
import { AddToCart, StyledBookingTimeError } from "@components";
import { BOOKING_SYSTEMS_WITH_CUSTOM_BOOKING_TIME_IDS, LOADING_STAGES } from "@constants";
import { IBookingTimesFare, IPriceGroup } from "@interfaces";
import { breakpoint } from "@utilities";
import _ from "lodash";
import { memo, useEffect, useState } from "react";
import { LoadingStage } from "src/Interfaces/shared";
import { formatBookingTime, shouldEnableAvailabilityCheck } from "src/Utilities/cartHelper";
import styled from "styled-components";

interface IPriceGroupProps {
    priceGroup: IPriceGroup;
    checkFrom: Date;
    checkTo: Date;
    bookingSystem: string | null;
    productClass: string | null;
    checkAvailability: (from: Date, to: Date, bookingTimeID: string | null) => void;
}

export const PriceGroup = memo((props: IPriceGroupProps) => {
    const { priceGroup, checkFrom, checkTo, bookingSystem, productClass, checkAvailability } =
        props;
    const [hasBookingTimeError, setHasBookingTimeError] = useState(
        priceGroup.hasBookingTimeError === true,
    );
    const needsBookingTimeId = !_.includes(
        BOOKING_SYSTEMS_WITH_CUSTOM_BOOKING_TIME_IDS,
        bookingSystem?.trim().toLowerCase(),
    );
    const [shouldShowBookingTimeIds, setShouldShowBookingTimeIds] = useState(!needsBookingTimeId);
    const [bookingTimeIdLoadingStage, setBookingTimeIdLoadingStage] = useState(
        LoadingStage.NOT_INITIALIZED,
    );
    const [bookingTimes, setBookingTimeIds] = useState<IBookingTimesFare[]>([]);
    const [selectedBookingTimeId, setSelectedBookingTimeId] = useState<string | null>(
        needsBookingTimeId ? "0" : null,
    );

    useEffect(() => {
        const loadBookingTimeIds = async (productPricesDetailsId: string | number) => {
            try {
                setBookingTimeIdLoadingStage(LoadingStage.IS_INITIALIZING);
                const getBookingDetailResp =
                    await toursApi.getBookingDetail(productPricesDetailsId);
                if (productClass === "A" && getBookingDetailResp.data.bookingTimes.length === 1) {
                    // product is accomodation and has one time to pick
                    // so no need to show booking time ids
                    // and first one should be default
                    setShouldShowBookingTimeIds(false);
                    setSelectedBookingTimeId(
                        _.head(getBookingDetailResp.data.bookingTimes)!.BookingTimeID.toString(),
                    );
                }
                setBookingTimeIds(getBookingDetailResp.data.bookingTimes);
                setSelectedBookingTimeId(
                    getBookingDetailResp.data.bookingTimes
                        .find((bt) => bt.Default)
                        ?.BookingTimeID.toString() ?? "0",
                );
                setBookingTimeIdLoadingStage(LoadingStage.INITIALIZE_COMPLETE);
            } catch (error) {
                console.error(error);
                setHasBookingTimeError(true);
                setBookingTimeIdLoadingStage(LoadingStage.INITIALIZE_FAILURE);
            }
        };

        if (needsBookingTimeId) {
            loadBookingTimeIds(_.head(priceGroup.prices)!.productPricesDetailsId);
        }
    }, [bookingSystem]);

    return (
        <Content>
            <Fare>
                <div className="title">Fare Type</div>
                <div>
                    <div className="text">{priceGroup.name}</div>
                    {priceGroup.travelTimeMessage && (
                        <div className="travel-time">{priceGroup.travelTimeMessage}</div>
                    )}
                </div>
            </Fare>
            <Price>
                <div>
                    {`Price `}
                    <span style={ priceGroup.hasVariablePricing ? {fontSize: '16px'} : {}}>
                        {priceGroup.rrp}
                    </span>
                </div>
                {hasBookingTimeError && priceGroup.levyMessage && (
                    <div className="note">{priceGroup.levyMessage}</div>
                )}
            </Price>
            {!priceGroup.hasBookingTimeError ? (
                <div className="listBtn">
                    {shouldShowBookingTimeIds &&
                        LOADING_STAGES.includes(bookingTimeIdLoadingStage) && (
                            <div className="time">
                                {bookingTimes.map((bookingTime) => {
                                    const isChecked =
                                        selectedBookingTimeId === bookingTime.BookingTimeID;
                                    return (
                                        <span>
                                            <input
                                                type="radio"
                                                name={`time-${bookingTime.BookingTimeID}`}
                                                checked={isChecked}
                                                value={bookingTime.BookingTimeID}
                                            >
                                                {formatBookingTime(bookingTime.BookingTime)}
                                            </input>
                                        </span>
                                    );
                                })}
                            </div>
                        )}
                    <AddToCart
                        color={["#436CB2 0%", "#28B7C3 100%"]}
                        onClick={() => checkAvailability(checkFrom, checkTo, selectedBookingTimeId)}
                        disabled={
                            !shouldEnableAvailabilityCheck(checkFrom, checkTo, priceGroup.prices)
                        }
                    >
                        Check Availability
                    </AddToCart>
                </div>
            ) : (
                <div className="listBtn">
                    <StyledBookingTimeError>
                        We are unable to check live availability for this fare right now. Please try
                        again later.
                    </StyledBookingTimeError>
                </div>
            )}
        </Content>
    );
});

const Content = styled.div`
    background-color: #fff;
    width: 100%;
    padding: 25px;
    border-radius : 8px;
    border-bottom: 1.5px solid #e5e5e5;

    ${breakpoint.breakMobile` 
        display : flex;
        padding: 30px 16px 30px 16px;
        gap: 10px;
        flex-direction : column;
    `}

    ${breakpoint.breakIpadPro` 
        display: flex;
        justify-content: space-between;
        align-items: end;
    `}

    ${breakpoint.breakLarge` 
        display: flex;
        justify-content: space-between;
        align-items: end;
    `}

    .priceBtn {
        display: flex;
    }

    .fareType,
    .date,
    .listBtn {
        width: 32%;
        ${breakpoint.breakOnlyMobile` 
            width: 100%;
        `}
        ${breakpoint.breakIpad` 
            width : 32%
        `}
        ${breakpoint.breakIpadPro`
            width : 32%
        `}
        ${breakpoint.breakLarge`
            width : 32%
        `}
    }
   
    .listBtn {
        .MuiFormLabel-root {
            font-weight: bold;
            color: #090a18;
            font-size: 16px;
            width : 100%;
            ${breakpoint.breakIpad` 
                    font-size: 12px;
                `}
            ${breakpoint.breakIpadPro`
                    font-size: 16px;
                `}
        }
        .MuiFormControl-root {
            width: 100%;
            position: relative;
            left: 6px;
        }
        
`;

const Fare = styled.div`
    background-color: transparent;
    .title {
        font-weight: bold;
        color: #090a18;
        margin-bottom: 8px;
        font-size: 16px;
        ${breakpoint.breakIpad` 
                    font-size: 12px;
                `}
        ${breakpoint.breakIpadPro`
                    font-size: 16px;
                `}
    }
    .text {
        font-weight: normal;
        line-height: 21px;
        font-size: 16px;
        width: 400px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        ${breakpoint.breakIpad` 
            font-size: 12px;
        `}
        ${breakpoint.breakIpadPro`
            font-size: 16px;
        `}
        ${breakpoint.breakOnlyMobile`
             width : 300px;
        `}
    }
    .travel-time {
        font-weight: normal;
        line-height: 21px;
        font-size: 14px;
        color: ${(props) => props.theme.colors.gray_3};
        ${breakpoint.breakIpad` 
                    font-size: 10px;
                `}
        ${breakpoint.breakIpadPro`
                    font-size: 14px;
                `}
    }
`;

const Price = styled.div`
    color: #828282;
    font-size: 16px;
    ${breakpoint.breakIpad` 
        font-size: 12px;
    `}
    ${breakpoint.breakIpadPro`
        font-size: 16px;
    `}
                
    span {
        color: ${(props) => props.theme.colors.main};
        font-weight: 600;
        display: inline-block;
        transform: scale(1.2);
        margin-left: 10px;
        font-size: 24px;
        ${breakpoint.breakMobile`
            font-size:16px;
        `}
        ${breakpoint.breakIpad` 
            font-size: 16px;
        `}
        ${breakpoint.breakIpadPro`
            font-size: 24px;
        `}
    }
    .note {
        font-size: 14px;
        margin-top: 7px;
        width: 150px;
    }
`;
