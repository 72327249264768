import styled from "styled-components";
import moment from "moment";

import { IProductAvailability } from "@interfaces";
import { Checkbox } from "@mui/material";
import { BOOKING_DATE_FORMAT } from "@constants";
import _ from "lodash";

interface IProps {
    availability: IProductAvailability;
    isChecked: boolean;
    onCheck: (checkDate: IProductAvailability) => void;
}

export const FareOptionComponent = ({ availability, isChecked, onCheck }: IProps) => {
    const handleClick = () => {
        onCheck(availability);
    };

    return availability.NumAvailable > 0 ? (
        <Container>
            <div className="header">
                <div className="date">
                    Date:{" "}
                    {moment(availability.BookingDate, BOOKING_DATE_FORMAT).format("DD/MM/YYYY")}
                </div>
                <div onClick={handleClick}>
                    <Checkbox checked={isChecked} color="primary" />
                </div>
            </div>
            <div className="line bgc">
                <span className="field">Avail</span>
                <span className="value">{availability.NumAvailable}</span>
            </div>
            <div className="line">
                <span className="field">RRP</span>
                <span className="value">${_.round(availability.RRP ?? 0, 2)}</span>
            </div>
            {availability.levy && (
                <div className="line">
                    <span className="field">Levy</span>
                    <span className="value">${_.round(availability.levy, 2)}</span>
                </div>
            )}
        </Container>
    ) : null;
};

const Container = styled.div`
    background-color: #fff;
    border-radius : 8px;
    padding: 12px 16px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 13px;
    }

    .line {
        padding: 7px 18px;
        display: flex;
        justify-content: space-between;

        .value {
            font-weight: 400;

            &.net {
                color: ${(p) => p.theme.colors.main};
            }
        }

        &.bgc {
            background-color: #f8f8f8;
        }
    }
`;
