import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { DatePickerCommon } from "@components";
import { PATH_DETAIL_TOUR } from "@routes";
import { ITourFare, ICheckAvailDateRange, ICheckPriceAvailQueryParam } from "@interfaces";
import { getFarePrice, useAppDispatch } from "@redux";
import { FARE_PRICE_TRAVEL_DATE_FORMAT } from "@constants";
import _ from "lodash";
import moment from "moment";
import { faresPricesGroups } from "src/Utilities/cartHelper";
import { PriceGroup } from "./ProductPricings";

interface IProps {
    productClass: string | null;
    tourFare: ITourFare[];
    tourId: number | string;
    apiProviderId: number;
    groupFaresForAvailabilityCheck: boolean;
    fareName: string;
}

export const FareTypeModule = ({ productClass, tourFare, tourId, fareName }: IProps) => {
    const navigate = useNavigate();
    const bookingSystem = tourFare?.find((f) => f.hasBookingSystem)?.bookingSystem ?? null;
    const dispatch = useAppDispatch();
    const firstFare = _.first(tourFare);
    const now = new Date();

    // Determine the travel end date from `firstFare` if it exists
    const travelEnd = !_.isNil(firstFare?.travelEnd)
        ? moment(firstFare!.travelEnd!, FARE_PRICE_TRAVEL_DATE_FORMAT).toDate()
        : null;

    // Set a default "to" date for availability checking
    // If `travelEnd` exists and is within 14 days from today, use `travelEnd`
    // Otherwise, set `to` date as 14 days from today
    const defaultToDate =
        travelEnd && moment(travelEnd).isBefore(moment().add(14, "days"))
            ? travelEnd
            : moment(now).add(14, "days").toDate();

    // Define state to manage the date range for availability checking
    const [checkAvailDateRange, setDate] = useState<ICheckAvailDateRange>({
        from: now, // Default "from" date is today
        to: defaultToDate, // Default "to" date is `defaultToDate`
    });

    // Function to change the "from" date, ensuring it does not exceed the "to" date
    const changeDateFrom = useCallback((dateCheck: Date) => {
        setDate((prevDate) => ({
            ...prevDate,
            from: dateCheck,
            to: dateCheck > prevDate.to ? dateCheck : prevDate.to,
        }));
    }, []);

    // Function to change the "to" date, ensuring it does not precede the "from" date
    const changeDateTo = useCallback((dateCheck: Date) => {
        setDate((prevDate) => ({
            ...prevDate,
            to: dateCheck,
            from: dateCheck < prevDate.from ? dateCheck : prevDate.from,
        }));
    }, []);

    // Define constraints for date selection
    const limitDate = {
        from: {
            minDate: now, // "from" date cannot be before today
            maxDate: checkAvailDateRange.to, // "from" date cannot be after "to" date
        },
        to: {
            minDate: checkAvailDateRange.from, // "to" date cannot be before "from" date
            maxDate: null, // No upper limit for "to" date
        },
    };

    useEffect(() => {
        dispatch(getFarePrice(tourFare[0].productPricesDetailsId, tourFare[0].fareName));
    }, []);

    return (
        <Container>
            <div className="info">
                <p className="heading">Select Date</p>
                <p className="title">{fareName}</p>
                <div className="choseDate">
                    <div className="from">
                        <DatePickerCommon
                            label="From"
                            value={checkAvailDateRange.from}
                            onChange={changeDateFrom}
                            limitDate={limitDate.from}
                        />
                    </div>
                    <div className="to">
                        <DatePickerCommon
                            label="To"
                            value={checkAvailDateRange.to}
                            onChange={changeDateTo}
                            limitDate={limitDate.to}
                        />
                    </div>
                </div>
            </div>
            <div className="price">
                {!_.isNil(tourFare) ? (
                    faresPricesGroups(tourFare).map((priceGroup) => {
                        return (
                            <PriceGroup
                                priceGroup={priceGroup}
                                checkFrom={checkAvailDateRange.from}
                                checkTo={checkAvailDateRange.to}
                                bookingSystem={bookingSystem}
                                productClass={productClass}
                                checkAvailability={(from, to, selectedBookingTimeId) => {
                                    const queryData: ICheckPriceAvailQueryParam = {
                                        startDate: from.toISOString(),
                                        days: moment(to).diff(moment(from), "days").toString(),
                                        bookingTimeId: selectedBookingTimeId,
                                    };
                                    const queryString = new URLSearchParams(
                                        queryData as any,
                                    ).toString();
                                    navigate(
                                        `${PATH_DETAIL_TOUR}/${tourId}/${priceGroup.id}?${queryString}`,
                                    );
                                }}
                            />
                        );
                    })
                ) : (
                    <p className="noFare">No fare available!</p>
                )}
            </div>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .price {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .info {
        border-radius: 8px;
        padding: 20px 16px 16px 16px;
        background-color: #fff;
        .heading {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 10px;
        }

        .title {
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 16px;
            line-height: 21px;
        }
        .travel-time {
            font-weight: normal;
            line-height: 21px;
            font-size: 14px;
            margin-bottom: 10px;
            color: ${(props) => props.theme.colors.gray_3};
        }
    }

    .choseDate {
        display: flex;
        gap: 20px;

        .MuiInputLabel-animated {
            color: #000;
        }

        .MuiInputBase-input {
            color: #000;
        }
    }

    .listButton {
        display: flex;

        button {
            font-family: DM Sans;
            font-size: 12px;
            font-weight: 700;
            line-height: 19px;
            margin-top: 0;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    .MuiButtonBase-root span {
        color: #000;
    }
    .MuiRadio-colorSecondary.Mui-checked span {
        color: ${(p) => p.theme.colors.main};
    }
    .MuiInputBase-root {
        margin-bottom: 12px;
    }
    .rrp {
        font-size: 20px;
    }
    .check_availability {
        font-size: 16px !important;
    }
`;
