import { createSlice } from "@reduxjs/toolkit";

import { AppDispatch, store } from "@redux";
import { toursApi } from "@api";
import { ITourElement, IOptionSearch, ITour } from "@interfaces";
import { sliceString } from "@utilities";
import { RootState } from ".";
import _ from "lodash";

interface ISearchTours {
    loading: boolean;
    listTours: ITourElement[];
    totalRecord: number;
    currentPage: number;
    sortBy: string;
    sortDirection: string;
}

const initialState: ISearchTours = {
    loading: false,
    listTours: [],
    totalRecord: 0,
    currentPage: 1,
    sortBy: "rrp",
    sortDirection: "asc",
};

export const search =
    (loadMore: boolean, option: IOptionSearch) => async (dispatch: AppDispatch) => {
        dispatch(loading(true));
        const searchObject = store.getState().searchCategories;
        const searchData = _.entries(searchObject).reduce((newObj, [key, value]) => {
            //@ts-ignore
            if (key === "suppliers" && value && value.id) {
                //@ts-ignore
                return { ...newObj, ["supplier"]: value.id };
                //@ts-ignore
            } else if (key === "transports" && value && value.id) {
                //@ts-ignore
                return { ...newObj, ["transport"]: value.id };
                //@ts-ignore
            } else if (value && value.id) {
                //@ts-ignore
                return { ...newObj, [key]: value.id };
            } else if ((key === "name" || key === "day") && value) {
                if (key === "day") {
                    return { ...newObj, ["duration"]: value };
                }
                return { ...newObj, [key]: value };
            } else if (key === "sortDirection" && value) {
                //@ts-ignore
                option.sortDirection = value;
            }
            if (key === "sortBy" && value) {
                //@ts-ignore
                option.sortBy = value;
            }
            return newObj;
        }, {});
        try {
            const res = await toursApi.search(searchData, option);
            const processData = res.data.results.map((tour: ITour) => {
                let duringTime = "";
                if (Number(tour.durationDays) > 0) {
                    duringTime += `${tour.durationDays} ${
                        Number(tour.durationDays) === 1 ? "day" : "days"
                    }`;
                }
                if (Number(tour.durationDays) > 0 && Number(tour.durationNight) > 0) {
                    duringTime += " - ";
                }
                if (Number(tour.durationNight) > 0) {
                    duringTime += `${tour.durationNight} ${
                        Number(tour.durationNight) === 1 ? "night" : "nights"
                    }`;
                }
                return {
                    productId: tour.productId,
                    name: sliceString(tour.name, 7),
                    location: tour.country,
                    description: sliceString(tour.productTeaser ? tour?.productTeaser : "", 20),
                    duration: duringTime,
                    rrp: Number(tour.rrp).toFixed(2),
                    supplier: tour.supplierName,
                    productImagePath: tour.productLargeSizeImagePath,
                };
            });
            if (loadMore) {
                dispatch(pushTours(processData));
            } else {
                dispatch(setTours(processData));
            }
            dispatch(setTotalRecord(res.data.totalDisplayRecords));
            dispatch(loading(false));
        } catch (err: any) {
            console.error(err);
            dispatch(loading(false));
            return [];
        }
    };

const searchTours = createSlice({
    name: "searchTours",
    initialState: initialState,
    reducers: {
        loading: (state, action) => {
            state.loading = action.payload;
        },
        setTours: (state, action) => {
            state.listTours = action.payload;
        },
        setTotalRecord: (state, action) => {
            state.totalRecord = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        pushTours: (state, action) => {
            state.listTours = [...state.listTours, ...action.payload];
        },
        clearListTour: (state) => {
            state.listTours = [];
        },
    },
});

export const selectSearchTour = (state: RootState) => state.searchTours;

export const { loading, setTours, setTotalRecord, setCurrentPage, pushTours, clearListTour } =
    searchTours.actions;
export default searchTours.reducer;
