import { cartApi, toursApi } from "@api";
import {
    BOOKING_SYSTEMS_WITH_CUSTOM_BOOKING_TIME_IDS,
    CHECK_AVAILABILITY_START_DATE_FORMAT,
    FARE_PRICE_TRAVEL_DATE_FORMAT,
} from "@constants";
import {
    IAvailabilityDetailsToConfirm,
    // IAddCart,
    // IAvailability,
    IAvailField,
    IAvailOptions,
    ICheckAvail,
    // ICheckAvail,
    ICustomerInfo,
    // IDirectPurchaseData,
    IDirectPurchaseItemData,
    IFareCart,
    IPriceGroup,
    // IPriceGroupBase,
    IPricingInfo,
    IProductAvailability,
    IProductAvailDropOffOption,
    IProductAvailPickUpOption,
    IProductAvailTimeOption,
    ISetCartBookingData,
    ISetCustomerData,
    ITourFare,
    ITourFareBookingTime,
} from "@interfaces";
import { AxiosResponse } from "axios";
import _ from "lodash";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { quoteApi } from "src/Api/quoteApi";

const moment = extendMoment(Moment);

export const calculateTotal = (cartItems: IFareCart[], isRRP: boolean): number => {
    return cartItems.reduce((accumulator, cartItem) => {
        const quantity = cartItem.booking_quantity ?? 0;
        const apiProviderId = cartItem.product?.json?.apiProviderId ?? 0;
        const groupFaresForAvailabilityCheck = cartItem.product?.groupFaresForAvailabilityCheck;

        const farePrice = cartItem.availability?.FarePrice;
        const productPricing = cartItem.availability?.productPricingData;
        const price =
            apiProviderId > 0 && groupFaresForAvailabilityCheck
                ? ((isRRP ? farePrice?.RRP : farePrice?.NetRate) ?? 0)
                : ((isRRP ? productPricing?.RRP : productPricing?.NetRate) ?? 0);

        return accumulator + price * quantity;
    }, 0);
};

export const calculatePricingInfo = (cartItems: IFareCart[]): IPricingInfo => {
    const totalRRP = calculateTotal(cartItems, true);
    const totalNet = calculateTotal(cartItems, false);

    const commission = totalRRP - totalNet;
    const commissionPercent = totalRRP !== 0 ? (commission / totalRRP) * 100 : 0;
    const totalPrice = totalRRP;

    return {
        totalRRP,
        totalNet,
        commission,
        commissionPercent,
        totalPrice,
    };
};

export const convertCustomerInfoToData = (customerInfo: ICustomerInfo): ISetCustomerData => {
    return {
        firstName: customerInfo.first_name,
        lastName: customerInfo.last_name,
        dateOfBirth: customerInfo.date_of_birth,
        email: customerInfo.email,
        customerIndex: customerInfo.customer_index,
        phoneNumber: `${customerInfo.phone_number}`,
        postalCode: customerInfo.postal_code,
        countryCode: customerInfo.country_code,
    };
};

export const convertCustomerDataToInfo = (customerData: ISetCustomerData): ICustomerInfo => {
    return {
        first_name: customerData.firstName,
        last_name: customerData.lastName,
        date_of_birth: customerData.dateOfBirth,
        email: customerData.email,
        customer_index: customerData.customerIndex,
        phone_number: _.toNumber(customerData.phoneNumber),
        postal_code: customerData.postalCode,
        country_code: customerData.countryCode,
    };
};

export const convertCartItemToDirectPurchaseData = (
    cartItem: IFareCart,
): IDirectPurchaseItemData => {
    return {
        tdmsProductId: cartItem.tdms_product_id,
        productPricesDetailsId: cartItem.product_price_details_id,
        startDate: moment(cartItem.start_date, "YYYY-M-DD").format(
            CHECK_AVAILABILITY_START_DATE_FORMAT,
        ),
        days: cartItem.days,
        selectedAvailableIndices: [cartItem.selected_index],
        timeId: cartItem.booking_data.time_id,
        commences: cartItem.booking_data.commences,
        bookingData: cartItem.bookingData,
    };
};

const hasTravelTimes = (farePrices: ITourFare[]) =>
    _.every(farePrices, (fp) => !_.isNil(fp.travelStart) && !_.isNil(fp.travelEnd));

const hasSamePrices = (farePrices: ITourFare[]) =>
    _.chain(farePrices)
        .filter((fp) => fp.rrp > 0)
        .uniqBy((fp) => fp.rrp)
        .value().length === 1;

const hasSameLevy = (farePrices: ITourFare[]) =>
    _.chain(farePrices)
        .filter((fp) => !_.isEmpty(fp.levy))
        .filter((fp) => !_.isEmpty(fp.levyDescription))
        .uniqBy((fp) => `${fp.levy}-${fp.levyDescription}`)
        .value().length === 1;

const firstStartTravelTime = (travelStartTimes: string[]) =>
    _.chain(travelStartTimes)
        .map((ts) => moment(ts))
        .sortBy((ts) => ts.valueOf())
        .head()
        .value();

const lastEndTravelTime = (travelEndTimes: string[]) =>
    _.chain(travelEndTimes)
        .map((te) => moment(te))
        .sortBy((te) => -te.valueOf())
        .head()
        .value();

export const shouldEnableAvailabilityCheck = (
    checkFrom: Date,
    checkTo: Date,
    farePrices: ITourFare[],
) => {
    const allFarePricesHasTravelRange = hasTravelTimes(farePrices);
    if (!allFarePricesHasTravelRange) {
        return true; // some do not have travel range so allow to check availabliity
    }

    const firstAvailableStartDate = firstStartTravelTime(farePrices.map((fp) => fp.travelStart!));
    const lastAvailableEndDate = lastEndTravelTime(farePrices.map((fp) => fp.travelEnd!));

    const availableRange = moment.range(firstAvailableStartDate, lastAvailableEndDate);
    const checkRange = moment.range(checkFrom, checkTo);
    return !_.isNil(checkRange.intersect(availableRange));
};

export const formatBookingTime = (bookingTime: string) => {
    if (!moment(bookingTime).isValid()) {
        return bookingTime;
    }
    const [hour, minute] = bookingTime.split("_");
    return moment(`${hour}:${minute}`, "HH:mm").format("h:mm a");
};

export const availableTravelTimeMessage = (faresPrices: ITourFare[]): string | null => {
    const shouldShowTravelTimes = hasTravelTimes(faresPrices);
    if (!shouldShowTravelTimes) {
        return null;
    }
    const allowedStartTravelTime = firstStartTravelTime(faresPrices.map((fp) => fp.travelStart!));
    const allowedEndTravelTime = lastEndTravelTime(faresPrices.map((fp) => fp.travelEnd!));
    const isWithinSameYear = allowedStartTravelTime.year === allowedEndTravelTime.year;
    const timeFormat = `DD/MM${isWithinSameYear ? "/YYYY" : ""}`;
    return `${allowedStartTravelTime.format(timeFormat)} to ${allowedEndTravelTime.format(timeFormat)}`;
};

export const buildLevyMessage = (levy: number, levyDescription: string) =>
    `+ ${_.toNumber(levy).toFixed(2)} to pay on arrival ${levyDescription}`;

export const buildPriceGroup = (farePrice: ITourFare, farePricesInGroup: ITourFare[]) => {
    const hasVariablePricing = !hasSamePrices(farePricesInGroup);
    const rrpPrices = _.chain(farePricesInGroup).map((fp) => fp.rrp);
    const rrp = hasVariablePricing
        ? `$${rrpPrices.min().value().toFixed(2)} - $${rrpPrices.max().value().toFixed(2)}`
        : `$${farePrice.rrp.toFixed(2)}`;
    const levyMessage = !hasSameLevy(farePricesInGroup)
        ? null
        : buildLevyMessage(farePrice.levy!, farePrice.levyDescription!);
    const travelTimeMessage = !hasTravelTimes(farePricesInGroup)
        ? null
        : `Available from ${availableTravelTimeMessage(farePricesInGroup)}`;
    const priceGroup: IPriceGroup = {
        id: farePrice.fareTypeId.toString(),
        name: farePrice.fareName,
        hasVariablePricing: hasVariablePricing,
        prices: farePricesInGroup,
        rrp: rrp,
        levy: farePrice.levy ?? null,
        levyMessage: levyMessage,
        travelTimeMessage: travelTimeMessage,
        hasBookingTimeError: false, // TODO: check if we get this from the backend
    };
    return priceGroup;
};

export const shouldCheckAvailabilityByGroup = (
    apiProviderId: number,
    groupFaresForAvailabilityCheck: boolean,
) => apiProviderId > 0 && groupFaresForAvailabilityCheck;

export const faresPricesGroups = (faresPrices: ITourFare[]): IPriceGroup[] => {
    return _.chain(faresPrices)
        .groupBy((fp) => fp.fareTypeId)
        .map((farePrices) => {
            const farePrice = farePrices.at(0)!;
            return buildPriceGroup(farePrice, farePrices);
        })
        .value();
};

export const getMonthsInRange = (start: Date, end: Date) => {
    const startDate = moment(start).startOf("month");
    const endDate = moment(end).startOf("month");
    const months: moment.Moment[] = [];

    while (startDate.isSameOrBefore(endDate, "month")) {
        months.push(moment(startDate));
        startDate.add(1, "month"); // Move to the next month
    }

    return months;
};

export const getCalendarDays = (year: number, month: number, startOfWeek = "Sunday") => {
    const startDay = startOfWeek === "Sunday" ? 0 : 1; // 0 for Sunday, 1 for Monday

    // Get the first and last day of the target month
    const firstDay = moment({ year, month, day: 1 });
    const lastDay = moment(firstDay).endOf("month");

    // Find how many days to pad from the previous month
    const prevPadding = (firstDay.day() - startDay + 7) % 7;

    // Find how many days to pad from the next month
    const nextPadding = (7 - ((lastDay.day() - startDay + 1 + 7) % 7)) % 7;

    // Build the full calendar array
    const days = [];

    // Previous month padding
    for (let i = prevPadding; i > 0; i--) {
        days.push(moment(firstDay).subtract(i, "days"));
    }

    // Current month days
    for (let i = 0; i < lastDay.date(); i++) {
        days.push(moment(firstDay).add(i, "days"));
    }

    // Next month padding
    for (let i = 1; i <= nextPadding; i++) {
        days.push(moment(lastDay).add(i, "days"));
    }

    return days;
};

export const fieldToTimeFieldOption = (field: IAvailField): IProductAvailTimeOption => {
    return {
        type: field.type,
        displayName: field.displayName,
        required: field.required,
        date: moment(field.date, "YYYY-MM-DD").toDate(),
        options: field.options.map((fo) => {
            return {
                id: fo.externalId.toString(),
                displayName: fo.displayName,
                numAvailable: fo.numAvailable,
            };
        }),
    };
};

export const fieldToPickUpFieldOption = (
    timeId: string,
    field: IAvailField,
): IProductAvailPickUpOption => {
    return {
        timeId,
        type: field.type,
        displayName: field.displayName,
        required: field.required,
        options: field.options.map((fo) => {
            return {
                id: fo.externalId.toString(),
                displayName: fo.displayName,
                numAvailable: fo.numAvailable,
            };
        }),
    };
};

export const fieldToDropOffFieldOption = (
    timeId: string,
    field: IAvailField,
): IProductAvailDropOffOption => {
    return {
        timeId,
        type: field.type,
        displayName: field.displayName,
        required: field.required,
        options: field.options.map((fo) => {
            return {
                id: fo.externalId.toString(),
                displayName: fo.displayName,
                numAvailable: fo.numAvailable,
            };
        }),
    };
};

export const getBookingTimeIds = async (productPriceDetailIds: string[], bookingSystem: string) => {
    if (BOOKING_SYSTEMS_WITH_CUSTOM_BOOKING_TIME_IDS.includes(bookingSystem.trim().toLowerCase())) {
        return ["0"];
    } else {
        const bookingDetailsReqs = _.uniq(productPriceDetailIds).map(toursApi.getBookingDetail);
        const bookingDetailsResps = await Promise.allSettled(bookingDetailsReqs);
        bookingDetailsResps.filter((resp) => resp.status === "rejected").forEach(console.error);
        const uniqueBookingTimeIds = _.uniq(
            bookingDetailsResps
                .filter(
                    (resp): resp is PromiseFulfilledResult<AxiosResponse<ITourFareBookingTime>> =>
                        resp.status === "fulfilled",
                )
                .flatMap((resp) =>
                    resp.value.data.bookingTimes.map((bt) => bt.BookingTimeID.toString()),
                ),
        );
        return _.isEmpty(uniqueBookingTimeIds) ? ["0"] : uniqueBookingTimeIds;
    }
};

export const getBookingTravelOptions = async (
    productPricesDetailsId: string,
    fields: IAvailField[],
) => {
    const timeOptionFields: IProductAvailTimeOption[] = fields
        .filter((f) => f.name.toLowerCase() === "timeid")
        .map(fieldToTimeFieldOption);
    const pickUpOptionFields: IProductAvailPickUpOption[] = timeOptionFields.flatMap(
        (timeField) => {
            return timeField.options.flatMap((timeOption) => {
                return fields
                    .filter((f) => f.name.trim().toLowerCase() === "pickupid")
                    .map((f) => fieldToPickUpFieldOption(timeOption.id, f));
            });
        },
    );
    const dropOffOptionFields: IProductAvailPickUpOption[] = timeOptionFields.flatMap(
        (timeField) => {
            return timeField.options.flatMap((timeOption) => {
                return fields
                    .filter((f) => f.name.trim().toLowerCase() === "dropoff")
                    .map((f) => fieldToDropOffFieldOption(timeOption.id, f));
            });
        },
    );

    const confirmOptionsRequests = timeOptionFields.flatMap((timeField) => {
        return timeField.options.map((timeOption) =>
            toursApi
                .getDetailsToConfirmAvailability(
                    productPricesDetailsId,
                    timeField.date,
                    timeOption.id,
                )
                .then((av) => ({ timeId: timeOption.id, avails: av })),
        );
    });

    const confirmOptionsResponses = await Promise.allSettled(confirmOptionsRequests);
    const confirmPickUpOptionFields = confirmOptionsResponses
        .filter(
            (
                co,
            ): co is PromiseFulfilledResult<{
                timeId: string;
                avails: IAvailabilityDetailsToConfirm;
            }> => co.status === "fulfilled",
        )
        .flatMap((co) =>
            co.value.avails.fields
                .filter((f: IAvailField) => f.name.toLowerCase() === "pickupid")
                .map((f: IAvailField) => fieldToPickUpFieldOption(co.value.timeId, f)),
        );

    const confirmDropOffOptionFields = confirmOptionsResponses
        .filter(
            (
                co,
            ): co is PromiseFulfilledResult<{
                timeId: string;
                avails: IAvailabilityDetailsToConfirm;
            }> => co.status === "fulfilled",
        )
        .flatMap((co) =>
            co.value.avails.fields
                .filter((f: IAvailField) => f.name.toLowerCase() === "dropoffs")
                .map((f: IAvailField) => fieldToDropOffFieldOption(co.value.timeId, f)),
        );
    const confirmTimeOptionFields = confirmOptionsResponses
        .filter(
            (
                co,
            ): co is PromiseFulfilledResult<{
                timeId: string;
                avails: IAvailabilityDetailsToConfirm;
            }> => co.status === "fulfilled",
        )
        .flatMap((co) =>
            co.value.avails.fields
                .filter((f: IAvailField) => f.name.toLowerCase() === "timeid")
                .map((f: IAvailField) => fieldToTimeFieldOption(f)),
        );

    const ret: IAvailOptions = {
        timeOptions: timeOptionFields.concat(confirmTimeOptionFields),
        pickUpOptions: pickUpOptionFields.concat(confirmPickUpOptionFields),
        dropOffOptions: dropOffOptionFields.concat(confirmDropOffOptionFields),
    };
    return {
        ppdid: productPricesDetailsId,
        availOptions: ret,
    };
};

// export const getAvailOptions = async (
//     productPricesDetailsId: string,
//     fields: IAvailField[],
//     bookingSystem: string | null,
// ) => {
//     const shouldCheckAvailabilityToConfirm =
//         !_.isNil(bookingSystem) &&
//         [BOOKING_SYSTEM_RESPAX_V2, BOOKING_SYSTEM_IBIS].includes(
//             bookingSystem.trim().toLowerCase(),
//         );

//     if (!shouldCheckAvailabilityToConfirm) {
//         return {
//             timeOptions: [],
//             pickUpOptions: [],
//             dropOffOptions: [],
//         };
//     }
//     const timeOptionFields: IProductAvailTimeOption[] = fields
//         .filter((f) => f.name.toLowerCase() === "timeid")
//         .map(fieldToTimeFieldOption);

//     const confirmOptionsRequests = timeOptionFields.flatMap((timeField) => {
//         return timeField.options.map((timeOption) =>
//             toursApi
//                 .getDetailsToConfirmAvailability(
//                     productPricesDetailsId,
//                     timeField.date,
//                     timeOption.id,
//                 )
//                 .then((av) => ({ timeId: timeOption.id, avails: av.data })),
//         );
//     });

//     const confirmOptionsResponses = await Promise.allSettled(confirmOptionsRequests);
//     const confirmPickUpOptionFields = confirmOptionsResponses
//         .filter((co) => co.status === "fulfilled")
//         .flatMap((co) =>
//             co.value.avails
//                 .flatMap((a) => a.fields)
//                 .filter((f) => f.name.toLowerCase() === "pickupid")
//                 .map((f) => fieldToPickUpFieldOption(co.value.timeId, f)),
//         );
//     const confirmDropOffOptionFields = confirmOptionsResponses
//         .filter((co) => co.status === "fulfilled")
//         .flatMap((co) =>
//             co.value.avails
//                 .flatMap((a) => a.fields)
//                 .filter((f) => f.name.toLowerCase() === "dropoffs")
//                 .map((f) => fieldToDropOffFieldOption(co.value.timeId, f)),
//         );
//     const confirmTimeOptionFields = confirmOptionsResponses
//         .filter((co) => co.status === "fulfilled")
//         .flatMap((co) =>
//             co.value.avails
//                 .flatMap((a) => a.fields)
//                 .filter((f) => f.name.toLowerCase() === "timeid")
//                 .map((f) => fieldToTimeFieldOption(f)),
//         );

//     const ret: IAvailOptions = {
//         timeOptions: timeOptionFields.concat(confirmTimeOptionFields),
//         pickUpOptions: confirmPickUpOptionFields,
//         dropOffOptions: confirmDropOffOptionFields,
//     };
//     return ret;
// };

// export const getAvailabilities = async (
//     apiProviderId: number,
//     groupFaresForAvailabilityCheck: boolean,
//     productId: string,
//     priceGroup: IPriceGroup,
//     startDate: Date,
//     endDate: Date,
//     bookingTimeID: string | null,
//     bookingSystem: string,
// ): Promise<IProductAvailability[]> => {
//     const shouldCheckByGroup = shouldCheckAvailabilityByGroup(
//         apiProviderId,
//         groupFaresForAvailabilityCheck,
//     );
//     if (shouldCheckByGroup) {
//         const availabilitiesByGroupResp = await toursApi.checkAvailByProductAndRange(
//             productId,
//             priceGroup.id,
//             startDate,
//             endDate,
//         );
//         const availWithOptionsReq = availabilitiesByGroupResp.data.map(async (g, index) => {
//             const availOptions = await getAvailOptions(g.FarePrice.productPricesDetailsId.toString(), [], bookingSystem);
//             const ret: IProductAvailability = {
//                 productPricesDetailsId: g.FarePrice.productPricesDetailsId.toString(),
//                 BookingDate: moment(g.BookingDate, BOOKING_DATE_FORMAT).toDate(),
//                 NumAvailable: g.NumAvailable,
//                 RRP: g.FarePrice.RRP,
//                 NetRate: g.FarePrice.NetRate,
//                 levy: priceGroup.levy,
//                 availIndex: index,
//                 ...availOptions,
//             };
//             return ret;
//         });
//         const availWithOptionsResp = await Promise.allSettled(availWithOptionsReq);
//         availWithOptionsResp.filter(resp => resp.status === 'rejected').map(resp => console.error(resp.reason));
//         const ret = availWithOptionsResp.filter(resp => resp.status === 'fulfilled').map(resp => resp.value);
//         return ret;
//     }

//     const pricingAvailsReq = priceGroup.prices.flatMap(async (fp) => {
//         const ppdid = fp.productPricesDetailsId.toString();
//         const availRange = await toursApi.checkAvailRange(
//             ppdid,
//             bookingTimeID!,
//             startDate,
//             moment(endDate).diff(moment(startDate), "days"),
//         );
//         const availReqs = availRange.data.map(async (avail, index) => {
//             const availOptions = await getAvailOptions(ppdid, avail.fields, bookingSystem);
//             return {
//                 productPricesDetailsId: ppdid,
//                 BookingDate: avail.BookingDate,
//                 NumAvailable: avail.NumAvailable,
//                 RRP: avail.productPricingData.RRP,
//                 NetRate: avail.productPricingData.NetRate,
//                 levy: priceGroup.levy,
//                 availIndex: index,
//                 ...availOptions,
//             };
//         });
//         return (await Promise.allSettled(availReqs))
//             .filter((p) => p.status === "fulfilled")
//             .map((p) => p.value);
//     });
//     const pricingAvailsResp = await Promise.allSettled(pricingAvailsReq);
//     return pricingAvailsResp.filter((p) => p.status === "fulfilled").flatMap((p) => p.value);
// };

/**
 *
 * @param farePrices
 * @param selectedFareTypeIndex
 * @param startDate
 * @param endDate
 * @param apiProviderId
 * @param groupFaresForAvailabilityCheck
 * @param productId
 */
export const getAvailabilities = async (
    farePrices: ITourFare[],
    selectedFareTypeIndex: number,
    startDate: Date,
    endDate: Date,
    apiProviderId: number,
    groupFaresForAvailabilityCheck: boolean,
    productId: string,
): Promise<IProductAvailability[]> => {
    const shouldCheckInGroup = shouldCheckAvailabilityByGroup(
        apiProviderId,
        groupFaresForAvailabilityCheck,
    );
    const farePrice = farePrices[selectedFareTypeIndex];
    let productPriceDetailIds: string[] = [];
    if (!shouldCheckInGroup) {
        productPriceDetailIds = farePrices
            .filter((fp) => fp.fareTypeId === farePrice.fareTypeId)
            .filter((fp) => {
                const selectedRange = moment.range(startDate, endDate);
                if (!_.isNil(fp.travelStart) && !_.isNil(fp.travelEnd)) {
                    const travelRange = moment.range(
                        moment(fp.travelStart, FARE_PRICE_TRAVEL_DATE_FORMAT).toDate(),
                        moment(fp.travelEnd, FARE_PRICE_TRAVEL_DATE_FORMAT).toDate(),
                    );
                    return !_.isNil(selectedRange.intersect(travelRange));
                }

                if (!_.isNil(fp.travelStart)) {
                    return selectedRange.contains(
                        moment(fp.travelStart, FARE_PRICE_TRAVEL_DATE_FORMAT).toDate(),
                    );
                }

                if (!_.isNil(fp.travelEnd)) {
                    return selectedRange.contains(
                        moment(fp.travelEnd, FARE_PRICE_TRAVEL_DATE_FORMAT).toDate(),
                    );
                }

                // allow ppdid if both is absent
                return true;
            })
            .map((fp) => fp.productPricesDetailsId.toString());
        const bookingTimeIds = await getBookingTimeIds(
            productPriceDetailIds,
            farePrice.bookingSystem,
        );
        const availabilitiesReq = await bookingTimeIds.flatMap(async (bookingTimeId) => {
            const availRangesReqs = productPriceDetailIds.map((ppdid) =>
                toursApi.checkAvailRange(
                    ppdid.toString(),
                    bookingTimeId,
                    startDate,
                    moment(endDate).diff(startDate, "days"),
                ),
            );
            const availRangesResps = await Promise.allSettled(availRangesReqs);
            const availRangesData = availRangesResps
                .filter(
                    (resp): resp is PromiseFulfilledResult<AxiosResponse<ICheckAvail[]>> =>
                        resp.status === "fulfilled",
                )
                .flatMap((resp) => resp.value.data);
            const travelOptionsReqs = availRangesData.flatMap((a) =>
                getBookingTravelOptions(a.productPricesDetailsId!.toString(), a.fields),
            );
            const travelOptionsResps = await Promise.allSettled(travelOptionsReqs);
            travelOptionsResps.filter((resp) => resp.status === "rejected").forEach(console.error);
            const travelOptions = travelOptionsResps
                .filter(
                    (
                        resp,
                    ): resp is PromiseFulfilledResult<{
                        ppdid: string;
                        availOptions: IAvailOptions;
                    }> => resp.status === "fulfilled",
                )
                .map((resp) => resp.value);
            return availRangesData.map((availData, availIndex) => {
                const travelOption = travelOptions.find(
                    (t) => t.ppdid === availData.productPricesDetailsId?.toString(),
                )?.availOptions;
                const ret: IProductAvailability = {
                    BookingDate: availData.BookingDate,
                    productId: productId,
                    productPricesDetailsId: availData.productPricesDetailsId!.toString(),
                    NumAvailable: availData.NumAvailable,
                    RRP: availData.productPricingData.RRP,
                    NetRate: availData.productPricingData.NetRate,
                    levy: farePrice.levy ?? null,
                    availIndex,
                    bookingTimeId: bookingTimeId,
                    timeOptions: _.isNil(travelOption?.timeOptions)
                        ? []
                        : travelOption!.timeOptions,
                    pickUpOptions: _.isNil(travelOption?.pickUpOptions)
                        ? []
                        : travelOption!.pickUpOptions,
                    dropOffOptions: _.isNil(travelOption?.dropOffOptions)
                        ? []
                        : travelOption!.dropOffOptions,
                };
                return ret;
            });
        });
        const availabilitiesResp = await Promise.allSettled(availabilitiesReq);
        return availabilitiesResp
            .filter(
                (resp): resp is PromiseFulfilledResult<IProductAvailability[]> =>
                    resp.status === "fulfilled",
            )
            .flatMap((resp) => resp.value);
    } else {
        const availabilitiesByProductReq = await toursApi.checkAvailByProductAndRange(
            productId,
            farePrice.fareTypeId.toString(),
            startDate,
            endDate,
        );
        const availabilitiesByProduct = availabilitiesByProductReq.data;
        productPriceDetailIds = _.chain(availabilitiesByProduct)
            .filter((a) => !_.isNil(a.FarePrice?.productPricesDetailsId))
            .map((a) => a.FarePrice!.productPricesDetailsId.toString())
            .uniq()
            .value();
        const bookingTimeIds = await getBookingTimeIds(
            productPriceDetailIds,
            farePrice.bookingSystem,
        );
        const availabilitiesReq = await bookingTimeIds.flatMap(async (bookingTimeId) => {
            const availRangesReqs = productPriceDetailIds.map((ppdid) =>
                toursApi.checkAvailRange(
                    ppdid.toString(),
                    bookingTimeId,
                    startDate,
                    moment(endDate).diff(startDate, "days"),
                ),
            );
            const availRangesResps = await Promise.allSettled(availRangesReqs);
            const availRangesData = availRangesResps
                .filter(
                    (resp): resp is PromiseFulfilledResult<AxiosResponse<ICheckAvail[]>> =>
                        resp.status === "fulfilled",
                )
                .flatMap((resp) => resp.value.data);
            const travelOptionsReqs = availRangesData.flatMap((a) =>
                getBookingTravelOptions(a.productPricesDetailsId!.toString(), a.fields),
            );
            const travelOptionsResps = await Promise.allSettled(travelOptionsReqs);
            travelOptionsResps.filter((resp) => resp.status === "rejected").forEach(console.error);
            const travelOptions = travelOptionsResps
                .filter(
                    (
                        resp,
                    ): resp is PromiseFulfilledResult<{
                        ppdid: string;
                        availOptions: IAvailOptions;
                    }> => resp.status === "fulfilled",
                )
                .map((resp) => resp.value);

            return availabilitiesByProduct.map((availData, availIndex) => {
                const travelOption = travelOptions.find(
                    (t) => t.ppdid === availData.FarePrice?.productPricesDetailsId?.toString(),
                )?.availOptions;
                const ret: IProductAvailability = {
                    productId: productId,
                    BookingDate: moment(availData.BookingDate).toDate(),
                    productPricesDetailsId:
                        availData.FarePrice?.productPricesDetailsId?.toString() ?? null,
                    NumAvailable: availData.NumAvailable,
                    RRP: availData.FarePrice?.RRP ?? null,
                    NetRate: availData.FarePrice?.NetRate ?? null,
                    levy: farePrice.levy ?? null,
                    availIndex,
                    bookingTimeId: bookingTimeId,
                    timeOptions: _.isNil(travelOption?.timeOptions)
                        ? []
                        : travelOption!.timeOptions,
                    pickUpOptions: _.isNil(travelOption?.pickUpOptions)
                        ? []
                        : travelOption!.pickUpOptions,
                    dropOffOptions: _.isNil(travelOption?.dropOffOptions)
                        ? []
                        : travelOption!.dropOffOptions,
                };
                return ret;
            });
        });
        const availabilitiesResp = await Promise.allSettled(availabilitiesReq);
        availabilitiesResp.filter((resp) => resp.status === "rejected").forEach(console.error);
        return availabilitiesResp
            .filter(
                (resp): resp is PromiseFulfilledResult<IProductAvailability[]> =>
                    resp.status === "fulfilled",
            )
            .flatMap((resp) => resp.value);
    }
    // let bookingTimeIds = [];
    // if (!BOOKING_SYSTEMS_WITH_CUSTOM_BOOKING_TIME_IDS.includes(farePrice.bookingSystem)) {
    //     const productPricesDetailsIds = [farePrice.productPricesDetailsId];
    //     const bookingDetailsReqs = productPricesDetailsIds.map(toursApi.getBookingDetail);
    //     const bookingDetailsResps = await Promise.allSettled(bookingDetailsReqs);
    //     bookingDetailsResps.filter(resp => resp.status === 'rejected').forEach(console.error);
    //     bookingTimeIds = _.uniq(
    //         bookingDetailsResps.filter(resp => resp.status === 'fulfilled').flatMap(resp => resp.value.data.bookingTimes.map(bt => bt.BookingTimeID.toString()))
    //     );
    // } else {
    //     bookingTimeIds = ['0'];
    // }

    // bookingTimeIds.map(async (bookingTimeId) => {
    //     const availRangesReqs = productPriceDetailIds.map(ppdid => toursApi.checkAvailRange(ppdid.toString(), bookingTimeId, startDate, moment(endDate).diff(startDate, 'days')));
    //     const availRangesResps = await Promise.allSettled(availRangesReqs);
    //     const availRangesData = availRangesResps.filter(resp => resp.status === 'fulfilled').flatMap(resp => resp.value.data);
    //     const travelOptionsReqs = availRangesData.flatMap(a => getBookingTravelOptions(a.productPricesDetailsId!.toString(), a.fields));
    //     const travelOptionsResps = await Promise.allSettled(travelOptionsReqs);
    //     travelOptionsResps.filter(resp => resp.status === 'rejected').forEach(console.error);
    //     const travelOptions = travelOptionsResps.filter(resp => resp.status === 'fulfilled').map(resp => resp.value);
    // });

    // if (shouldCheckInGroup) {
    //     const availabilitiesByProductReq = await toursApi.checkAvailByProductAndRange(productId, farePrice.fareTypeId.toString(), startDate, endDate);
    //     const availabilitiesByProduct = availabilitiesByProductReq.data;
    //     const availableUniquePPDIDs = _.chain(availabilitiesByProduct).map(a => a.FarePrice.productPricesDetailsId).uniq().value();
    //     if (BOOKING_SYSTEMS_WITH_CUSTOM_BOOKING_TIME_IDS.includes(farePrice.bookingSystem)) {
    //         const bookingTimeId = "0";
    //         const availRangesReqs = availableUniquePPDIDs.map(ppdid => toursApi.checkAvailRange(ppdid.toString(), bookingTimeId, startDate, moment(endDate).diff(startDate, 'days')));
    //         const availRangesResps = await Promise.allSettled(availRangesReqs);
    //         availRangesResps.filter(resp => resp.status === 'rejected').forEach(console.error);
    //         const availRangesData = availRangesResps.filter(resp => resp.status === 'fulfilled').flatMap(resp => resp.value.data);
    //         const travelOptionsReqs = availRangesData.flatMap(a => getBookingTravelOptions(a.productPricesDetailsId!.toString(), a.fields));
    //         const travelOptionsResps = await Promise.allSettled(travelOptionsReqs);
    //         travelOptionsResps.filter(resp => resp.status === 'rejected').forEach(console.error);
    //         const travelOptions = travelOptionsResps.filter(resp => resp.status === 'fulfilled').map(resp => resp.value);
    //     }
    //     const bookingDetailsReqs = availableUniquePPDIDs.map(toursApi.getBookingDetail);
    //     const bookingDetailsResps = await Promise.allSettled(bookingDetailsReqs);
    //     bookingDetailsResps.filter(resp => resp.status === 'rejected').forEach(console.error);
    //     const bookingTimeIds = BOOKING_SYSTEMS_WITH_CUSTOM_BOOKING_TIME_IDS.includes(farePrice.bookingSystem)
    //         ? ['0'] : bookingDetailsResps.filter(resp => resp.status === 'fulfilled').flatMap(resp => resp.value.data.bookingTimes.map(bt => bt.BookingTimeID.toString()));
    // } else {
    //     const productPricesDetailsIds = [farePrice.productPricesDetailsId];
    //     const bookingDetailsReqs = productPricesDetailsIds.map(toursApi.getBookingDetail);
    //     const bookingDetailsResps = await Promise.allSettled(bookingDetailsReqs);
    //     bookingDetailsResps.filter(resp => resp.status === 'rejected').forEach(console.error);
    //     const bookingTimeIDs = _.uniq(
    //         bookingDetailsResps.filter(resp => resp.status === 'fulfilled').flatMap(resp => resp.value.data.bookingTimes.map(bt => bt.BookingTimeID.toString()))
    //     );
    //     bookingTimeIDs.map(async (bookingTimeId) => {
    //         const availRangesReqs = productPricesDetailsIds.map(ppdid => toursApi.checkAvailRange(ppdid.toString(), bookingTimeId, startDate, moment(endDate).diff(startDate, 'days')));
    //         const availRangesResps = await Promise.allSettled(availRangesReqs);
    //         const availRangesData = availRangesResps.filter(resp => resp.status === 'fulfilled').flatMap(resp => resp.value.data);
    //         const travelOptionsReqs = availRangesData.flatMap(a => getBookingTravelOptions(a.productPricesDetailsId!.toString(), a.fields));
    //         const travelOptionsResps = await Promise.allSettled(travelOptionsReqs);
    //         travelOptionsResps.filter(resp => resp.status === 'rejected').forEach(console.error);
    //         const travelOptions = travelOptionsResps.filter(resp => resp.status === 'fulfilled').map(resp => resp.value);
    //     });
    // }
};

// export const convertCheckedAvailToAvailability = (checkedAvailability: ICheckAvail): IAvailability => {
//     const dateFormat = 'DD-MMM-YYY';
//     return {
//         ...checkedAvailability,
//         datePriceCacheId: _.toNumber(checkedAvailability.datePriceCacheId!),
//         productPricesDetailsId: _.toNumber(checkedAvailability.productPricesDetailsId),
//         BookingDate: moment(checkedAvailability.BookingDate).format(dateFormat),
//         BookingTimeID: `${checkedAvailability.BookingTimeID}`,
//         EndDate: moment(checkedAvailability.EndDate).format(dateFormat),
//         DateRestriction: _.isNil(checkedAvailability?.DateRestriction)
//             ? null
//             : moment(checkedAvailability.DateRestriction).format(dateFormat),
//         NumAvailableDisplay: `${checkedAvailability.NumAvailable}`,
//         extraInfo: [],
//     }
// }

// export const buildCartItem = (addToCartItem: IAddCart, checkedAvailability: ICheckAvail): IFareCart => {
//     return {
//         availability: convertCheckedAvailToAvailability(checkedAvailability),
//         availability_last_updated_at: moment().format(),

//     }
// }

export const submitItems = async (items: ISetCartBookingData[], mode: "quote" | "cart") => {
    const responses = await Promise.allSettled(
        items.map(async (itemBookingData) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { location, timeId, pickupId, pickupLocation, ...restOptionalData } =
                itemBookingData.bookingData;
            const parsedLocation = typeof location === "string" ? JSON.parse(location) : location;
            const payload = {
                quantity: 1,
                bookingData: {
                    timeId: timeId || "",
                    optionalData: { ...restOptionalData },
                    ...(parsedLocation?.pickupId
                        ? { pickupId: String(parsedLocation.pickupId) }
                        : {}),
                    ...(parsedLocation?.pickupLocation
                        ? { pickupLocation: parsedLocation.pickupLocation }
                        : {}),
                },
            };
            switch (mode) {
                case "quote": {
                    const saveCartItemResponse = await cartApi.saveCart(
                        itemBookingData.itemId!,
                        payload,
                    );
                    return saveCartItemResponse.data;
                }
                case "cart": {
                    const updateQuoteItemResponse = await quoteApi.updateQuoteItem(
                        itemBookingData.itemId!,
                        payload,
                    );
                    return updateQuoteItemResponse.data;
                }
            }
        }),
    );
    return responses;
};
