export const IconSearch = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 18.8984C14.866 18.8984 18 15.7645 18 11.8984C18 8.03244 14.866 4.89844 11 4.89844C7.134 4.89844 4 8.03244 4 11.8984C4 15.7645 7.134 18.8984 11 18.8984Z"
            stroke="#898D8F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M20 20.8984L16 16.8984"
            stroke="#898D8F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
