import { IProductAvailability, IProductAvailOption } from "@interfaces";
import {
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import BookNowButton from "./BookNowButton";
import { breakpoint } from "@utilities";

interface AddToCartButtonProps {
    onTogglePopup?: () => void;
    availability: IProductAvailability;
    isSubmitInProgress: boolean;
    handleAdd: (
        availability: IProductAvailability,
        mode: "addToCart" | "bookNow" | "newQuote" | "existingQuote",
        timeId?: IProductAvailOption,
        pickUpLocation?: IProductAvailOption,
        dropOffLocation?: IProductAvailOption,
    ) => void;
}

export const AddToCartButton = forwardRef<HTMLDivElement, AddToCartButtonProps>(
    ({ availability, isSubmitInProgress, handleAdd }: AddToCartButtonProps, ref) => {
        const bookingDateKey = moment(availability.BookingDate).format("YYYY-MM-DD");

        // Extract unique options
        const timeOptions = _.uniqBy(
            availability.timeOptions.flatMap((to) => to.options),
            (opt) => opt.id,
        );
        const pickUpLocations = _.uniqBy(
            availability.pickUpOptions.flatMap((po) => po.options),
            (opt) => opt.id,
        );
        const dropOffLocations = _.uniqBy(
            availability.dropOffOptions.flatMap((po) => po.options),
            (opt) => opt.id,
        );

        // State management
        const [selectedTimeId, setSelectedBookingTimeId] = useState<
            IProductAvailOption | undefined
        >(timeOptions.at(0) || undefined);
        const [selectedPickUpLocationId, setSelectedPickUpLocationId] = useState(
            pickUpLocations.at(0)?.id || "",
        );
        const [selectedDropOffLocationId, setSelectedDropOffLocation] = useState(
            dropOffLocations.at(0)?.id || "",
        );
        const [isOpen, setIsOpen] = useState(false);
        const [isEnabled, setIsEnabled] = useState(true);
        const selectedPickUpLocation = pickUpLocations.find(
            (loc) => loc.id === selectedPickUpLocationId,
        );
        const selectedDropOffLocation = dropOffLocations.find(
            (loc) => loc.id === selectedDropOffLocationId,
        );

        // Menu Props
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: 200,
                    width: 250,
                },
            },
        };

        // Handle selection changes
        const handleTimeChange = (option: IProductAvailOption) => {
            setSelectedBookingTimeId(option);
        };

        const handlePickUpChange = (event: SelectChangeEvent<string>) => {
            const newValue = event.target.value;
            setSelectedPickUpLocationId(newValue);
        };

        const handleDropOffChange = (event: SelectChangeEvent<string>) => {
            const newValue = event.target.value;
            setSelectedDropOffLocation(newValue);
        };

        // Toggle dropdown for additional actions
        const handleDropdownToggle = (event: React.MouseEvent) => {
            event.stopPropagation();
            setIsOpen(!isOpen);
        };
        // Form validation
        useEffect(() => {
            const isFormValid = () => {
                if (isSubmitInProgress) return false;
                if (availability.timeOptions.some((o) => o.required) && !selectedTimeId)
                    return false;
                if (availability.pickUpOptions.some((o) => o.required) && !selectedPickUpLocationId)
                    return false;
                if (availability.dropOffOptions.some((o) => o.required) && !selectedDropOffLocation)
                    return false;
                return true;
            };
            setIsEnabled(isFormValid());
        }, [isSubmitInProgress, selectedTimeId, selectedPickUpLocationId, selectedDropOffLocation]);

        return (
            <Secondary ref={ref}>
                <div className="text-container">
                    <div key={bookingDateKey} className="secondary-container">
                        {/* Booking Date */}
                        <div className="date-container">
                            {moment(availability.BookingDate).format("D MMMM YYYY")}
                        </div>

                        {/* Time Selection */}
                        {!_.isEmpty(timeOptions) && (
                            <div className="time-container">
                                <p>Select Time</p>
                                <div className="time">
                                    {timeOptions.map((option) => (
                                        <span key={option.id}>
                                            <input
                                                type="radio"
                                                name={`time-${bookingDateKey}`}
                                                value={option.id}
                                                checked={selectedTimeId?.id === option.id}
                                                onChange={() => handleTimeChange(option)}
                                            />
                                            {option.displayName}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}

                        {/* Pickup Location */}
                        {!_.isEmpty(pickUpLocations) && (
                            <FormControl className="formControlResponsive">
                                <InputLabel id={`pickup-select-label-${bookingDateKey}`}>
                                    Pick Up Location
                                </InputLabel>
                                <Select
                                    labelId={`pickup-select-label-${bookingDateKey}`}
                                    value={selectedPickUpLocationId}
                                    onChange={handlePickUpChange}
                                    input={<OutlinedInput label="Pick Up Location" />}
                                    MenuProps={MenuProps}
                                >
                                    {pickUpLocations.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.displayName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {/* Drop-off Location */}
                        {!_.isEmpty(dropOffLocations) && (
                            <FormControl className="formControlResponsive">
                                <InputLabel id={`dropOff-select-label-${bookingDateKey}`}>
                                    Drop Off Location
                                </InputLabel>
                                <Select
                                    labelId={`dropOff-select-label-${bookingDateKey}`}
                                    value={selectedDropOffLocationId}
                                    onChange={handleDropOffChange}
                                    MenuProps={MenuProps}
                                >
                                    {dropOffLocations.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.displayName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </div>
                </div>

                {/* Buttons */}
                <ButtonContainer>
                    <BookNowButton
                        disabled={!isEnabled}
                        handleClick={() =>
                            handleAdd(
                                availability,
                                "bookNow",
                                selectedTimeId,
                                selectedPickUpLocation,
                                selectedDropOffLocation,
                            )
                        }
                    />

                    <AddButton>
                        <div className={`button-container ${!isEnabled ? "disabled" : ""}`}>
                            <div
                                className="add-to-cart-button"
                                onClick={() =>
                                    handleAdd(
                                        availability,
                                        "addToCart",
                                        selectedTimeId,
                                        selectedPickUpLocation,
                                        selectedDropOffLocation,
                                    )
                                }
                            >
                                Add to Cart
                            </div>
                            <div className="divider" />
                            <button
                                className={`dropdown-toggle ${isOpen ? "open" : ""}`}
                                onClick={handleDropdownToggle}
                                disabled={!isEnabled}
                            >
                                <img
                                    src="/images/chevron-up-white.svg"
                                    alt="chevron"
                                    className={`chevron ${isOpen ? "rotated" : ""}`}
                                />
                            </button>
                        </div>

                        {isOpen && (
                            <div className="dropdown-menu">
                                <div
                                    className="dropdown-item"
                                    onClick={() =>
                                        handleAdd(
                                            availability,
                                            "existingQuote",
                                            selectedTimeId,
                                            selectedPickUpLocation,
                                            selectedDropOffLocation,
                                        )
                                    }
                                >
                                    Add to existing quote
                                </div>
                                <div
                                    className="dropdown-item"
                                    onClick={() =>
                                        handleAdd(
                                            availability,
                                            "newQuote",
                                            selectedTimeId,
                                            selectedPickUpLocation,
                                            selectedDropOffLocation,
                                        )
                                    }
                                >
                                    Save as new quote
                                </div>
                            </div>
                        )}
                    </AddButton>
                </ButtonContainer>
            </Secondary>
        );
    },
);

const Secondary = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;

    ${breakpoint.breakTablet`
        padding: 30px;
  `}

    ${breakpoint.breakOnlyMobile`
        display:flex;
        flex-direction : column;
        align-items :start;
  `}
    ${breakpoint.breakIpadPro`
        width: ${(p) => p.theme.widths.wide_900};
    `}

    ${breakpoint.breakLarge`
        width: ${(p) => p.theme.widths.wide_1110};
    `}

    .text-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    .secondary-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
    .date-container {
        font-weight: bold;
        font-size: 18px;
    }
    .time-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        p {
            font-size: 18px;
        }
        .time {
            display: flex;
            gap: 32px;
            span {
                display: flex;
                gap: 8px;
                font-weight: 600;
            }
        }
    }

    .formControlResponsive {
        width: 500px;

        @media (min-width: 375px) {
            width: 342px;
        }
        @media (min-width: 425px) {
            width: 392px;
        }
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;

    ${breakpoint.breakOnlyMobile`
        width : 100%;
        flex-direction: column;
  `}
`;
const AddButton = styled.div`
    width: 250px;

    ${breakpoint.breakOnlyMobile`
        width : 100%;
    `}

    position: relative;

    .button-container {
        border-radius: 4px;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: auto;
        justify-content: space-between;
        background: linear-gradient(to right, #436cb2, #28b7c3);
        height: 54px;
        position: relative;
        padding: 0 12px;
        transition:
            background 0.3s ease,
            opacity 0.3s ease;
    }

    .button-container.disabled {
        background: linear-gradient(to right, #a0a0a0, #bdbdbd);
        opacity: 0.6;
        pointer-events: none;
    }

    .add-to-cart-button {
        border: none;
        background: transparent;
        color: white;
        font-weight: bold;
        cursor: pointer;
        text-align: center;
        flex-grow: 1;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .divider {
        height: 32px;
        width: 1px;
        background-color: white;
        margin: 0 8px;
    }
    .dropdown-toggle {
        border: none;
        background: transparent;
        color: white;
        cursor: pointer;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
    }
    .chevron {
        transition: transform 0.2s ease-in-out;
    }
    .chevron.rotated {
        transform: rotate(180deg);
    }
    .dropdown-menu {
        position: absolute;
        bottom: 110%;
        right: 0;
        width: 100%;
        background: white;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border: 1px solid #ddd;
        z-index: 10;
    }
    .dropdown-item {
        padding: 12px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        text-align: center;
        color: black;
        border-bottom: 1px solid #eee;
    }
    .dropdown-item:last-child {
        border-bottom: none;
    }
    .dropdown-item:hover {
        background: #f7f7f7;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(9, 10, 24, 0.4);
        z-index: 9999;
    }
    .modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        padding: 38px 46px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        width: 100%;
        max-width: 732px;
        z-index: 10000;
    }
    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .modal-header h2 {
        font-size: 28px;
        font-weight: 600;
    }
    .close-btn {
        background: transparent;
        position: absolute;
        top: 8%;
        right: 3%;
        border: none;
        color: #000;
        font-size: 20px;
        cursor: pointer;
    }
    .modal-body label {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .modal-body {
        margin-top: 15px;
        width: 100%;
        padding: 10px;
        font-size: 14px;
    }
    .modal-footer {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
    .create-btn {
        width: 216px;
        font-size: 16px;
        height: 54px;
        background: linear-gradient(to right, #436cb2, #28b7c3);
        color: white;
        font-weight: bold;
        border: none;
        cursor: pointer;
    }
    .create-btn:hover {
        background: linear-gradient(to right, #28b7c3, #436cb2);
    }
`;
