import { useState } from "react";
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, TextField, FormControl, RadioGroup,
  List, ListItem, ListItemButton, FormControlLabel, Radio
} from "@mui/material";

import { create, InstanceProps } from "react-modal-promise";
import { IQuote } from "src/Interfaces/quotes";

interface ICreateNewQuoteModalProps extends InstanceProps<string, string> {
    isOpen: boolean,
    onResolve: (title?: string) => void,
    onReject: () => void,
}

export const CreateNewQuoteModal = ({ isOpen, onResolve, }: ICreateNewQuoteModalProps) => {
    const [quoteTitle, setQuoteTitle] = useState("");
  
    return (
      <Dialog open={isOpen} onClose={() => onResolve()}>
        <DialogTitle>Create New Quote</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Quote Title"
            fullWidth
            variant="outlined"
            value={quoteTitle}
            onChange={(e) => setQuoteTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onResolve()} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => onResolve(quoteTitle)} color="primary" disabled={!quoteTitle.trim()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

export const createNewQuoteModal = create<ICreateNewQuoteModalProps>(CreateNewQuoteModal);

interface IAddToExistingQuoteModalProps extends InstanceProps<IQuote, void> {
    isOpen: boolean,
    onResolve: (quote?: IQuote) => void,
    onReject: () => void,
    quotes: IQuote[],
}

export const AddToExistingQuoteModal = ({ isOpen, onResolve, quotes }: IAddToExistingQuoteModalProps) => {
    const [selectedQuote, setSelectedQuote] = useState<IQuote | null>(null);
  
    return (
      <Dialog open={isOpen} onClose={() => onResolve()}>
        <DialogTitle>Select an Existing Quote</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup value={selectedQuote?.id || ""} onChange={(e) => {
              const quote = quotes.find(q => q.id.toString() === e.target.value);
              setSelectedQuote(quote || null);
            }}>
              <List>
                {quotes.map((quote) => (
                  <ListItem key={quote.id} disablePadding>
                    <ListItemButton onClick={() => setSelectedQuote(quote)}>
                      <FormControlLabel
                        value={quote.id.toString()}
                        control={<Radio />}
                        label={quote.title}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onResolve()} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => onResolve(selectedQuote!)} color="primary" disabled={!selectedQuote}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

export const addToExistingQuoteModal = create(AddToExistingQuoteModal);
