import styled from "styled-components";
import { Fragment, useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useWindowDimensions, detectSpaceWhite } from "@utilities";
import {
    ClipLoaderCommon,
    ProductDetailComponent,
    DetailTourMobileComponent,
    StyledTourDetail,
    StyledNeedToKnow,
    NeedToKnowTagCommon,
    NeedToKnowContentCommon,
    BreadcrumbsCommon,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { FareTypeModule, PriceDetailTourModule } from "@module";
import { PATH_DETAIL_TOUR, PATH_HOME, PATH_SEARCH_RESULT } from "@routes";
import { getTour, selectTour, setSupplier, useAppDispatch, useAppSelector } from "@redux";
import { IDetailTour } from "@interfaces";

export const DetailTour = () => {
    //page hooks
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    //redux state
    const tourRedux = useAppSelector(selectTour);
    //page state
    const [currentNeedToKnowIndex, setCurrentNeedToKnowIndex] = useState<number>(0);
    const [isCheckFare, setIsCheckFare] = useState<boolean>(false);
    //page variable
    const { width } = useWindowDimensions();
    const fareTypeRef = useRef(null);
    const { id } = useParams<{ id: string }>();
    const [tour, setTour] = useState<IDetailTour | null>(null);

    useEffect(() => {
        const fetchTour = async () => {
            await dispatch(getTour(id!));
        };
        if (id) fetchTour();
    }, [id]);

    const isLoading = tourRedux.isLoading;

    useEffect(() => {
        if (tourRedux.tour?.productId === id) {
            setTour(tourRedux.tour);
        }
    }, [tourRedux.tour, id]);

    const breadcrumbsData = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Search result",
            link: PATH_SEARCH_RESULT,
        },
        {
            title: "Detail tour",
            link: PATH_DETAIL_TOUR + "/" + id,
        },
    ];
    //get product if product is no in redux

    const handleSwitchNeedToKnow = (index: number) => {
        setCurrentNeedToKnowIndex(index);
    };

    const handleClickCheckAvail = () => {
        if (fareTypeRef.current) {
            //@ts-ignore
            const scrollHeight = fareTypeRef.current.scrollHeight;
            let scrollPosition = scrollHeight;
            if (width >= 1200) {
                scrollPosition = scrollHeight + 168;
            } else if (width >= 1024) {
                scrollPosition = scrollHeight + 210;
            } else {
                scrollPosition = scrollHeight + 150;
            }
            window.scrollTo({
                top: scrollPosition,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    const handleClickSupplier = () => {
        navigate(PATH_SEARCH_RESULT);
        dispatch(
            setSupplier({
                id: Number(tourRedux.tour?.supplierId),
                text: tourRedux.tour?.supplier,
            }),
        );
    };

    //webview application
    const webView = tour && (
        <Fragment>
            <ProductDetailComponent
                breadcrumbsData={breadcrumbsData}
                data={tour}
                onClickCheckAvail={handleClickCheckAvail}
                handleClickSupplier={handleClickSupplier}
            />
            <div className="fareType">
                <StyledTourDetail>
                    <div className="heading">
                        <h3>Need to know</h3>
                    </div>
                    <StyledNeedToKnow>
                        {tour.description && (
                            <>
                                <div className="listTags">
                                    {tour.description.map((item, index) => {
                                        if (detectSpaceWhite(item.content)) return;
                                        return (
                                            <NeedToKnowTagCommon
                                                isActive={currentNeedToKnowIndex === index}
                                                key={index}
                                                onSwitchNeedToKnow={() => {
                                                    handleSwitchNeedToKnow(index);
                                                }}
                                                title={item.title}
                                            />
                                        );
                                    })}
                                </div>
                                <div className="listContentWrapper">
                                    <div className="listContents">
                                        {tour.description.map((item, index) => {
                                            return (
                                                <NeedToKnowContentCommon
                                                    key={index}
                                                    isActive={currentNeedToKnowIndex === index}
                                                    content={item.content || ""}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </>
                        )}
                    </StyledNeedToKnow>
                </StyledTourDetail>
            </div>
            <div className="fareType" ref={fareTypeRef}>
                <PriceDetailTourModule
                    productClass={tour.productClass}
                    tourFare={tour.faresPrices}
                    tourId={id!}
                />
            </div>
        </Fragment>
    );

    const fareTypeMobile = tour && (
        <FareTypeMobileContainer>
            <div className="breadcrumbs">
                <BreadcrumbsCommon data={breadcrumbsData} />
            </div>
            <FareTypeModule
                apiProviderId={tour.apiProviderId}
                groupFaresForAvailabilityCheck={tour.groupFaresForAvailabilityCheck}
                productClass={tour.productClass}
                tourFare={tour.faresPrices}
                tourId={id!}
                fareName={tour.name}
            />
        </FareTypeMobileContainer>
    );

    const handleCheckFare = () => {
        window.scrollTo({
            top: 0,
        });
        setIsCheckFare(true);
    };

    const mobileView = isCheckFare
        ? fareTypeMobile
        : tour && (
              <DetailTourMobileComponent
                  tour={tour}
                  onCheckFare={handleCheckFare}
                  handleClickSupplier={handleClickSupplier}
              />
          );

    const handleBack = () => {
        if (isCheckFare) {
            setIsCheckFare(false);
        } else {
            window.history.back();
        }
    };

    return (
        <AppModuleLayout onBack={handleBack}>
            <Wrapper>
                {isLoading ? (
                    <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
                ) : (
                    <>
                        {tour ? (
                            width >= 767 ? (
                                webView
                            ) : (
                                mobileView
                            )
                        ) : (
                            <div className="noProduct">
                                <img src="/images/notFound.svg" alt="" />
                                <p className="line1">No Products Found</p>
                                <p className="line2">
                                    Your search did not match any products. <br />
                                    Please try again.
                                </p>
                            </div>
                        )}
                    </>
                )}
            </Wrapper>
        </AppModuleLayout>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;

    .fareType {
        background-color: #f2f2f2;

        &:last-child {
            padding-bottom: 100px;
        }
    }

    .noProduct {
        text-align: center;
        padding-top: 20px;
        font-size: 22px;

        .line1 {
            font-weight: 700;
            padding-bottom: 20px;
        }

        .line2 {
            font-size: 18px;
            line-height: 22px;
        }
    }
`;

const FareTypeMobileContainer = styled.div`
    width: 100%;
    background-color: #f2f2f2;
    padding: 26px 16px;
    min-height: calc(100vh - 95px);

    .noFare {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        font-weight: 700;
    }

    .breadcrumbs {
        margin-bottom: 20px;
    }
`;
