import styled from "styled-components";

import { breakpoint } from "@utilities";
import FooterTag from "./FooterTag";
import { selectSavedCarts, useAppSelector } from "@redux";

export interface ITag {
    text: string;
    Icon?: () => JSX.Element;
    link: string;
    active?: boolean;
}

interface IFooterProps {
    tagList?: ITag[];
}

export const FooterMobileComponent = (props: IFooterProps) => {
    const isShowBtn = useAppSelector(selectSavedCarts).isShowPopup;

    return !isShowBtn ? (
        <StyledFooter>
            {props.tagList && props.tagList.map((tag, index) => <FooterTag key={index} {...tag} />)}
        </StyledFooter>
    ) : (
        <></>
    );
};

const StyledFooter = styled.div`
    position: fixed;
    bottom: 0;
    height: 80px;
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: #fff;
    z-index: 2;

    ${breakpoint.breakTablet`
        display: none;
    `}
`;
