import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";

import {
    BreadcrumbsCommon,
    ClipLoaderCommon,
    MenuAccountComponent,
    StyledSavedCartPage,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_BOOKING, PATH_BOOKING_DETAIL, PATH_HOME } from "@routes";
import { breakpoint } from "@utilities";
import { getListBooking, AppDispatch } from "@redux";
import { IListBookings } from "@interfaces";
import { NavLink } from "react-router-dom";
import moment from "moment";

export const Booking = () => {
    // page hooks
    const dispatch = useDispatch<AppDispatch>();

    //page state
    const [bookings, setBookings] = useState<IListBookings[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const breadcrumbsModel = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Bookings",
            link: PATH_BOOKING,
        },
    ];

    useEffect(() => {
        fetchBookings();
    }, []);

    const fetchBookings = async () => {
        try {
            const res = await dispatch(getListBooking());
            //@ts-ignore
            unwrapResult(res);
            //@ts-ignore
            setBookings(res.payload);
        } catch {
            setBookings([]);
        } finally {
            setLoading(false);
        }
    };

    const formatPurchaseDate = (purchaseDate?: string | null): string => {
        if (!purchaseDate) return "N/A"; // Handle cases where purchaseDate is null or undefined
        const date = new Date(purchaseDate);
        return moment(date).format("dddd, D MMMM");
    };

    return (
        <AppModuleLayout>
            {loading ? (
                <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
            ) : (
                <StyledSavedCartPage>
                    <div className="wrapper">
                        <div className="breadCrumbs">
                            <BreadcrumbsCommon data={breadcrumbsModel} />
                        </div>
                        <div className="subWrapper">
                            <div className="menu">
                                <MenuAccountComponent />
                            </div>
                            <div className="listCarts">
                                <div className="heading">
                                    <p>Bookings</p>
                                </div>
                                {bookings.length > 0 ? (
                                    bookings.map((booking, index) => (
                                        <div key={index}>
                                            {booking.products && booking.products.length > 0 && (
                                                <div className="products-list">
                                                    {booking.products.map(
                                                        (product, productIndex) => (
                                                            <div key={productIndex}>
                                                                {product.redeemers.map(
                                                                    (item, index) => (
                                                                        <div key={index}>
                                                                            {item.bookingDetails.map(
                                                                                (detail) => (
                                                                                    <DateText>
                                                                                        {formatPurchaseDate(
                                                                                            detail.travelDate,
                                                                                        ) || "N/A"}
                                                                                    </DateText>
                                                                                ),
                                                                            )}
                                                                        </div>
                                                                    ),
                                                                )}
                                                                <StyledSavedCartWrapper>
                                                                    <StyledDateWrapper>
                                                                        <div className="product-container">
                                                                            <div className="productName">
                                                                                {product.name}
                                                                            </div>
                                                                            <NavLink
                                                                                to={`${PATH_BOOKING_DETAIL}/${booking.bookingReference}/${product.tripId}`}
                                                                            >
                                                                                <img
                                                                                    src="images/ChevronVectorRight.svg"
                                                                                    alt="Right arrow"
                                                                                />
                                                                            </NavLink>
                                                                        </div>
                                                                        <StyledInfo>
                                                                            <div className="content-container">
                                                                                <span className="content">
                                                                                    Voucher Number:
                                                                                    <span className="content-data">
                                                                                        {
                                                                                            product.voucherNumber
                                                                                        }
                                                                                    </span>
                                                                                </span>
                                                                                <span className="content">
                                                                                    Guest:
                                                                                    <span className="content-data">
                                                                                        {
                                                                                            product.qty
                                                                                        }
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div className="content-container">
                                                                                <span className="content">
                                                                                    Start Time:
                                                                                    <span className="content-data">
                                                                                        {
                                                                                            product.startLocation
                                                                                        }
                                                                                    </span>
                                                                                </span>
                                                                                <span className="content">
                                                                                    End Date:
                                                                                    <span className="content-data">
                                                                                        {
                                                                                            product.startLocation
                                                                                        }
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                        </StyledInfo>
                                                                    </StyledDateWrapper>
                                                                </StyledSavedCartWrapper>
                                                            </div>
                                                        ),
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <div className="heading">
                                        <p>You don't have any bookings</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </StyledSavedCartPage>
            )}
        </AppModuleLayout>
    );
};

const DateText = styled.div`
    color: #131214;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
`;

const StyledSavedCartWrapper = styled.div<{
    isList?: boolean;
}>`
    background-color: white;
    margin-bottom: 24px;
`;

const StyledDateWrapper = styled.div`
    padding: 16px 0;
    padding-left: 20px;
    padding-right: 25px;
    display: flex;
    color: #6e7375;
    gap: 10px;
    flex-direction: column;
    border-bottom: 1px solid #e0e0e0;
    position: relative;

    .product-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

    .productName {
        display: flex;  
        flex-direction : row;
        align-items: center;
        color: #131214;
        font-size: 18px;
        font-weight: bold;
        ${breakpoint.breakOnlyMobile`
            margin-left : 0px;
        `}
    }
`;

const StyledInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    .content-container {
        display: flex;
        color: #4f4f4f;
        font-size: 14px;
        gap: 120px;
        .content-data {
            font-weight: 500;
        }

        .content {
            display: flex;
            gap: 6px;
            ${breakpoint.breakOnlyMobile`
                flex-direction : column;
                gap : 3px;
            `}
        }
    }
`;
