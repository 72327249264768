import styled from "styled-components";
import { Link } from "react-router-dom";

import { breakpoint } from "@utilities";

export const StyledTourElement = styled.div`
    position: relative;

    .heart {
        position: absolute;
        top: 0;
        right: 15px;
        z-index: 1;

        ${breakpoint.breakTablet`
            right: 34px;
        `}

        ${breakpoint.breakIpadPro`
            right: 40px;
        `}

        &.searchResultPage {
            ${breakpoint.breakTablet`
                right: 15px;
            `}
        }
    }
`;

export const StyledTourElementMain = styled(Link)`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 30px;

    ${breakpoint.breakTablet`
        width: 202px;
        margin-right: 22px;
        margin-bottom: 0;
    `}

    ${breakpoint.breakIpadPro`
        width: 286px;
    `}
    
    ${breakpoint.breakLarge`
        width: 355.33333px;
    `}
    
    .tour_image {
        position: relative;
        width: 100%;
        padding-top: 50%;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 8px 8px 0 0;
        background-size: cover;
    }

    .main {
        background-color: #fff;
        border-radius: 0 0 8px 8px;
        ${breakpoint.breakIpadPro`
            min-height : 120px;
         `}
        ${breakpoint.breakLarge`
            min-height : 140px;
         `}
        ${breakpoint.breakMobile`
           min-height : 110px;
        `}
    }

    .info {
        padding: 12px 12px 0 12px;

        ${breakpoint.breakTablet`
            padding: 10px 10px 0 10px;
        `}

        ${breakpoint.breakIpadPro`
            padding: 16px 16px 0 16px;
        `}
        
        ${breakpoint.breakLarge`
            padding: 16px 16px 0 16px;
        `}

        p {
            margin: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 1.3em;
            color: ${(props) => props.theme.colors.black};

            ${breakpoint.breakTablet`
                font-size: 12px;
            `}

            ${breakpoint.breakIpadPro`
                font-size: 16px;
            `}
            
            ${breakpoint.breakLarge`
                font-size: 20px;
            `}
        }
        .destination__title {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            word-break: break-word;
        }

        .destination__place {
            font-weight: 400;
            display: flex;
            color: #6e7375;
            align-items: center;

            ${breakpoint.breakTablet`
                margin-top: 4px;
                font-size: 12px;
            `}

            ${breakpoint.breakIpadPro`
                margin-top: 6px;
            `}
            
            ${breakpoint.breakLarge`
                margin-top: 12px;
                font-size: 12px;
            `}
            
            svg {
                margin-right: 5px;
                width: 19px;

                ${breakpoint.breakMobile`
                    margin-right: 10px;
                    width: 15px;
                `}

                ${breakpoint.breakTablet`
                    margin-right: 5px;
                `}

                ${breakpoint.breakIpadPro`
                    margin-right: 10px;
                    width: 19px;
                `}
            }
            .placeName {
                line-height: 1.3em;
                font-size: 14px;
                font-weight: 400;

                ${breakpoint.breakMobile`
                    font-size: 16px;
                `}

                ${breakpoint.breakTablet`
                    font-size: 10px;
                `}

                ${breakpoint.breakIpadPro`
                    font-size: 12px;
                `}
                
                ${breakpoint.breakLarge`
                    font-size: 16px;
                `}
            }
        }

        .description {
            font-weight: 400;
            font-size: 12px;
            height: 40px;
            overflow: hidden;

            ${breakpoint.breakMobile`
                font-size: 14px;
            `}

            span {
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
            }
        }

        .supplier {
            font-weight: 700;
            font-size: 12px;
            margin-top: 12px;

            ${breakpoint.breakMobile`
                font-size: 14px;
            `}

            span {
                color: ${(props) => props.theme.colors.main};
            }
        }
    }

    .durationNPrice {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px 12px 12px;
        color: #000;

        ${breakpoint.breakTablet`
            padding: 0 10px 12px 10px;
        `}

        ${breakpoint.breakIpadPro`
            padding: 0 16px 12px 16px;
        `}
        
        ${breakpoint.breakLarge`
            padding: 0 18px 12px 18px;
        `}
        
        .destination__duration {
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
                width: 19px;

                ${breakpoint.breakMobile`
                    margin-right: 10px;
                    width: 15px;
                `}

                ${breakpoint.breakTablet`
                    margin-right: 5px;
                `}

                ${breakpoint.breakIpadPro`
                    margin-right: 10px;
                    width: 19px;
                `}
            }
            span {
                font-size: 14px;
                font-weight: 400;
                white-space: nowrap;

                ${breakpoint.breakMobile`
                    font-size: 16px;
                `}

                ${breakpoint.breakTablet`
                    font-size: 10px;
                `}

                ${breakpoint.breakIpadPro`
                    font-size: 12px;
                `}
                
                ${breakpoint.breakLarge`
                    font-size: 16px;
                `}
            }
        }

        .destination__price {
            display: flex;
            align-items: center;

            span {
                font-size: 12px;
                font-weight: 400;

                ${breakpoint.breakMobile`
                    font-size: 14px;
                `}

                ${breakpoint.breakTablet`
                    font-size: 8px;
                `}

                ${breakpoint.breakIpadPro`
                    font-size: 12px;
                `}
                
                ${breakpoint.breakLarge`
                    font-size: 14px;
                `}
            }

            .price {
                line-height: 26px;
                font-size: 16px;
                font-weight: 700;
                margin-left: 9px;

                ${breakpoint.breakMobile`
                    font-size: 20px;
                `}

                ${breakpoint.breakTablet`
                    font-size: 12px;
                    margin-left: 4px;
                `}

                ${breakpoint.breakIpadPro`
                    font-size: 16px;
                    margin-left: 7px;
                `}
                
                ${breakpoint.breakLarge`
                    font-size: 20px;
                    margin-left: 9px;
                `}
            }
            .form {
                position: relative;
            }
        }
    }
`;

export const StyledTourElementSearch = styled(Link)`
    display: flex;
    position: relative;
    margin-bottom: 36px;
    /* height: 284px; */

    ${breakpoint.breakTablet`
        height: 208px;
    `}

    ${breakpoint.breakIpadPro`
        height: 284px;
    `}

    .tour_image {
        position: relative;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        flex: 1;
        background-color: #fff;
        background-size: cover;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    .main {
        flex: 2;
        display: flex;
        flex-direction: column;
        padding-top: 0 !important;

        .info {
            flex: 1;
            background-color: #fff;
            padding: 16px;
            min-height: 110px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-top-right-radius: 8px;

            ${breakpoint.breakTablet`
                padding: 10px;
                min-height: 80px;
            `}

            ${breakpoint.breakIpadPro`
                padding: 24px;
                min-height: 110px;
            `}
            
            ${breakpoint.breakLarge`
                padding: 31px;
                min-height: 125px;
            `}

            p {
                margin: 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 1.3em;
                color: ${(props) => props.theme.colors.black};
                max-width: 75%;
                ${breakpoint.breakTablet`
                    font-size: 12px;
                `}

                ${breakpoint.breakIpadPro`
                    font-size: 16px;
                `}
                
                ${breakpoint.breakLarge`
                    font-size: 20px;
                `}
            }

            .destination__place {
                margin-top: 12px;
                font-weight: 400;
                display: flex;
                align-items: center;

                ${breakpoint.breakTablet`
                    margin-top: 4px;
                    font-size: 12px;
                `}

                ${breakpoint.breakIpadPro`
                    margin-top: 6px;
                `}
                
                ${breakpoint.breakLarge`
                    margin-top: 12px;
                    font-size: 12px;
                `}
            
                svg {
                    margin-right: 5px;
                    width: 19px;

                    ${breakpoint.breakMobile`
                        margin-right: 10px;
                        width: 15px;
                    `}

                    ${breakpoint.breakTablet`
                        margin-right: 5px;
                    `}

                    ${breakpoint.breakIpadPro`
                        margin-right: 10px;
                        width: 19px;
                    `}
                }
                .placeName {
                    line-height: 1.3em;
                    font-size: 14px;
                    font-weight: 400;

                    ${breakpoint.breakMobile`
                        font-size: 16px;
                    `}

                    ${breakpoint.breakTablet`
                        font-size: 10px;
                    `}

                    ${breakpoint.breakIpadPro`
                        font-size: 12px;
                    `}
                    
                    ${breakpoint.breakLarge`
                        font-size: 16px;
                    `}
                }
            }

            .description {
                margin-top: 12px;
                font-weight: 400;
                font-size: 12px;
                height: 40px;
                overflow: hidden;
                line-height: 22px;
                color: ${({ theme }) => theme.colors.gray_2};

                ${breakpoint.breakTablet`
                    font-size: 10px;
                `}

                ${breakpoint.breakIpadPro`
                    font-size: 12px;
                `}


                ${breakpoint.breakLarge`
                    font-size: 14px;
                `}

                span {
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                }
            }

            .supplier {
                font-weight: 700;
                font-size: 12px;
                margin-top: 12px;
                line-height: 1.5em;

                ${breakpoint.breakTablet`
                    font-size: 10px;
                `}

                ${breakpoint.breakIpadPro`
                    font-size: 12px;
                `}


                ${breakpoint.breakLarge`
                    font-size: 14px;
                `}

                span {
                    color: ${(props) => props.theme.colors.main};
                }
            }
        }
        .durationNPrice {
            background-color: #000;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            padding: 0 16px;
            border-bottom-right-radius: 8px;

            ${breakpoint.breakTablet`
                padding: 0 10px;
            `}

            ${breakpoint.breakIpadPro`
                padding: 0 24px;
            `}
            
            ${breakpoint.breakLarge`
                padding: 0 31px;
            `}
        
        .destination__duration {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                    width: 19px;

                    ${breakpoint.breakMobile`
                    margin-right: 10px;
                    width: 15px;
                `}

                    ${breakpoint.breakTablet`
                    margin-right: 5px;
                `}

                ${breakpoint.breakIpadPro`
                    margin-right: 10px;
                    width: 19px;
                `}
                
                path {
                        stroke: #ffffff;
                    }
                }
                span {
                    font-size: 14px;
                    font-weight: 400;
                    white-space: nowrap;

                    ${breakpoint.breakMobile`
                    font-size: 16px;
                `}

                    ${breakpoint.breakTablet`
                    font-size: 10px;
                `}

                ${breakpoint.breakIpadPro`
                    font-size: 12px;
                `}
                
                ${breakpoint.breakLarge`
                    font-size: 16px;
                `}
                }
            }

            .destination__price {
                display: flex;
                align-items: center;

                span {
                    font-size: 12px;
                    font-weight: 400;

                    ${breakpoint.breakMobile`
                    font-size: 14px;
                `}

                    ${breakpoint.breakTablet`
                    font-size: 8px;
                `}

                ${breakpoint.breakIpadPro`
                    font-size: 12px;
                `}
                
                ${breakpoint.breakLarge`
                    font-size: 14px;
                `}
                }

                .price {
                    line-height: 26px;
                    font-size: 16px;
                    font-weight: 700;
                    margin-left: 9px;

                    ${breakpoint.breakMobile`
                    font-size: 20px;
                `}

                    ${breakpoint.breakTablet`
                    font-size: 12px;
                    margin-left: 4px;
                `}

                ${breakpoint.breakIpadPro`
                    font-size: 16px;
                    margin-left: 7px;
                `}
                
                ${breakpoint.breakLarge`
                    font-size: 20px;
                    margin-left: 9px;
                `}
                }
            }
        }
    }
`;
