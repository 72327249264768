import { Formik, FieldArray, Form, FormikHelpers } from "formik";
import styled from "styled-components";
import { useEffect, useState } from "react";
import {
    AddMyDetailsComponent,
    BinSymbolCommon,
    CustomerDetailFormComponent,
    MyDetailsComponent,
    StyledButton,
    TotalPriceComponent,
} from "@components";
import { checkPayment, fetchCustomer, selectAccount, useAppDispatch, useAppSelector } from "@redux";
import * as Yup from "yup";
import { externalApi } from "@api";
import { ErrorToast } from "src/Components/toasts/ErrorToast";
import { customerDetailSchema } from "@validations";
import moment from "moment";
import _ from "lodash";
import { breakpoint } from "@utilities";
import { ICustomerInfo, IFareCart, ISetCustomerData } from "@interfaces";
import { convertCustomerInfoToData } from "src/Utilities/cartHelper";

interface IProps {
    items: IFareCart[];
    customers: ICustomerInfo[];
    handleCustomersSave: (customerData: ISetCustomerData[]) => Promise<void>;
    handleSwitchToPayment: () => void;
}

export const CheckoutModule = (props: IProps) => {
    const { items, customers, handleCustomersSave, handleSwitchToPayment } = props;
    const dispatch = useAppDispatch();
    const { account } = useAppSelector(selectAccount);
    const [countriesData, setCountriesData] = useState([]);
    const [isShowErrorDate, setIsShowErrorDate] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchCustomer());
        dispatch(checkPayment());
    }, [items.length]);

    const initialValues: { customers: ISetCustomerData[] } = {
        customers: customers.length > 0 ? customers.map(convertCustomerInfoToData) : [],
    };

    useEffect(() => {
        (async () => {
            const res = await externalApi.getCountriesDate();
            setCountriesData(res.data);
        })();
    }, []);

    const getDateValue = (dateOfBirth: string | Date | null | undefined): Date | null => {
        if (!dateOfBirth) return null;
        return typeof dateOfBirth === "string" ? new Date(dateOfBirth) : dateOfBirth;
    };
    const isAccountIncomplete = !(
        account?.first_name &&
        account?.last_name &&
        account?.email &&
        account?.phone_number &&
        account?.post_code &&
        account?.country_code
    );

    const handleSubmit = async (
        values: { customers: ISetCustomerData[] },
        { setSubmitting, validateForm }: FormikHelpers<{ customers: ISetCustomerData[] }>,
    ) => {
        try {
            const validationResult = await validateForm();
            if (!_.isEmpty(validationResult)) {
                return;
            }
            setSubmitting(true);
            const formattedValues = {
                ...values,
                customers: values.customers.map((customer) => ({
                    ...customer,
                    dateOfBirth: moment(customer.dateOfBirth).format("DD-MMM-YYYY"),
                })),
            };
            await handleCustomersSave(formattedValues.customers);
        } catch (error) {
            console.error(error);
            ErrorToast({ Message: "Failed to update customers" });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
                customers: Yup.array().of(customerDetailSchema),
            })}
            onSubmit={handleSubmit}
        >
            {({
                values,
                isValid,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                submitForm,
            }) => (
                <Container>
                    <div className="wrapper">
                        <Form className="form">
                            <Info>
                                <FieldArray
                                    name="customers"
                                    render={(arrayHelpers) => (
                                        <User>
                                            {!isAccountIncomplete ? (
                                                <MyDetailsComponent
                                                    account={account}
                                                    countriesData={countriesData}
                                                />
                                            ) : (
                                                <AddMyDetailsComponent />
                                            )}

                                            {values.customers.map((customer, index) => {
                                                const dateValue = getDateValue(
                                                    customer.dateOfBirth,
                                                );

                                                const handleChangeDate = (
                                                    date: Date | null,
                                                    index: number,
                                                ) => {
                                                    setIsShowErrorDate(true);
                                                    const formattedDate = date
                                                        ? moment(date).format("DD-MMM-YYYY")
                                                        : null;
                                                    setFieldValue(
                                                        `customers[${index}].dateOfBirth`,
                                                        formattedDate,
                                                    );
                                                };

                                                return (
                                                    <div
                                                        className="listCustomerDetail"
                                                        key={customer.customerIndex || index}
                                                    >
                                                        <Container2>
                                                            <Header>
                                                                <p className="heading">
                                                                    Additional Traveller
                                                                </p>
                                                                <div
                                                                    className="bin"
                                                                    onClick={() =>
                                                                        arrayHelpers.remove(index)
                                                                    }
                                                                >
                                                                    <BinSymbolCommon />
                                                                </div>
                                                            </Header>
                                                            <FormContainer>
                                                                <CustomerDetailFormComponent
                                                                    name={`customers[${index}]`}
                                                                    values={customer}
                                                                    countriesData={countriesData}
                                                                    dateValue={dateValue}
                                                                    handleChangeDate={(date) =>
                                                                        handleChangeDate(
                                                                            date,
                                                                            index,
                                                                        )
                                                                    }
                                                                    isShowErrorDate={
                                                                        isShowErrorDate
                                                                    }
                                                                    handleChange={handleChange}
                                                                    handleBlur={handleBlur}
                                                                    handleSubmit={handleSubmit}
                                                                />
                                                            </FormContainer>
                                                            <div
                                                                className="btnRefresh"
                                                                onClick={() => {
                                                                    values.customers.forEach(
                                                                        (_, index) => {
                                                                            setFieldValue(
                                                                                `customers[${index}].firstName`,
                                                                                "",
                                                                            );
                                                                            setFieldValue(
                                                                                `customers[${index}].lastName`,
                                                                                "",
                                                                            );
                                                                            setFieldValue(
                                                                                `customers[${index}].phoneNumber`,
                                                                                "",
                                                                            );
                                                                            setFieldValue(
                                                                                `customers[${index}].email`,
                                                                                "",
                                                                            );
                                                                            setFieldValue(
                                                                                `customers[${index}].postalCode`,
                                                                                "",
                                                                            );
                                                                            setFieldValue(
                                                                                `customers[${index}].dateOfBirth`,
                                                                                null,
                                                                            );
                                                                            setFieldValue(
                                                                                `customers[${index}].countryCode`,
                                                                                "",
                                                                            );
                                                                        },
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src="/images/fi_refresh-ccw.svg"
                                                                    alt="fi_refresh-ccw"
                                                                />
                                                                <span>Reset</span>
                                                            </div>
                                                        </Container2>
                                                    </div>
                                                );
                                            })}
                                            {!isAccountIncomplete && (
                                                <>
                                                    <div className="secondaryActions">
                                                        <div className="btnAddCustomer">
                                                            <img
                                                                src="/images/fi_plus-circle.png"
                                                                alt="fi_plus-circle"
                                                            />
                                                            <span
                                                                onClick={() => {
                                                                    const newIndex =
                                                                        values.customers.length;
                                                                    arrayHelpers.push({
                                                                        customerIndex: newIndex,
                                                                        id: Date.now(),
                                                                        firstName: "",
                                                                        lastName: "",
                                                                        phoneNumber: "",
                                                                        email: "",
                                                                        postalCode: "",
                                                                        dateOfBirth: "",
                                                                        countryCode: "",
                                                                    });
                                                                }}
                                                            >
                                                                Add Additional Traveller
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <StyledButton
                                                        className="saveButton"
                                                        color={["#436CB2 0%", "#28B7C3 100%"]}
                                                        borderRadius="4px"
                                                        type="submit"
                                                        disabled={!isValid || isSubmitting}
                                                    >
                                                        {isSubmitting ? "Saving ..." : "Save"}
                                                    </StyledButton>
                                                </>
                                            )}
                                        </User>
                                    )}
                                />
                            </Info>
                        </Form>
                        <div className="totalPriceContainer">
                            <TotalPriceComponent
                                cartItems={items}
                                btnTitle="Payment"
                                disableNext={
                                    !isValid ||
                                    items.length === 0 ||
                                    isSubmitting ||
                                    isAccountIncomplete
                                }
                                onBtnClick={handleSwitchToPayment}
                                page="checkout"
                                onSaveClick={async () => {
                                    await submitForm();
                                }}
                            />
                        </div>
                    </div>
                </Container>
            )}
        </Formik>
    );
};
const Container = styled.div`
    background-color: #f2f2f2;
    width: 100%;
    .secondaryActions {
        display: flex;
        justify-content: start;
        margin-top: 19px;

        .btnAddCustomer {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 21px;
            color: ${(props) => props.theme.colors.main};
            cursor: pointer;
            span {
                margin-left: 14px;
            }
        }
    }

    .totalPriceContainer {
        display: flex;
        justify-content: flex-end;
        position: relative;

        ${breakpoint.breakOnlyMobile`
            position: fixed;
            bottom: 0;
            background-color: white;
            z-index: 100; 
            width: 100%;
        `}
    }

    ${breakpoint.breakTablet`
        background-color: #F2F2F2;
        & > .wrapper {
            display: flex;
            width: ${(p) => p.theme.widths.wide_700};
            margin: 0 auto;
        }
        .totalPrice {
            flex: 350;
        }
        .bottom {
            background-color: transparent;
            padding: 15px 0 0 0;
            .title {
                font-size: 15px;
            }
        }
    `}
    ${breakpoint.breakIpadPro`
        & > .wrapper {
            width: ${(p) => p.theme.widths.wide_900};
        }
    `}
    
    ${breakpoint.breakLarge`
        & > .wrapper {
            width: ${(p) => p.theme.widths.wide_1110};
        }
        .bottom {
            padding: 32px 0 0 0;
            .title {
                font-size: 18px;
            }
        }
    `}

    .form {
        ${breakpoint.breakLarge`
            width: ${(p) => p.theme.widths.wide_1110};
        `}
    }
`;

const Info = styled.div`
    padding: 16px;
    ${breakpoint.breakTablet`
        padding: 0 16px 44px 0;
    `}
`;

const User = styled.div`
    .listCustomerDetail {
        display: block;
        margin-bottom: 16px;
    }

    .userItem,
    .editUser {
        display: none;

        ${breakpoint.breakTablet`
            display: flex;
        `}
    }
    .saveButton {
        ${breakpoint.breakOnlyMobile`
            display: none;
        `}
    }
`;

const Container2 = styled.div`
    background-color: #fff;
    padding: 28px 0;
    .btnRefresh {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: bold;
        margin: 10px 28px 0;
        span {
            margin-left: 13px;
        }
        color: ${(props) => props.theme.colors.black};
        cursor: pointer;
    }
`;

const Header = styled.div`
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;

    .bin {
        display: block;
        cursor: pointer;
    }

    .heading {
        font-size: 20px;
        font-weight: 700;
    }
`;

const FormContainer = styled.div`
    overflow-y: scroll;

    ${breakpoint.breakTablet`
        overflow-y: unset;
    `}
    ${breakpoint.breakOnlyMobile`
        overflow-y: unset;
    `}
`;
