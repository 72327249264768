import { memo, useState } from "react";

import { IProductAvailability } from "@interfaces";
import { AvailBlockComponent } from "@components";
import _ from "lodash";

interface IProps {
    availData: IProductAvailability | null;
    date: Date;
    isChecked: boolean;
    onCheck: (checkDate: IProductAvailability) => void;
}

export const AvailBlockModule = memo((props: IProps) => {
    //props
    const { availData, date, isChecked, onCheck } = props;
    //page state
    const [isCheck, setIsCheck] = useState<boolean>(isChecked);

    const handleClick = () => {
        if (!_.isNil(availData)) {
            setIsCheck(!isCheck);
            onCheck(availData);
        }
    };

    return (
        <AvailBlockComponent
            date={date}
            availData={availData}
            isChecked={isCheck}
            onCheck={handleClick}
        />
    );
});
