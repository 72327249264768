import { Link } from "react-router-dom";
import styled from "styled-components";
import { selectAccount, useAppSelector } from "@redux";
import { IconLogOut } from "@components";
import { IItemMenuHeader } from "@interfaces";
import { breakpoint, useLogoutHandler } from "@utilities";

interface IProps {
    listMenuItems: IItemMenuHeader[];
}

export const MenuHeaderComponent = (props: IProps) => {
    const { listMenuItems } = props;
    const { account } = useAppSelector(selectAccount);

    const handleLogout = useLogoutHandler();

    return (
        <Container>
            {
                <>
                    {listMenuItems.map((menuItem, index) => {
                        const Icon = menuItem.icon;
                        if (account?.sso_type !== "email" && menuItem.title === "Update Password") {
                            return null;
                        }
                        return (
                            <Link className="linkItem" to={menuItem.link} key={index}>
                                <div className="itemWrapper">
                                    <span className="icon">
                                        {/* <IconHeart /> */}
                                        <Icon />
                                    </span>
                                    <span className="linkText">{menuItem.title}</span>
                                </div>
                            </Link>
                        );
                    })}
                    <div className="linkItem logOut" onClick={handleLogout}>
                        <div className="itemWrapper">
                            <span className="icon">
                                <IconLogOut />
                            </span>
                            <span className="linkText">Logout</span>
                        </div>
                    </div>
                </>
            }
        </Container>
    );
};

const Container = styled.div`
    width: 255px;
    background-color: #fff;
    position: absolute;
    ${breakpoint.breakOnlyMobile`
        top: calc(100% - 20px);
    `}
    top: calc(100% + 20px);
    right: 0;
    box-shadow: 4px 4px 45px rgba(0, 0, 0, 0.08);
    border: 1px solid ${(p) => p.theme.colors.gray_5};
    padding: 0 30px;

    .linkItem {
        cursor: pointer;

        .itemWrapper {
            display: flex;
            align-items: center;
            padding: 24px 0;
            border-bottom: 1px solid ${(p) => p.theme.colors.gray_5};
        }

        .linkText {
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            color: #000;
        }

        .icon {
            margin-right: 16px;

            svg {
                width: 20px;

                path {
                    stroke: ${(p) => p.theme.colors.gray_1} !important;
                }
            }
        }

        &:last-child {
            .itemWrapper {
                border-bottom: none;
            }
        }
    }

    .logOut {
        color: ${(p) => p.theme.colors.gray_3};

        .itemWrapper {
            .icon svg {
                path {
                    stroke: ${(p) => p.theme.colors.red} !important;
                }
            }

            .linkText {
                color: ${(p) => p.theme.colors.red};
            }
        }
    }
`;
