import { useEffect, useMemo, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import queryString from "query-string";

import {
    DatePickerCommon,
    FareOptionComponent,
    ProductDetailComponent,
    StyledRatio34Image,
    ClipLoaderCommon,
    BreadcrumbsCommon,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { diffDate, useWindowDimensions } from "@utilities";
import {
    PATH_BOOK_NOW,
    PATH_CART,
    PATH_CHECK_AVAILABILITY,
    PATH_DETAIL_TOUR,
    PATH_HOME,
    PATH_LOGIN,
    PATH_QUOTES,
    PATH_SEARCH_RESULT,
} from "@routes";
import {
    IFareAvail,
    ICheckAvailDateRange,
    ICheckPriceAvailQueryData,
    IProductAvailability,
    IProductAvailOption,
} from "@interfaces";
import { CheckAvailTableModule } from "@module";
import {
    getTour,
    openToast,
    selectTour,
    useAppSelector,
    setSupplier,
    useAppDispatch,
    addToBookNow,
    addToCartAndFetch,
    selectAuth,
    selectAccount,
} from "@redux";
import { DURATION_TIME } from "@constants";
import _ from "lodash";
import moment from "moment";
import { LoadingStage } from "src/Interfaces/shared";
import { getAvailabilities } from "src/Utilities/cartHelper";
import { ErrorToast } from "src/Components/toasts/ErrorToast";
import { AddToCartButton } from "src/Module/TourCheckAvail/AddToCartButton";
import { TextField } from "@mui/material";
import { addToExistingQuoteModal, createNewQuoteModal } from "src/Module/Modals";
import {
    addItemToExistingQuoteAndReload,
    createNewQuoteAndReload,
    loadQuotes,
    selectQuotes,
} from "src/Redux/Slices/quotes";
import { IQuote } from "src/Interfaces/quotes";

export const CHANGE_DATE_FROM = "FROM";
export const CHANGE_DATE_TO = "TO";

export const CheckAvailability = () => {
    //page hook
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { id, fareTypeId } = useParams<{ id: string; fareTypeId: string }>();
    const startDateFromParam = moment(searchParams.get("startDate")).toDate();
    const daysFromParam = _.toNumber(searchParams.get("days"));
    const checkPriceAvailQueryDatatFromParam: ICheckPriceAvailQueryData = {
        startDate: startDateFromParam,
        days: Math.abs(_.isNaN(daysFromParam) ? 14 : daysFromParam),
        bookingTimeId: searchParams.get("bookingTimeId"),
    };
    const [bookingTimeId, setBookingTimeId] = useState(
        checkPriceAvailQueryDatatFromParam.bookingTimeId,
    );
    const [checkedDateRange, setCheckedDateRange] = useState<ICheckAvailDateRange>({
        from: checkPriceAvailQueryDatatFromParam.startDate,
        to: moment(checkPriceAvailQueryDatatFromParam.startDate)
            .add(checkPriceAvailQueryDatatFromParam.days, "days")
            .toDate(),
    });
    const params = queryString.parse(window.location.search) as unknown as IFareAvail;
    //redux state
    const tourRedux = useAppSelector(selectTour);
    //page state
    // const [checkAvailData, setCheckAvailData] = useState<ICheckAvail[]>([]);
    const [availabilities, setAvailabilities] = useState<IProductAvailability[]>([]);
    const [, setAvailLoadingStage] = useState<LoadingStage>();
    const [selectedAvailabilities, setSelectedAvailabilities] = useState<IProductAvailability[]>(
        [],
    );
    // const [pagingCheckAvailData, setPagingCheckAvailData] = useState<ICheckAvail[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
    //page variable
    const { width } = useWindowDimensions();
    const isChoseDate = useRef({ from: false, to: false });
    const { jwt } = useAppSelector(selectAuth);
    const { account } = useAppSelector(selectAccount);
    const shouldRedirect = useMemo(() => _.isNil(account) || _.isNil(jwt), [account, jwt]);

    const tour = tourRedux.tour?.productId === id ? tourRedux.tour : null;
    const mainRef = useRef<any>(null);
    const { quotes } = useAppSelector(selectQuotes);
    const [isSubmitInProgres, setIsSubmitInProgress] = useState(false);
    const addToCartRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setSearchParams({
            startDate: checkedDateRange.from.toISOString(),
            days: moment(checkedDateRange.to).diff(checkedDateRange.from, "days").toString(),
            bookingTimeId: bookingTimeId?.toString() ?? "",
        });
    }, [checkedDateRange, bookingTimeId]);

    useEffect(() => {
        const goToDetail = () => {
            navigate(PATH_DETAIL_TOUR + "/" + id);
        };
        if (!_.isNil(tour)) {
            const selectedFareType = tour.faresPrices.find((fp) => fp.fareTypeId === fareTypeId);
            if (_.isNil(selectedFareType)) {
                goToDetail();
            }
            // const shouldCheckInGroup = shouldCheckAvailabilityByGroup(
            //     tour.apiProviderId,
            //     tour.groupFaresForAvailabilityCheck,
            // );
            // const isCustomBooking = BOOKING_SYSTEMS_WITH_CUSTOM_BOOKING_TIME_IDS.includes(
            //     selectedFareType!.bookingSystem,
            // );
            // if (isCustomBooking) {
            //     setBookingTimeId("0")
            // }
            // if (shouldCheckInGroup || isCustomBooking) {
            //     if (bookingTimeId !== "0") {
            //         setBookingTimeId("0");
            //     }
            //     return;
            // }

            // if (_.isNil(bookingTimeId)) {
            //     goToDetail();
            // }
        }
    }, [tour?.productId]);

    //get tour detail
    useEffect(() => {
        if (id !== tour?.productId) dispatch(getTour(id!));
    }, [id]);

    useEffect(() => {
        if (mainRef.current) {
            mainRef.current.scrollIntoView();
        }
    }, []);

    useEffect(() => {
        const _diffDate = diffDate(checkedDateRange.from, checkedDateRange.to);
        if (_diffDate >= 0) {
            setIsLoading(true);
            // const farePricesGroups = faresPricesGroups(
            //     tour!.apiProviderId,
            //     tour!.groupFaresForAvailabilityCheck,
            //     tour!.faresPrices,
            // );
            const selectedFarePriceIndex = tour!.faresPrices.findIndex(
                (fp) => fp.fareTypeId === fareTypeId,
            );
            getAvailabilities(
                tour!.faresPrices,
                selectedFarePriceIndex,
                checkedDateRange.from,
                checkedDateRange.to,
                tour!.apiProviderId,
                tour!.groupFaresForAvailabilityCheck,
                tour!.productId,
            )
                .then((avails) => {
                    if (
                        !avails.some((a) => a.bookingTimeId === bookingTimeId) &&
                        !_.isEmpty(avails)
                    ) {
                        setBookingTimeId(avails.at(0)!.bookingTimeId);
                    }
                    setAvailLoadingStage(LoadingStage.INITIALIZE_COMPLETE);
                    setAvailabilities(avails);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    ErrorToast({ Message: "Something went wrong" });
                });
            // toursApi
            //     .checkAvail({ ...params, ...checkedDateRange })
            //     .then((res: any) => {
            //         const dataProcess = res.data.map((item: any) => {
            //             return {
            //                 ...item,
            //             };
            //         });
            //         setCheckAvailData(dataProcess);
            //         setPagingCheckAvailData(
            //             dataProcess.filter((item: ICheckAvail) => item.NumAvailable > 0),
            //         );
            //     })
            //     .catch((err: any) => {
            //         // eslint-disable-next-line no-console
            //         console.log(err);
            //     })
            //     .finally(() => setIsLoading(false));
        } else {
            dispatch(
                openToast({
                    type: "error",
                    autoHideDuration: DURATION_TIME,
                    message: "Invalid date.",
                }),
            );
        }
    }, [checkedDateRange.from, checkedDateRange.to, tour?.productId]);

    // useEffect(() => {
    //     setPagingCheckAvailData(checkAvailData.slice(0, PAGINATION));
    // }, [checkAvailData]);

    const breadcrumbsData = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Search result",
            link: PATH_SEARCH_RESULT,
        },
        {
            title: "Detail tour",
            link: PATH_DETAIL_TOUR + "/" + id,
        },
        {
            title: "Check Availability",
            link: PATH_CHECK_AVAILABILITY + "/" + id + window.location.search,
        },
    ];

    // const maxDate = dateRange.from ? getRelativeDate(new Date(dateRange.from), 13, 1) : null;
    // const minDate = dateRange.to ? getRelativeDate(new Date(dateRange.to), 13, 0) : null;

    const now = new Date();
    // const travelStart = params.minDate ? new Date(params.minDate) : now;
    // const minDate = travelStart > now ? travelStart : now;
    // const maxDate = params.maxDate ? new Date(params.maxDate) : null;
    // const { account } = useAppSelector(selectAccount);
    // const { jwt } = useAppSelector(selectAuth);

    const nextYear = moment(now).add(1, "year").toDate();
    const limitDate = {
        from: {
            minDate: now,
            maxDate: nextYear,
        },
        to: {
            minDate: now,
            maxDate: nextYear,
        },
    };

    // const handleGoToCart = () => {
    //     document.body.style.overflowY = "";
    //     navigate(`${PATH_CART}`);
    //     // set lại date form và date to về null
    //     dispatch(setDateRangeFrom(null));
    //     dispatch(setDateRangeTo(null));
    //     // reset lại pickup time
    //     dispatch(resetPickupTimes());
    //     // set lại step và currentStep
    //     dispatch(setStep(1));
    //     dispatch(setCurrentStep(1));
    // };

    // const handleGotoHome = () => {
    //     document.body.style.overflowY = "";
    //     navigate(PATH_HOME);
    //     dispatch(setStep(1));
    // };

    const handleClickSupplier = () => {
        navigate(PATH_SEARCH_RESULT);
        dispatch(
            setSupplier({
                id: Number(tourRedux.tour?.supplierId),
                text: tourRedux.tour?.supplier,
            }),
        );
    };

    // const handleCheck = (checkDate: IProductAvailability) => {
    //     let updatedCheckedDate = [];

    //     // Check if the date is already selected
    //     const checkedIndex = selectedAvailabilities.findIndex(
    //         (date) => checkDate.BookingDate === date.BookingDate,
    //     );

    //     if (checkedIndex >= 0) {
    //         // If already checked, remove it
    //         updatedCheckedDate = selectedAvailabilities.filter(
    //             (date) => date.BookingDate !== checkDate.BookingDate,
    //         );
    //     } else {
    //         // Otherwise, add it
    //         updatedCheckedDate = [...selectedAvailabilities, checkDate];
    //     }

    //     setSelectedAvailabilities(updatedCheckedDate);
    //     setIsShowPopup(updatedCheckedDate.length > 0);
    // };

    const handleAvailCheck = (checkDate: IProductAvailability) => {
        let updatedCheckedDate = [];

        // Check if the date is already selected
        const checkedIndex = selectedAvailabilities.findIndex(
            (date) => checkDate.BookingDate === date.BookingDate,
        );

        if (checkedIndex >= 0) {
            // If already checked, remove it
            updatedCheckedDate = selectedAvailabilities.filter(
                (date) => date.BookingDate !== checkDate.BookingDate,
            );
        } else {
            // Otherwise, add it
            updatedCheckedDate = [...selectedAvailabilities, checkDate];
        }

        setSelectedAvailabilities(updatedCheckedDate);
        setIsShowPopup(updatedCheckedDate.length > 0);
    };

    const [isModalOpen, setIsModalOpen] = useState({
        existingQuote: false,
        newQuote: false,
    });

    const closeModal = () => {
        setIsModalOpen({ existingQuote: false, newQuote: false });
    };

    useEffect(() => {
        if (selectedAvailabilities.length > 0 && addToCartRef.current) {
            addToCartRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [selectedAvailabilities]);

    const handleAdd = async (
        availability: IProductAvailability,
        mode: "addToCart" | "bookNow" | "newQuote" | "existingQuote",
        timeId?: IProductAvailOption,
        pickUpLocation?: IProductAvailOption,
        dropOffLocation?: IProductAvailOption,
    ) => {
        if (shouldRedirect) {
            const returnPath = encodeURIComponent(location.pathname);
            const params = `?return_to=${returnPath}`;
            navigate(`${PATH_LOGIN}${params}`, { replace: true });
            return;
        }
        try {
            setIsSubmitInProgress(true);
            if (mode === "newQuote") {
                await createNewQuoteModal().then(async (title?: string) => {
                    if (!_.isEmpty(title)) {
                        await dispatch(
                            createNewQuoteAndReload({
                                tdmsProductId: availability.productId,
                                productPricesDetailsId: availability.productPricesDetailsId!,
                                timeId: availability.bookingTimeId ?? "0",
                                startDate: moment(checkedDateRange.from).format("DD-MMM-Y"),
                                days: moment(checkedDateRange.to)
                                    .diff(moment(checkedDateRange.from), "days")
                                    .toString(),
                                selectedAvailableIndices: [availability.availIndex],
                                quoteTitle: title!,
                            }),
                        ).unwrap();
                        navigate(PATH_QUOTES);
                    }
                });
            } else if (mode === "existingQuote") {
                await dispatch(loadQuotes());
                await addToExistingQuoteModal({ quotes }).then(async (quote?: IQuote) => {
                    if (!_.isNil(quote)) {
                        await dispatch(
                            addItemToExistingQuoteAndReload({
                                tdmsProductId: availability.productId,
                                productPricesDetailsId: availability.productPricesDetailsId!,
                                timeId: availability.bookingTimeId ?? "0",
                                startDate: moment(checkedDateRange.from).format("DD-MMM-Y"),
                                days: moment(checkedDateRange.to)
                                    .diff(moment(checkedDateRange.from), "days")
                                    .toString(),
                                selectedAvailableIndices: [availability.availIndex],
                                quoteId: quote.id.toString(),
                            }),
                        ).unwrap();
                        navigate(PATH_QUOTES);
                    }
                });
            } else if (mode === "bookNow") {
                await dispatch(
                    addToBookNow({
                        tdmsProductId: availability.productId,
                        productPricesDetailsId: availability.productPricesDetailsId!,
                        startDate: moment(checkedDateRange.from).format("DD-MMM-Y"),
                        days: moment(checkedDateRange.to).diff(
                            moment(checkedDateRange.from),
                            "days",
                        ),
                        selectedAvailableIndices: [availability.availIndex],
                        bookingData: {
                            pickupLocation: pickUpLocation?.displayName,
                            pickupId: pickUpLocation?.id,
                            dropoffLocation: dropOffLocation?.displayName,
                            dropoffId: dropOffLocation?.id,
                            commences: timeId?.displayName,
                            timeId: timeId?.id,
                        },
                    }),
                ).unwrap();
                navigate(PATH_BOOK_NOW);
            } else if (mode === "addToCart") {
                await dispatch(
                    addToCartAndFetch({
                        tdmsProductId: availability.productId,
                        productPricesDetailsId: availability.productPricesDetailsId!,
                        startDate: moment(checkedDateRange.from).format("DD-MMM-Y"),
                        days: moment(checkedDateRange.to).diff(
                            moment(checkedDateRange.from),
                            "days",
                        ),
                        selectedAvailableIndices: [availability.availIndex],
                        bookingData: {
                            pickupLocation: pickUpLocation?.displayName,
                            pickupId: pickUpLocation?.id,
                            dropoffLocation: dropOffLocation?.displayName,
                            dropoffId: dropOffLocation?.id,
                            commences: timeId?.displayName,
                            timeId: timeId?.id,
                        },
                    }),
                ).unwrap();
                navigate(PATH_CART);
            }
        } catch (error) {
            console.error(error);
            ErrorToast({ Message: "Something went wrong" });
        } finally {
            setIsSubmitInProgress(false);
        }
    };

    return (
        <AppModuleLayout>
            <Container>
                {tour &&
                    (width < 767 ? (
                        <Main>
                            <div className="breadcrumbs">
                                <BreadcrumbsCommon data={breadcrumbsData} />
                            </div>
                            <StyledRatio34Image img={tour.productImagePath} />
                            <Info>
                                <h1 className="titleProduct">{tour.name}</h1>
                                <h3 className="fareName">{params.fareName}</h3>
                                <div className="date">
                                    <div className="from">
                                        <DatePickerCommon
                                            label="From"
                                            id="from"
                                            value={checkedDateRange.from}
                                            onChange={(fromDate) => {
                                                if (!_.isNil(fromDate)) {
                                                    setCheckedDateRange({
                                                        ...checkedDateRange,
                                                        to: fromDate,
                                                    });
                                                }
                                            }}
                                            limitDate={limitDate.from}
                                        />
                                    </div>
                                    <div className="to">
                                        <DatePickerCommon
                                            label="To"
                                            id="to"
                                            value={checkedDateRange.to}
                                            onChange={(toDate) => {
                                                if (!_.isNil(toDate)) {
                                                    setCheckedDateRange({
                                                        ...checkedDateRange,
                                                        to: toDate,
                                                    });
                                                }
                                            }}
                                            limitDate={limitDate.to}
                                        />
                                    </div>
                                </div>
                            </Info>
                            <div className="listOption">
                                {!availabilities.length ? (
                                    !isLoading ? (
                                        <p style={{ textAlign: "center" }}>
                                            There is no availability for this fare. Please change
                                            your date or select another fare.
                                        </p>
                                    ) : (
                                        <div className="clip-loader">
                                            <ClipLoaderCommon
                                                color="#31B4B9"
                                                size={70}
                                                margin={10}
                                            />
                                        </div>
                                    )
                                ) : (
                                    availabilities.map((availability, index) => {
                                        const isChecked = selectedAvailabilities.some(
                                            (dateItem) =>
                                                dateItem.BookingDate === availability.BookingDate,
                                        );
                                        return (
                                            <FareOptionComponent
                                                key={`avail-${index}`}
                                                isChecked={isChecked}
                                                availability={availability}
                                                onCheck={handleAvailCheck}
                                            />
                                        );
                                    })
                                )}
                            </div>
                        </Main>
                    ) : (
                        <CheckAvailDesktopWrapper>
                            <ProductDetailComponent
                                breadcrumbsData={breadcrumbsData}
                                data={tour}
                                handleClickSupplier={handleClickSupplier}
                            />
                            <Main>
                                <CheckAvailTableModule
                                    //@ts-ignore
                                    ref={mainRef}
                                    availabilities={availabilities}
                                    selectedAvailabilities={selectedAvailabilities}
                                    setSelectedAvailabilities={setSelectedAvailabilities}
                                    params={params}
                                    date={checkedDateRange}
                                    changeDateFrom={(fromDate) => {
                                        setCheckedDateRange({
                                            ...checkedDateRange,
                                            from: fromDate,
                                        });
                                    }}
                                    changeDateTo={(toDate) => {
                                        setCheckedDateRange({
                                            ...checkedDateRange,
                                            to: toDate,
                                        });
                                    }}
                                    limitDate={limitDate}
                                    isLoading={isLoading}
                                    isChoseDate={isChoseDate.current}
                                />
                                {selectedAvailabilities && selectedAvailabilities.length > 0 && (
                                    <AddToCartButton
                                        ref={addToCartRef}
                                        isSubmitInProgress={isSubmitInProgres}
                                        availability={selectedAvailabilities.at(0)!}
                                        handleAdd={handleAdd}
                                    />
                                )}
                            </Main>
                        </CheckAvailDesktopWrapper>
                    ))}
                {selectedAvailabilities && selectedAvailabilities.length > 0 && isShowPopup && (
                    <>
                        <Overlay onClick={() => setIsShowPopup(false)} />
                        <BottomSheet>
                            <div className="main">
                                {selectedAvailabilities && selectedAvailabilities.length > 0 && (
                                    <AddToCartButton
                                        ref={addToCartRef}
                                        isSubmitInProgress={isSubmitInProgres}
                                        availability={selectedAvailabilities.at(0)!}
                                        handleAdd={handleAdd}
                                    />
                                )}
                            </div>
                        </BottomSheet>
                    </>
                )}
                {isModalOpen.existingQuote && (
                    <div className="overlay" onClick={closeModal}>
                        <div className="modal" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-content">
                                <button className="close-btn" onClick={closeModal}>
                                    <img src="/images/fi_x.png" alt="cross" />
                                </button>
                                <div className="modal-header">
                                    <h2>Create New Quote</h2>
                                </div>
                                <div className="modal-body">
                                    <TextField
                                        fullWidth
                                        id="standard-basic"
                                        label="Quote Reference"
                                        variant="standard"
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button className="create-btn">Create</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isModalOpen.newQuote && (
                    <div className="overlay" onClick={closeModal}>
                        <div className="modal" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-content">
                                <button className="close-btn" onClick={closeModal}>
                                    <img src="/images/fi_x.png" alt="cross" />
                                </button>
                                <div className="modal-header">
                                    <h2>Create New Quote</h2>
                                </div>
                                <div className="modal-body">
                                    <TextField
                                        fullWidth
                                        id="standard-basic"
                                        label="Quote Reference"
                                        variant="standard"
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button className="create-btn">Create</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Container>
        </AppModuleLayout>
    );
};

const Main = styled.div`
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    flex: 1;

    .listOption {
        width: 100%;
        flex: 1;
        padding: 18px 16px;

        .clip-loader {
            width: 100%;
        }
    }
    .breadcrumbs {
        margin: 20px 0 20px 20px;
    }
`;

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const CheckAvailDesktopWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Info = styled.div`
    padding: 16px 16px 21px 16px;
    background-color: #fff;

    .MuiIconButton-label {
        color: ${(props) => props.theme.colors.main};
    }

    .titleProduct {
        font-weight: 700;
        font-size: 18px;
        color: ${(props) => props.theme.colors.black};
    }

    .fareName {
        margin-top: 10px;
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 18px;
    }

    .date {
        display: flex;
        gap: 20px;
        .MuiInputLabel-animated {
            color: #000;
        }
        .MuiInputBase-input {
            color: #000;
        }
    }
`;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(9, 10, 24, 0.4);
    z-index: 9;
`;

const BottomSheet = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    animation: ${slideUp} 0.3s ease-out;
    padding: 20px 16px;
`;
