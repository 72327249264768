import { memo, useEffect, useState } from "react";
import styled from "styled-components";

import { IAccountInfo, ICountryData } from "@interfaces";
import { IHandleBlur, IHandleChange, IHandleSubmit } from "@interfaces";
import { InputCommon, SelectCommon, StyledButton } from "@components";
import { breakpoint } from "@utilities";
import { externalApi } from "@api";
import { useFormikContext } from "formik";
import moment from "moment";

interface IProps {
    values: IAccountInfo;
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    handleSubmit: IHandleSubmit;
    countriesData: ICountryData[];
    isUpdateAccount: boolean;
    isLeader?: boolean;
    isSubmitting: boolean;
}

export const AccountComponent = memo((props: IProps) => {
    const [countriesData, setCountriesData] = useState([]);
    useEffect(() => {
        (async () => {
            const res = await externalApi.getCountriesDate();
            setCountriesData(res.data);
        })();
    }, []);
    const formatDate = (date: string) => {
        return moment(date, "DD-MMM-YYYY").format("YYYY-MM-DD"); // Convert to YYYY-MM-DD
    };
    const countriesModel = countriesData.map((item: any) => ({
        id: item.countryCode,
        label: item.name,
    }));
    const { values, handleChange, handleSubmit, handleBlur, isUpdateAccount, isSubmitting } = props;
    const { setFieldValue } = useFormikContext();
    return (
        <Container onSubmit={handleSubmit}>
            <h2 className="title__account">
                {isUpdateAccount ? "Update My Account Details" : "Account Details"}
            </h2>
            <div className="Wrapper">
                <div className="col_48">
                    <InputCommon
                        name="first_name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.first_name}
                        label="First name *"
                        type="text"
                        required
                    />
                </div>
                <div className="col_48">
                    <InputCommon
                        name="last_name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        required
                        value={values.last_name}
                        label="Last name *"
                        type="text"
                    />
                </div>
            </div>

            <div className="Wrapper">
                <div className="col_48 m_100">
                    <InputCommon
                        name="date_of_birth"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.date_of_birth ? formatDate(values.date_of_birth) : ""}
                        label="Date of Birth *"
                        required
                        type="date"
                    />
                </div>
                <div className="col_48 m_100">
                    <InputCommon
                        name="post_code"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.post_code}
                        label="Post Code *"
                        required
                        type="text"
                    />
                </div>
            </div>

            <div className="Wrapper">
                <div className="col_48 m_100">
                    <InputCommon
                        name="phone_number"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.phone_number}
                        label="Phone Number *"
                        required
                        type="text"
                    />
                </div>
                <div className="col_48 m_100">
                    <SelectCommon
                        name="country_code"
                        handleChange={(value) => setFieldValue("country_code", value)}
                        handleBlur={handleBlur}
                        value={values.country_code}
                        label="Country"
                        required
                        listValueMembers={countriesModel}
                    />
                </div>
            </div>

            <StyledButton className="submit_account" disabled={isSubmitting} type="submit">
                {isUpdateAccount ? "Update Details" : "Submit"}
            </StyledButton>
            <div className="note">
                <span>
                    *To change your email address please contact{" "}
                    <a href="mailto:support@freelancetravel.com">support@freelancetravel.com</a>.
                    Please note this will also update your account login details.
                </span>
            </div>
        </Container>
    );
});

const Container = styled.form`
    .note {
        color: ${(props) => props.theme.colors.gray_3};
        font-size: 14px;
        margin-top: 1em;
        line-height: 1.25em;
        a {
            color: ${(props) => props.theme.colors.main};
        }
    }

    .Wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .col_48 {
            width: 48%;
        }
        ${breakpoint.breakOnlyMobile`
        flex-direction : column;
        .col_48 {
            width: 100%;
        }
        .col_48.m_100 {
            width: 100%;
         }
        `};
    }
    .submit_account {
        width: 40%;
        margin: auto;
        margin-top: 50px;
        ${breakpoint.breakOnlyMobile`
        width: 100%;
        margin-top: 30px;
        `};
    }
    .MuiFormControl-root {
        height: 65px;
        margin: 0 !important;
    }
    .title__account {
        font-weight: bold;
        margin-bottom: 12px !important;
    }
    .MuiFormControl-root .gFTEKm {
        margin-top: 0 !important;
    }
    label {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`;
