import styled from "styled-components";
import { memo, useEffect, useState } from "react";
import moment from "moment";
import { DatePickerCommon, ClipLoaderCommon } from "@components";
import { IDateRange, ICheckAvailDateRange, IProductAvailability } from "@interfaces";
import { breakpoint, sliceString } from "@utilities";
import { AvailBlockModule } from "@module";
import { getCalendarDays, getMonthsInRange } from "src/Utilities/cartHelper";
import { BOOKING_DATE_FORMAT } from "@constants";
import { useParams } from "react-router-dom";
import { selectTour, useAppSelector } from "@redux";
import _ from "lodash";

interface ILimitDate {
    minDate: Date | null;
    maxDate: Date | null;
}
interface IProps {
    availabilities: IProductAvailability[];
    changeDateFrom: (date: Date) => void;
    changeDateTo: (date: Date) => void;
    selectedAvailabilities: IProductAvailability[];
    setSelectedAvailabilities: (newAvailabilities: IProductAvailability[]) => void;
    limitDate: {
        from: ILimitDate;
        to: ILimitDate;
    };
    isLoading: boolean;
    isChoseDate: {
        from: boolean;
        to: boolean;
    };
    params: any;
    date: ICheckAvailDateRange;
}

interface IHeaderTableProps {
    limitDate: {
        from: ILimitDate;
        to: ILimitDate;
    };
    changeDateFrom: (date: Date) => void;
    changeDateTo: (date: Date) => void;
    isChoseDate: {
        from: boolean;
        to: boolean;
    };
    dateRange: IDateRange;
    selectedAvailabilities: IProductAvailability[];
}

// interface IDataProcess {
//     data: ICheckAvail | null;
//     date: Date;
//     day: number;
//     month: string;
// }

// const addMonth = (months: ICheckMonth[], month: ICheckMonth) => {
//     const monthIndex = months.findIndex((monthSet) => monthSet.key === month.key);
//     if (monthIndex >= 0) {
//         return months;
//     } else {
//         return [
//             ...months.map((monthSet) => {
//                 return { ...monthSet };
//             }),
//             { ...month },
//         ];
//     }
// };

const HeaderTableComponent = memo((props: IHeaderTableProps) => {
    const { dateRange, isChoseDate, limitDate, changeDateFrom, changeDateTo } = props;
    const { fareTypeId } = useParams<{ fareTypeId: string }>();
    const tourRedux = useAppSelector(selectTour);
    const matchingFare = _.find(tourRedux.tour?.faresPrices, { fareTypeId });
    const handleChangeFrom = (date: Date) => {
        changeDateFrom(date);
        isChoseDate.from = true;
    };

    const handleChangeTo = (date: Date) => {
        changeDateTo(date);
        isChoseDate.to = true;
    };

    return (
        <Header>
            <div className="fareType">
                <p className="title">Fare Type</p>
                <p className="name">{sliceString(matchingFare?.fareName || "", 10)}</p>
            </div>
            <div className="date">
                <div className="datePicker from">
                    <DatePickerCommon
                        label="From"
                        value={dateRange.from}
                        onChange={handleChangeFrom}
                        limitDate={limitDate.from}
                    />
                </div>
                <div className="datePicker to">
                    <DatePickerCommon
                        label="To"
                        value={dateRange.to}
                        onChange={handleChangeTo}
                        limitDate={limitDate.to}
                    />
                </div>
            </div>
        </Header>
    );
});

export const CheckAvailTableModule = memo((props: IProps) => {
    const {
        availabilities,
        limitDate,
        isLoading,
        isChoseDate,
        changeDateFrom,
        changeDateTo,
        date,
        selectedAvailabilities,
        setSelectedAvailabilities,
    } = props;
    //page hooks
    // const navigate = useNavigate();
    // const location = useLocation();

    //redux state
    // const { account } = useAppSelector(selectAccount);
    // const { jwt } = useAppSelector(selectAuth);

    //page state
    // const shouldRedirect = useMemo(() => _.isNil(account) || _.isNil(jwt), [account, jwt]);
    // const [checkedDate, setCheckedDate] = useState<ICheckAvail[]>([]);
    //page variable
    const isExpanded = availabilities.length > 0 ? true : false;
    const [monthsInRange, setMonthsInRange] = useState(getMonthsInRange(date.from, date.to));
    const [activeMonthIndex, setActiveMonthIndex] = useState(0);
    const [activeMonth, setActiveMonth] = useState(monthsInRange.at(activeMonthIndex)!);
    const [calenderDays, setCalenderDays] = useState(
        getCalendarDays(activeMonth.year(), activeMonth.month()),
    );

    useEffect(() => {
        const newMonthsInRange = getMonthsInRange(date.from, date.to);
        const newActiveMonth = newMonthsInRange.at(0)!;
        const newCalendaryDays = getCalendarDays(newActiveMonth.year(), newActiveMonth.month());
        setMonthsInRange(newMonthsInRange);
        setActiveMonth(newActiveMonth);
        setCalenderDays(newCalendaryDays);
    }, [date.from, date.to]);

    useEffect(() => {
        const newMonthsInRange = getMonthsInRange(date.from, date.to);
        const newActiveMonth = newMonthsInRange.at(activeMonthIndex)!;
        const newCalendaryDays = getCalendarDays(newActiveMonth.year(), newActiveMonth.month());
        setActiveMonth(newActiveMonth);
        setCalenderDays(newCalendaryDays);
    }, [activeMonthIndex]);
    //load first time
    // useEffect(() => {
    //     const forwardData: IDataProcess[] = [];
    //     const nextData: IDataProcess[] = [];
    //     let newMonthSet: ICheckMonth[] = [];
    //     const currentData = data.map((item, index) => {
    //         const date = new Date(item.BookingDate);
    //         const month = `${date.getFullYear()}_${date.getMonth()}`;
    //         //data fill before select date
    //         if (index === 0) {
    //             setNewCurrentMonth(month);
    //             //first selected date add number of days in month before
    //             const forwardDays = +date.getDate() + +moment(date).startOf("month").format("d");
    //             for (let i = forwardDays - 1; i > 0; i--) {
    //                 // i = the number of days difference between the closest Sunday and the first day in the old array
    //                 const newDate = new Date(getDiffDate(i, date));
    //                 forwardData.push({
    //                     data: null,
    //                     date: newDate, // retrieve the corresponding date
    //                     day: newDate.getDay(),
    //                     month: `${newDate.getFullYear()}_${newDate.getMonth()}`,
    //                 });
    //             }
    //         }
    //         //data fill after select date
    //         if (index === data.length - 1) {
    //             //days in month minus late selected date add number days of last week in other month
    //             const nextDays =
    //                 +moment(date).daysInMonth() -
    //                 date.getDate() +
    //                 6 -
    //                 +moment(date).endOf("month").format("d");
    //             for (let i = 1; i <= nextDays; i++) {
    //                 const newDate = new Date(getDiffDate(i, date, 1));
    //                 nextData.push({
    //                     data: null,
    //                     date: newDate, // Thêm đối số type = 1 để lấy ngày phía sau
    //                     day: newDate.getDay(),
    //                     month: `${newDate.getFullYear()}_${newDate.getMonth()}`,
    //                 });
    //             }
    //         }
    //         newMonthSet = addMonth(newMonthSet, {
    //             key: month,
    //             label: `${moment(date).format("MMMM")} ${date.getFullYear()}`,
    //         });
    //         return {
    //             data: {
    //                 ...item,
    //             },
    //             date: date,
    //             day: date.getDay(), // thứ trong tuần (0 -> 6) bắt đầu từ chủ nhật
    //             month,
    //         };
    //     });

    //     setNewMonths(newMonthSet);
    //     setProcessData([...forwardData, ...currentData, ...nextData]);
    // }, [data]);

    //load data of page
    // useEffect(() => {
    //     if (processData[0]) {
    //         let firstDayIndex = -1;
    //         let lastDayIndex = -1;
    //         const dataDate = processData.filter((item, index) => {
    //             if (firstDayIndex === -1 && item.month === newCurrentMonth) firstDayIndex = index;
    //             if (
    //                 newMonths[monthIndex + 1] &&
    //                 lastDayIndex === -1 &&
    //                 item.month === newMonths[monthIndex + 1].key
    //             )
    //                 lastDayIndex = index;

    //             return item.month === newCurrentMonth;
    //         });
    //         //forward data
    //         const forwardData = dataDate[0].day
    //             ? processData.slice(firstDayIndex - dataDate[0].day, firstDayIndex)
    //             : [];
    //         //next data
    //         const nextData = processData.slice(
    //             lastDayIndex,
    //             lastDayIndex + 6 - dataDate[dataDate.length - 1].day,
    //         );
    //         setPaginatedData([...forwardData, ...dataDate, ...nextData]);
    //     }
    // }, [data, newCurrentMonth, processData]);

    // const getDiffDate = (diff: number, startDate: Date, type = 0) => {
    //     // Hàm lấy ngày phía trước hoặc phí sau
    //     const forwardDay = new Date(startDate);
    //     forwardDay.setDate(type === 0 ? startDate.getDate() - diff : startDate.getDate() + diff);
    //     return forwardDay; // return timestamp
    // };

    const weekModel = [
        {
            id: 0,
            title: "Sun",
        },
        {
            id: 1,
            title: "Mon",
        },
        {
            id: 2,
            title: "Tue",
        },
        {
            id: 3,
            title: "Wed",
        },
        {
            id: 4,
            title: "Thu",
        },
        {
            id: 5,
            title: "Fri",
        },
        {
            id: 6,
            title: "Sat",
        },
    ];

    const handleNextPage = () => {
        if (activeMonthIndex < monthsInRange.length - 1) {
            setActiveMonthIndex(activeMonthIndex + 1);
        }
    };

    const handlePrevPage = () => {
        if (activeMonthIndex > 0) {
            setActiveMonthIndex(activeMonthIndex - 1);
        }
    };

    return (
        <Container isExpanded={isExpanded}>
            <HeaderTableComponent
                limitDate={limitDate}
                isChoseDate={isChoseDate}
                dateRange={date}
                changeDateFrom={changeDateFrom}
                changeDateTo={changeDateTo}
                selectedAvailabilities={selectedAvailabilities}
            />
            <Main isExpanded={isExpanded} dataPagination={availabilities.length > 0}>
                {availabilities.length > 0 ? (
                    <>
                        <div className="listNavBtn">
                            <button
                                className="navigateBtn prevBtn"
                                onClick={handlePrevPage}
                                disabled={monthsInRange.length === 0 || activeMonthIndex === 0}
                            >
                                <img src="/images/fi_chevron-left.png" alt="" />
                            </button>
                            <div className="month">{activeMonth.format("MMMM")}</div>
                            <button
                                className="navigateBtn nextBtn"
                                onClick={handleNextPage}
                                disabled={
                                    monthsInRange.length === 0 ||
                                    activeMonthIndex === monthsInRange.length - 1
                                }
                            >
                                <img src="/images/fi_chevron-left.png" alt="" />
                            </button>
                        </div>
                        <div className="header _row">
                            {weekModel.map((item) => {
                                return (
                                    <div className="headerItem col_1_7" key={item.id}>
                                        {item.title}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="listBlock _row">
                            {calenderDays.map((calendarDay) => {
                                const availIndex = availabilities.findIndex((avail) => {
                                    return moment(avail.BookingDate, BOOKING_DATE_FORMAT).isSame(
                                        calendarDay,
                                    );
                                });
                                const availData =
                                    availIndex < 0 ? null : availabilities[availIndex];
                                const isChecked =
                                    selectedAvailabilities.filter(
                                        (avail) => avail.availIndex === availIndex,
                                    ).length > 0;
                                const date = calendarDay.toDate();
                                return (
                                    <div
                                        className="col_1_7"
                                        key={date.getDate() + "_" + date.getMonth()}
                                    >
                                        <AvailBlockModule
                                            date={calendarDay.toDate()}
                                            availData={availData}
                                            isChecked={isChecked}
                                            onCheck={(avail) => {
                                                if (isChecked) {
                                                    setSelectedAvailabilities([]);
                                                } else {
                                                    setSelectedAvailabilities([
                                                        ...selectedAvailabilities,
                                                        avail,
                                                    ]);
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </>
                ) : isLoading ? (
                    <ClipLoaderCommon color="#31B4B9" size={70} margin={0} />
                ) : (
                    <p>Please choose different or shorter date range (max 14 days)</p>
                )}
            </Main>
        </Container>
    );
});

const Container = styled.div<{ isExpanded: boolean }>`
    margin: 0 auto;
    width: ${(p) => p.theme.widths.wide_700};
    /* min-height: 774px; */
    min-height: ${(p) => (p.isExpanded ? "510px" : "unset !important")};
    display: flex;
    flex-direction: column;

    ${breakpoint.breakIpadPro`
        width: ${(p) => p.theme.widths.wide_900};
        min-height: 584px;
    `}

    ${breakpoint.breakLarge`
        width: ${(p) => p.theme.widths.wide_1110};
        min-height: 774px;
    `}
`;

const Header = styled.div`
    color: #fff;
    background-color: ${(p) => p.theme.colors.black};
    display: flex;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    justify-content: space-between;
    align-items: center;
    padding: 14px;

    ${breakpoint.breakLarge`
        padding: 28px 26px 21px 26px;
    `}

    .col_2 {
        flex: 2;
    }

    .col_3 {
        flex: 3;
    }

    .fareType {
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;

        ${breakpoint.breakIpadPro`
            font-size: 15px;
        `}

        ${breakpoint.breakLarge`
            font-size: 16px;
            line-height: 26px;
        `}

        .name {
            font-weight: 400;
            margin-top: 10px;

            ${breakpoint.breakIpadPro`
                margin-top: 17px;
            `}

            ${breakpoint.breakLarge`
                margin-top: 15px;
            `}
        }
    }

    .date {
        display: flex;

        .datePicker {
            margin: 0 10px;

            .MuiInputLabel-animated {
                color: #fff;
            }

            .MuiFormControl-marginNormal {
                margin: 0;
            }

            .MuiInputBase-root {
                background-color: ${(p) => p.theme.colors.gray_1} !important;
                height: 36px;
                width: 135px;

                ${breakpoint.breakIpadPro`
                    width: 160px;
                `}

                ${breakpoint.breakLarge`
                    width: 214px;
                    height: 54px;
                `}
            }

            .MuiInputBase-input {
                color: #fff;
            }
        }
    }

    .checkAvailBtn {
        display: flex;
        align-items: flex-end;
        margin-left: 5px;

        ${breakpoint.breakLarge`
            margin-left: 50px;
        `}
    }
`;

const Main = styled.div<{ isExpanded: boolean; dataPagination?: boolean }>`
    min-height: 60vh;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 13px 9px 0 9px;
    flex: ${(p) => (p.isExpanded ? "1" : "unset")};
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    ${breakpoint.breakTablet`
        padding: 35px 30px 48px 30px;
    `}

    @media (min-width: 768px) {
        padding-bottom: ${(p) => (p.dataPagination ? "48px" : "100px")};
    }

    ._row {
        display: flex;
        flex-wrap: wrap;
    }

    .col_1_7 {
        width: 14.2857%;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
    }

    .header {
        width: 100%;

        .headerItem {
            padding-right: 9px;
            text-align: right;
            color: ${(p) => p.theme.colors.gray_3};
            margin-bottom: 5px;
            font-size: 12px;

            ${breakpoint.breakLarge`
                margin-bottom: 9px;
                font-size: 16px;
            `}
        }
    }

    .listBlock {
        width: 100%;
        flex: 1;
    }

    .listBtn {
        display: flex;
        justify-content: flex-end;
        /* padding-bottom: 48px; */
    }

    .listNavBtn {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 10px;
    }

    .month {
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${(p) => p.theme.colors.gray_3};
        color: ${(p) => p.theme.colors.main};
        font-weight: bold;
    }

    .navigateBtn {
        background-color: ${(p) => p.theme.colors.gray_2};
        width: 30px;
        height: 30px;
        padding-top: 4px;
        border-radius: 0px;
        ${breakpoint.breakIpadPro`
            width: 40px;
            height: 40px;
        `}

        &.nextBtn {
            img {
                transform: rotate(180deg);
            }
        }

        &:disabled {
            background-color: ${(p) => p.theme.colors.gray_3};
        }
    }
`;
