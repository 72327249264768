import styled from "styled-components";

export const StyledVersion = styled.div`
    background: transparent;
    text-align: center;
    padding-bottom: 2em;
    font-size: 12px;
    margin-top : 50px;
    color: ${props => props.theme.colors.black};
`;
