export const IconQuote = () => (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.5 20.1016C17.7761 20.1016 18 19.8777 18 19.6016C18 19.3254 17.7761 19.1016 17.5 19.1016C17.2239 19.1016 17 19.3254 17 19.6016C17 19.8777 17.2239 20.1016 17.5 20.1016Z"
            fill="#898D8F"
            stroke="#898D8F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M9.5 20.1016C9.77614 20.1016 10 19.8777 10 19.6016C10 19.3254 9.77614 19.1016 9.5 19.1016C9.22386 19.1016 9 19.3254 9 19.6016C9 19.8777 9.22386 20.1016 9.5 20.1016Z"
            fill="#898D8F"
            stroke="#898D8F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M3 4.10156H6.09091L8.16182 13.9207C8.23248 14.2583 8.42602 14.5616 8.70856 14.7774C8.99109 14.9932 9.34463 15.1079 9.70727 15.1013H17.2182C17.5808 15.1079 17.9343 14.9932 18.2169 14.7774C18.4994 14.5616 18.693 14.2583 18.7636 13.9207L20 7.76814"
            stroke="#898D8F"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13 4.10156V11.1016"
            stroke="#898D8F"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M15.5 9.10156L13 11.1016L10.5 9.10156"
            stroke="#898D8F"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
