import styled from "styled-components";
import {
    ICountryData,
    IHandleBlur,
    IHandleChange,
    IHandleSubmit,
    ISetCustomerData,
} from "@interfaces";
import { DatePickerCommon, InputCommon, SelectCommon } from "@components";
import { breakpoint } from "@utilities";
import { useField, useFormikContext } from "formik";

interface IProps {
    name: string;
    values: ISetCustomerData;
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    handleSubmit: IHandleSubmit;
    userId?: number | string;
    dateValue: Date | null | undefined;
    countriesData: ICountryData[];
    handleChangeDate: (date: Date | null, value?: string | null | undefined) => void;
    isLeader?: boolean;
    isShowErrorDate: boolean;
}

export const CustomerDetailFormComponent = (props: IProps) => {
    const {
        name, // Use dynamic name for each customer
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        userId,
        dateValue,
        countriesData,
        handleChangeDate,
    } = props;

    const countriesModel = countriesData.map((item) => ({
        id: item.countryCode,
        label: item.name,
    }));
    const { setFieldValue } = useFormikContext();
    const [field] = useField(`${name}.countryCode`);
    const [dateOfBirthField, dateOfBirthMeta] = useField(`${name}.dateOfBirth`);
    return (
        <Container onSubmit={handleSubmit}>
            <div className="nameWrapper">
                <div className="firstName">
                    <InputCommon
                        name={`${name}.firstName`}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.firstName}
                        label="First name *"
                        type="text"
                        required
                        userId={userId}
                    />
                </div>
                <div className="lastName">
                    <InputCommon
                        name={`${name}.lastName`} // Same here
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        required
                        value={values.lastName}
                        label="Last name *"
                        type="text"
                        userId={userId}
                    />
                </div>
            </div>
            <DatePickerCommon
                {...dateOfBirthField}
                {...dateOfBirthMeta}
                label="Date of birth *"
                value={dateValue}
                onChange={(date: Date | null) => handleChangeDate(date)}
                limitDate={{ maxDate: new Date(), minDate: new Date("1/1/1000") }}
                placeholder="DD/MM/YYYY"
            />
            <InputCommon
                className="phone"
                name={`${name}.phoneNumber`}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.phoneNumber}
                required
                label="Phone *"
                type="text"
                userId={userId}
            />
            <InputCommon
                name={`${name}.email`}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.email}
                label="Email *"
                required
                type="text"
                userId={userId}
            />
            <InputCommon
                name={`${name}.postalCode`}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.postalCode}
                label="Post code *"
                required
                type="text"
                userId={userId}
            />

            <SelectCommon
                name={`${name}.countryCode`}
                handleChange={(value) => setFieldValue(`${name}.countryCode`, value)}
                handleBlur={handleBlur}
                value={field.value}
                label="Country"
                required
                userId={userId}
                listValueMembers={countriesModel}
            />
        </Container>
    );
};

const Container = styled.form`
    padding: 0 28px;
    .nameWrapper {
        display: flex;
        margin-bottom: 20px;

        ${breakpoint.breakOnlyMobile`
            display : flex;
            flex-direction : column;
        `}
        .firstName,
        .lastName {
            flex: 1;
        }

        .firstName {
            margin-right: 16px;
            ${breakpoint.breakOnlyMobile`
                margin-right: 0px;
            `}
        }
    }

    .removeNRefresh {
        display: flex;
        justify-content: space-between;
        margin-top: 19px;

        span {
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
        }

        .btnRemove,
        .btnRefresh {
            display: flex;
            align-items: center;

            span {
                margin-left: 13px;
            }
        }

        .btnRemove {
            color: ${(p) => p.theme.colors.red};
        }

        .btnRefresh {
            color: ${(props) => props.theme.colors.black};
            cursor: pointer;
        }
    }

    .saveBtn {
        ${breakpoint.breakIpadPro`
            max-width: 213px;
            margin: 0 auto;
        `}
    }
    .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
        width: 100% !important;
    }
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
        background-color: transparent !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
        color: rgba(0, 0, 0, 1.87) !important;
    }
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiFormLabel-filled {
        color: #4f4f4f;
        line-height: 160%;
        font-weight: 100;
        font-size: 16px;
        font-family: DM Sans !important;
    }
    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
        font-size: 14px;
    }
`;
