import styled from "styled-components";
import { Formik, FormikHelpers } from "formik";
import { AccountComponent, BreadcrumbsCommon, MenuAccountComponent } from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_MY_ACCOUNT, PATH_HOME } from "@routes";
import { breakpoint } from "@utilities";
import { accountSchema } from "@validations";
import { IAccountInfo, IAccountResponse } from "@interfaces";
import { selectAccount, updateAccountDetail, useAppDispatch, useAppSelector } from "@redux";
import { useEffect, useState } from "react";
import { externalApi } from "@api";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { SuccessToast } from "src/Components/toasts/SuccessToast";

export const MyAccountSetting = () => {
    //page redux
    const { account } = useAppSelector(selectAccount);

    // Extract only needed values for IAccountInfo
    const accountInfo: IAccountInfo = {
        first_name: account?.first_name ?? "",
        last_name: account?.last_name ?? "",
        date_of_birth: account?.date_of_birth ?? "",
        email: account?.email ?? "",
        phone_number: account?.phone_number ?? "",
        post_code: account?.post_code ?? "",
        country_code: account?.country_code ?? "",
    };
    const [countriesData, setCountriesData] = useState([]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            const res = await externalApi.getCountriesDate();
            setCountriesData(res.data);
        })();
    }, []);

    const breadcrumbsData = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Update My Details",
            link: PATH_MY_ACCOUNT,
        },
    ];

    const handleSubmit = async (
        value: IAccountInfo,
        { setSubmitting }: FormikHelpers<IAccountInfo>,
    ) => {
        try {
            const formattedValue = {
                ...value,
                date_of_birth: value.date_of_birth
                    ? moment(value.date_of_birth).format("DD-MMM-YYYY") // Format date to 'DD-MMM-YYYY'
                    : "",
            };

            const res = (await dispatch(updateAccountDetail(formattedValue))) as {
                payload: IAccountResponse;
            };
            if (res && res.payload.code === 200) {
                SuccessToast({ Message: "Account Details Updated" });
                navigate(-1);
            }
        } catch (error: any) {
            const message = error?.response?.data?.message || "Something went wrong!";
            console.error(message);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <AppModuleLayout>
            <StyledMyAccountPage>
                <div className="wrapper">
                    <div className="breadCrumbs">
                        <BreadcrumbsCommon data={breadcrumbsData} />
                    </div>
                    <div className="menu">
                        <MenuAccountComponent />
                    </div>
                    <div className="my_account">
                        <p className="heading1">Update My Details</p>
                        <FormContainer>
                            <Formik
                                initialValues={accountInfo}
                                validationSchema={accountSchema}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => {
                                    return (
                                        <AccountComponent
                                            values={values}
                                            countriesData={countriesData}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            handleSubmit={handleSubmit}
                                            isUpdateAccount={true}
                                            isSubmitting={isSubmitting}
                                        />
                                    );
                                }}
                            </Formik>
                        </FormContainer>
                    </div>
                </div>
            </StyledMyAccountPage>
        </AppModuleLayout>
    );
};

const StyledMyAccountPage = styled.div`
    background-color: #f2f2f2;
    min-height: calc(100vh - 95px);
    ${breakpoint.breakOnlyMobile`
        padding:24px 20px;
    `}

    .wrapper {
        display: block;
        margin: 0 auto;
        position: relative;

        ${breakpoint.breakTablet`
            display: flex;
            width: ${(p) => p.theme.widths.wide_700};
            margin-top: 81px;
            padding-bottom: 20px;
        `}

        ${breakpoint.breakIpadPro`
            width: ${(p) => p.theme.widths.wide_900};
        `}

        ${breakpoint.breakLarge`
            width: ${(p) => p.theme.widths.wide_1110};
        `}

        .breadCrumbs {
            position: absolute;
            bottom: calc(100% + 30px);

            ${breakpoint.breakOnlyMobile`
                position: relative;
            `}
        }

        .menu {
            display: none;

            ${breakpoint.breakTablet`
                display: block;
            `}
        }
    }

    .my_account {
        padding: 24px 16px;
        background-color: #f2f2f2;
        position: relative;
        top: 11px;
        margin-bottom: 11px;

        ${breakpoint.breakTablet`
            flex: 1;
            padding: 0;
       `}

        ${breakpoint.breakOnlyMobile`
           background-color:#ffffff;
           border-radius: 4px;
           padding-top: 15px;
       `}

        .heading1 {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
            margin-bottom: 30px;
            margin-top: -10px;
            display: none;

            ${breakpoint.breakTablet`
                display: block;
            `}
        }
    }
`;

const FormContainer = styled.div`
    background-color: #fff;
    padding: 30px 30px 50px 30px;
    border-radius: 4px;

    ${breakpoint.breakOnlyMobile`
        padding: 0px;
    `}
`;
