import { memo } from "react";
import styled from "styled-components";

import { PATH_SEARCH_RESULT } from "@routes";
import { breakpoint } from "@utilities";
import { ListOptionFilterComponent } from "@components";
import { IOption } from "@interfaces";

interface ISearchFilterProps {
    tagName: string;
    icon: string;
    isShowListOption: boolean;
    categoryData: IOption[];
    onClickTag: () => void;
    onCloseOptions: () => void;
    onClickItem: (categoryItem: IOption) => void;
    selectedCategoryName: string | undefined;
    name: string;
}

export const SearchFilterItemComponent = memo((props: ISearchFilterProps) => {
    const {
        tagName,
        icon,
        isShowListOption,
        categoryData,
        onClickTag,
        onCloseOptions,
        onClickItem,
        selectedCategoryName,
        name,
    } = props;
    const isSearchResultPage = window.location.pathname.includes(PATH_SEARCH_RESULT);

    return (
        <>
            <Container
                onClick={onClickTag}
                isShowListOption={isShowListOption}
                className={`${isSearchResultPage ? "searchResult" : ""}`}
            >
                <span className="icon">
                    <img src={icon} alt="" />
                </span>
                <span className="tagName">
                    {selectedCategoryName ? selectedCategoryName : tagName}
                </span>
                <span className="iconArrow mobile">
                    <img src="/images/fi_chevron-down-dark.png" alt="" />
                </span>
            </Container>
            {isShowListOption && (
                <ListOptionFilterComponent
                    data={categoryData}
                    onCloseOptions={onCloseOptions}
                    onClickItem={onClickItem}
                    name={name}
                />
            )}
        </>
    );
});

interface ISearchInputProps {
    value: string | number | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    icon: string;
    placeholder: string | undefined;
    onkeyup?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const SearchInputItem = (props: ISearchInputProps) => {
    const { value, onChange, icon, placeholder, onkeyup } = props;
    const isSearchResultPage = window.location.pathname.includes(PATH_SEARCH_RESULT);
    return (
        <Container className={`${isSearchResultPage ? "searchResult" : ""}`}>
            <span className="icon">
                <img src={icon} alt="" />
            </span>
            <input
                type="text"
                className="tagName"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onKeyUp={onkeyup}
            />
        </Container>
    );
};

const Container = styled.div<{
    isShowListOption?: boolean;
}>`
    display: flex;
    padding: 13px;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 100%;
    border-radius: 4px;
    input {
        font-size: 14px;
    }

    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        .iconArrow {
            position: absolute;
            top: 50%;
            right: 0;
            transform: ${(p) =>
                p.isShowListOption
                    ? "translateY(-50%) rotate(-180deg)"
                    : "translateY(-50%) rotate(0)"};
            transition: 300ms;
        }
    }

    &.searchResult {
        /* padding: 16px 14px 16px 31px; */
        margin-top: 16px;
        display: flex;
        align-items: center !important;

        ${breakpoint.breakOnlyMobile`
            margin-top: 0px;
        `}

        ${breakpoint.breakTablet`
            border: none;
        `}
    }

    ${breakpoint.breakTablet`
        &.searchResult {
            background-color: #F2F2F2;
        }
    `}

    ${breakpoint.breakTablet`
        border: 1px solid ${(p) => p.theme.colors.gray_5};
    `}

    ${breakpoint.breakIpadPro`
        padding: 28px 14px 28px 31px;
        border: 1px solid ${(p) => p.theme.colors.gray_5};

        &.searchResult {
            padding: 16px 14px 16px 31px;
            border: none;
            margin-top: 16px;

            .tagName {
                max-width: 187px !important;
                text-overflow: ellipsis;
            }
        }
    `}

    .icon {
        img {
            width: 16px;

            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                width: auto;
            }

            ${breakpoint.breakIpadPro`
                width: auto;
            `}
        }
    }

    .tagName {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        padding-left: 6px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        ${breakpoint.breakSmall` 
            width:85%;
        `}
        ${breakpoint.breakMobile` 
            width:100%;
        `}
        ${breakpoint.breakTablet`
            font-size: 10px;
            padding-left: 10px;
        `}

        ${breakpoint.breakIpadPro`
            font-size: 16px;
            padding-left: 16px;
        `}
    }
`;
