import { StyledBorderButton } from "@components";
import { breakpoint, theme } from "@utilities";
import styled from "styled-components";

interface BookNowButtonProps {
    handleClick: () => void;
    disabled?: boolean;
}

const BookNowButton = ({ handleClick, disabled = false }: BookNowButtonProps) => {
    return (
        <BorderButton
            onClick={handleClick}
            textColor={theme.colors.main}
            color="transparent"
            borderColor={theme.colors.main}
            disabled={disabled}
            borderRadius="4px"
        >
            Book Now
        </BorderButton>
    );
};

export const BorderButton = styled(StyledBorderButton)`
    margin-top: 0px;
    width: 250px;
    height: 54px;
    ${breakpoint.breakOnlyMobile`
        margin-top: 0px;
        width : 100%;
    `};
`;

export default BookNowButton;
