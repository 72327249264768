import styled from "styled-components";
import { memo } from "react";

import { IProductAvailability } from "@interfaces";
import { CheckBoxCommon } from "@components";
import { breakpoint } from "@utilities";
import _ from "lodash";

interface IProps {
    availData?: IProductAvailability | null;
    date: Date;
    isChecked?: boolean;
    onCheck: () => void;
}

interface IPaddingLayOutProps {
    isChecked?: boolean;
    status?: boolean;
}

export const AvailBlockComponent = memo((props: IProps) => {
    const { availData, date, isChecked, onCheck } = props;
    const dd = date.getDate();
    const mm = date.toLocaleString("default", { month: "short" });

    return (
        <PaddingLayOut>
            {!_.isNil(availData) ? (
                <ContainerFullFill isChecked={isChecked} status>
                    {availData.NumAvailable > 0 ? (
                        <span className="checkBox" onClick={onCheck}>
                            <CheckBoxCommon isChecked={isChecked} />
                        </span>
                    ) : (
                        <></>
                    )}
                    <span className="dd">{dd === 1 ? `${mm} ${dd}` : dd}</span>
                    {availData.NumAvailable > 0 ? (
                        <div className="listPrice">
                            <p className="priceItem bgr">
                                <span className="fieldName">Avail</span>
                                <span
                                    className={`value ${
                                        !availData.NumAvailable ? "limited" : ""
                                    }`}
                                >
                                    {availData.NumAvailable}
                                </span>
                            </p>
                            <p className="priceItem">
                                <span className="fieldName">RRP</span>
                                <span className="value">
                                    ${availData.RRP?.toFixed(2)}
                                </span>
                            </p>
                            
                            {availData.levy && (
                                <div className="priceItem">
                                    <span className="fieldName">Levy</span>
                                    <span className="value">${Number(availData.levy).toFixed(2)}</span>
                                </div>
                            )}

                            
                        </div>
                    ) : (
                        <div className="dataNull">
                            <img src="/images/dataNull.png" alt="" />
                        </div>
                    )}
                    <p className="line"></p>
                </ContainerFullFill>
            ) : (
                <Container>
                    <span className="dd">{dd === 1 ? `${mm} ${dd}` : dd}</span>
                </Container>
            )}
        </PaddingLayOut>
    );
});

const PaddingLayOut = styled.div`
    padding-top: 100%;
    position: relative;
`;

const ContainerFullFill = styled.label<IPaddingLayOutProps>`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid ${(p) => p.theme.colors.gray_5};
    box-shadow: ${(p) => (p.isChecked ? "0px 20px 29px rgba(0, 0, 0, 0.15)" : "unset")};
    transition: 300ms;
    cursor: pointer;

    .dd {
        position: absolute;
        top: 5px;
        right: 5px;
        /* font-size: 16px; */
        font-size: 10px;
        font-weight: 700;
        line-height: 21px;
        color: ${(p) => p.theme.colors.main};

        ${breakpoint.breakIpadPro`
            font-size: 14px;
        `}

        ${breakpoint.breakLarge`
            top: 10px;
            right: 10px;
            font-size: 16px;
        `}
    }

    .checkBox {
        position: absolute;
        top: 0;
        left: 0;
    }

    .listPrice {
        height: 100%;
        padding-top: 21px;
        padding-right: 2px;
        padding-left: 2px;
        ${(p) => {
            if (p.status) {
                return {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent:"space-evenly",
                };
            }
        }};
        ${breakpoint.breakIpadPro`
            padding-top: 28px;
        `}

        ${breakpoint.breakLarge`
            padding-top: 32px;
            padding-right: 9px;
            padding-left: 9px;
        `}

        .priceItem {
            display: flex;
            font-size: 10px;
            font-weight: 700;
            line-height: 16px;

            ${breakpoint.breakIpadPro`
                line-height: 21px;
                font-size: 13px;
            `}

            ${breakpoint.breakLarge`
                line-height: 22px;
                font-size: 14px;
            `}

            &.bgr {
                background-color: #f8f8f8;
            }
        }
    }

    .priceItem {
        .fieldName {
            flex: 72;
            padding-left: 7px;
        }

        .value {
            flex: 59;
            font-weight: 400;

            &.limited {
                color: ${(p) => p.theme.colors.red};
            }

            &.netPrice {
                color: ${(p) => p.theme.colors.main};
            }
        }
    }

    .line {
        height: 4px;
        width: calc(100% + 2px);
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        background: ${(p) => p.theme.colors.main};
    }

    .dataNull {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
        }
    }
`;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid ${(p) => p.theme.colors.gray_5};

    .dd {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 10px;
        font-weight: 400;
        line-height: 21px;
        color: ${(p) => p.theme.colors.black};

        ${breakpoint.breakIpadPro`
            font-size: 14px;
        `}

        ${breakpoint.breakLarge`
            font-size: 16px;
            top: 9px;
            right: 9px;
        `}
    }
`;
