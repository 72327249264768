import qs from "query-string";
import {
    ISearch,
    IOptionSearch,
    IFareAvail,
    ITourFareBookingTime,
    IAvailabilityByFareTypeID,
    ICheckAvail,
    IAvailabilityDetailsToConfirm,
} from "@interfaces";
import { diffDate } from "@utilities";
import ftApiClient from "./ftApiClient";
import wtApiClient from "./wtApiClient";
import moment from "moment";
import { CHECK_AVAILABILITY_START_DATE_FORMAT } from "@constants";
import _ from "lodash";

const availabilityDetailsCache = new Map<string, Promise<IAvailabilityDetailsToConfirm>>();

export const toursApi = {
    sharedToken: () => {
        const url = "/agent/default/token/";
        return ftApiClient.get(url);
    },
    search: (searches: ISearch, option?: IOptionSearch) => {
        let queryString = qs.stringify(searches) ? qs.stringify(searches) : "all=\\s";
        if (searches.day !== undefined && _.keys(searches).length === 1) {
            queryString = qs.stringify(searches) + "&all=\\s";
        } else if (searches.sortDirection && _.keys(searches).length === 2) {
            // nếu chỉ có sortDirection
            queryString = qs.stringify(searches) + "&all=\\s";
        }
        const optionQuery = option ? "&" + qs.stringify(option) : "";
        const url = "/products?" + queryString + optionQuery;
        return wtApiClient.get(url);
    },
    getById: (productId: number | string) => {
        const url = "/product/" + productId;
        return wtApiClient.get(url);
    },
    getMediasById: (productId: number | string) => {
        const url = "/media/images/product/" + productId;
        return wtApiClient.get(url);
    },
    getBookingDetail: (productPriceId: number | string) => {
        const url = "/bookingdetails/" + productPriceId;
        return wtApiClient.get<ITourFareBookingTime>(url);
    },
    checkAvail: (params: IFareAvail) => {
        const startDate = new Date(params.from || new Date()).getDate();
        const startMonth = new Date(params.from || new Date()).toLocaleString("en-us", {
            month: "short",
        });
        const startYear = new Date(params.from || new Date()).getFullYear();
        const dateCount = diffDate(params.from || new Date(), params.to || new Date());

        const url = `/checkavailabilityrange/${params.productPricesDetailsId}/${
            params.bookingTime
        }/${startDate}-${startMonth}-${startYear}/${dateCount + 1}`;
        return wtApiClient.get(url);
    },
    checkAvailRange: (ppdid: string, timeId: string, startDate: Date, days: number) => {
        const url = `/checkavailabilityrange/${ppdid}/${timeId}/${moment(startDate).format(CHECK_AVAILABILITY_START_DATE_FORMAT)}/${days}`;
        return wtApiClient.get<ICheckAvail[]>(url);
    },
    checkAvailByProductAndRange: (
        productId: string,
        fareTypeId: string,
        startDate: Date,
        endDate: Date,
    ) => {
        const formattedStartDate = moment(startDate).format(CHECK_AVAILABILITY_START_DATE_FORMAT);
        const formattedEndDate = moment(endDate).format(CHECK_AVAILABILITY_START_DATE_FORMAT);
        const url = `/checkAvailabilityByProductAndRange/${productId}/${fareTypeId}/${formattedStartDate}/${formattedEndDate}`;
        return wtApiClient.post<IAvailabilityByFareTypeID[]>(url);
    },
    getDetailsToConfirmAvailability: (ppid: string, date: Date, timeId: string) => {
        const url = `/bookingdetails/detailsToConfirmAvailability/${ppid}/${moment(date).format(CHECK_AVAILABILITY_START_DATE_FORMAT)}/${timeId}`;
        if (availabilityDetailsCache.has(url)) {
            return availabilityDetailsCache.get(url)!;
        }
        const promise = wtApiClient
            .get<IAvailabilityDetailsToConfirm>(url)
            .then((response) => response.data);
        availabilityDetailsCache.set(url, promise);
        return promise;
    },
};
