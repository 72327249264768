import styled from "styled-components";
import { useState} from "react";
import { useDispatch } from "react-redux";

import { ClipLoaderCommon, ConfirmComponent, ProcessOrderHeader } from "@components";
import { BookNowStepLabels, CartStepLabels, } from "@constants";
import { CartModule, CheckoutModule, OrderSuccessModule, PaymentModule } from "@module";
import {
    useAppSelector,
    AppDispatch,
    fetchCart,
    fetchCustomer,
    selectBookNow,
    setBookNowItemData,
    setBookNowCustomerData,
    clearBookNowItem,
} from "@redux";
import { AppModuleLayout } from "@layouts";
import { breakpoint } from "@utilities";
import { NavLink } from "react-router-dom";
import { BookNowBreadcrumbs } from "@routes";
import { IPayNowResponseData } from "@interfaces";
import { cartApi } from "@api";
import _ from "lodash";
import { convertCustomerDataToInfo } from "src/Utilities/cartHelper";
import { LoadingStage } from "src/Interfaces/shared";

export const BookNow = () => {
    //page hook
    const dispatch = useDispatch<AppDispatch>();
    const { item, directPurchaseItemData, customers, loadingStage } = useAppSelector(selectBookNow);
    //page state
    const [activeStepIndex, setActiveStep] = useState(0);
    const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

    const handleOrderCreate = async (paymentType: string) => {
        const purchaseDirectResponse = await cartApi.purchaseDirect({
            ...directPurchaseItemData!,
            redeemers: customers,
        });
        if (purchaseDirectResponse.status !== 200) {
            console.error(purchaseDirectResponse);
            throw new Error();
        }
        if (paymentType === 'pay-now') {
            const quoteUrl = (
                purchaseDirectResponse.data.data as IPayNowResponseData
            ).payNow.redirectUrl.replaceAll("\\", "");
            window.location.href = quoteUrl;
        } else {
            // go to final step
            setActiveStep(BookNowStepLabels.length - 1);
        }
    };
    const onOrderCreateSuccess = () => {
        dispatch(fetchCart());
        dispatch(fetchCustomer());
    };

    const handleHeaderBack = () => {
        if (activeStepIndex > 0) {
            setActiveStep(activeStepIndex - 1);
            return;
        }
        window.history.back();
    };

    const handleDeletePopUp = () => {
        setIsShowPopup(!isShowPopup);
    };

    const handleDeleteProduct = (shouldDelete: boolean) => {
        if (shouldDelete) dispatch(clearBookNowItem());
        handleDeletePopUp();
    };

    const renderComponent = () => {
        const activeStep = CartStepLabels[activeStepIndex];
        switch(activeStep) {
            case 'Order':
                return <OrderSuccessModule />;
            case 'Payment':
                return <PaymentModule
                    items={[item!]}
                    handleOrderCreate={handleOrderCreate}
                    onOrderCreateSuccess={onOrderCreateSuccess}
                />;
            case 'Checkout':
                return <CheckoutModule
                    items={[item!]}
                    customers={customers.map(c => convertCustomerDataToInfo(c))}
                    handleCustomersSave={(customers) => {
                        dispatch(setBookNowCustomerData(customers));
                        return Promise.resolve();
                    }}
                    handleSwitchToPayment={() => {
                        setActiveStep(_.findIndex(CartStepLabels, label => label === 'Payment'));
                    }}
                />;
            default:
                return <CartModule
                    items={[item!]}
                    moveTo={setActiveStep}
                    handleDeletePopUp={handleDeletePopUp}
                    handleItemsSave={(items) => {
                        dispatch(setBookNowItemData(_.first(items)!));
                        return Promise.resolve();
                    }}
                    handleSwitchToCheckout={() => {
                        setActiveStep(_.findIndex(CartStepLabels, label => label === 'Checkout'));
                    }}
                />;
        }
    };

    return (
        <AppModuleLayout onBack={handleHeaderBack}>
            <ProcessOrderHeader
                stepLabels={BookNowStepLabels}
                activeStepIndex={activeStepIndex}
                breadcrumbs={BookNowBreadcrumbs}
                changeStep={setActiveStep}
            />
            <Main>
                {loadingStage === LoadingStage.IS_INITIALIZING ? (
                    <Container className="wrapper">
                        <Content>
                            <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
                        </Content>
                    </Container>
                ) : _.isNil(item) ? (
                    <Container className="wrapper">
                        <Content>
                            <img
                                width={65}
                                height={65}
                                src="/images/cart/emptyCart.svg"
                                alt="emptyCartLogo"
                            />
                            <p>You have not selected any item to book now</p>
                            <NavLink to="/">
                                <Button>Continue Browsing</Button>
                            </NavLink>
                        </Content>
                    </Container>
                ) : renderComponent()}
                <ConfirmComponent
                    isShowPopup={isShowPopup}
                    onClickOverlay={handleDeletePopUp}
                    onClickBtnYes={() => handleDeleteProduct(true)}
                    onClickBtnNo={() => handleDeleteProduct(false)}
                >
                    Are you sure you want cancel this order?
                </ConfirmComponent>
            </Main>
        </AppModuleLayout>
    );
};

const Main = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    
    ${breakpoint.breakTablet`
        padding-bottom: 30px;
    `}

    .clipLoader {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.2);

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .loader {
            z-index: 1;
        }
    }
`;

const Container = styled.div`
    background-color: white;
    display: flex;
    flex: 1;
    ${breakpoint.breakMobile`
        width: ${(p) => p.theme.widths.wide_700}
    `}

    ${breakpoint.breakTablet`
        width: ${(p) => p.theme.widths.wide_700};
    `}

    ${breakpoint.breakIpadPro`
        width: ${(p) => p.theme.widths.wide_900};
    `}

    ${breakpoint.breakLarge`
        width: ${(p) => p.theme.widths.wide_1110};
    `}
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 14px;
`;

const Button = styled.button`
    border: 1px solid #31b4b9;
    padding: 12px 24px;
    border-radius: 4px;
    color: #31b4b9;
    background: none;
    cursor: pointer;

    &:hover {
        background-color: #e0f7f8;
    }
`;
