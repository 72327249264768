import { breakpoint } from "@utilities";
import styled from "styled-components";

export const StyledNavigator = styled.div`
    position: sticky;
    display: flex;
    align-items: center;
    padding: 25px 18px 25px 18px;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
    z-index: 3;
    top: 0;

    .backBtn {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-weight: 700;
            font-size: 16px;
            margin-left: 5px;
        }
    }

    .menu {
        display: flex;
        justify-content: center;

        .iconWrapper {
            margin-left: 18px;
            svg {
                path {
                    stroke: #37445d;
                }
            }
        }
    }

    .cartIconWrapper {
        position: relative;
        width: 26px;
        height: 26px;

        .number {
            position: absolute;
            display: inline-block;
            width: 14px;
            height: 14px;
            font-size: 10px;
            line-height: 14px;
            border-radius: 50%;
            text-align: center;
            color: #fff;
            top: 0;
            left: 100%;
            transform: translateX(-50%);
            background-color: #f43f3f;
            z-index: 2;
        }
    }

    @media (min-width: ${(props) => props.theme.breakPoints.breakTablet}) {
        display: none;
    }

    .logo {
        height: 28px;
        position: relative;

        .logoItem {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 200px;

            ${breakpoint.breakIpadPro`
                width:300px;
            `}

            ${breakpoint.breakTablet`
            width:300px;
            `}

            ${breakpoint.breakLarge`
                width:350px;
            `}
        }
    }
`;
