import { useNavigate } from "react-router-dom";
import { memo } from "react";
import queryString from "query-string";
import { IDateRange, ITourFare, IFareAvail } from "@interfaces";
import { AddToCart, StyledBookingTimeError } from "@components";
import { PATH_CHECK_AVAILABILITY } from "@routes";
import styled from "styled-components";
import { breakpoint } from "@utilities";
import _ from "lodash";

interface IProps {
    tourFare: ITourFare;
    date: IDateRange;
    changeDateFrom: (date: Date) => void;
    changeDateTo: (date: Date) => void;
    bookingTime: string | undefined;
    tourId: string | number;
}

export const TourFareComponent = memo((props: IProps) => {
    const { tourFare, date, bookingTime, tourId } = props;

    // page hook
    const navigate = useNavigate();
    //page variable
    const travelStart = !_.isNil(tourFare.travelStart) ? new Date(tourFare.travelStart) : null;
    const handleClickCheckAvail = () => {
        const params: IFareAvail = {
            fareName: tourFare.fareName,
            fareTypeId : tourFare.fareTypeId,
            bookingSystem: tourFare.bookingSystem,
            productPricesDetailsId: tourFare.productPricesDetailsId,
            ...date,
            bookingTime,
            minDate: travelStart,
            maxDate: tourFare.travelEnd ? new Date(tourFare.travelEnd) : null,
        };
        navigate(`${PATH_CHECK_AVAILABILITY}/${tourId}?${queryString.stringify(params)}`);
    };
    return (
        <Content>
            <Fare>
                <div className="title">Fare Type</div>
                <div>
                    <div className="text">{tourFare.fareName}</div>
                    {tourFare.travelStart && (
                        <div className="travel-time">
                            Travel between {tourFare.travelStart} and {tourFare.travelEnd}
                        </div>
                    )}
                </div>
            </Fare>
            <Price>
                <div>
                    Price <span>${tourFare.rrp.toFixed(2)}</span>
                </div>
                {!tourFare.bookingTimeError && !!tourFare.levy && (
                    <div className="note">
                        + ${tourFare.levy.toFixed(2)} to pay on arrival {tourFare.levyDescription}
                    </div>
                )}
            </Price>
            {!tourFare.bookingTimeError ? (
                <div className="listBtn">
                    <AddToCart
                        color={["#436CB2 0%", "#28B7C3 100%"]}
                        onClick={handleClickCheckAvail}
                        disabled={!date.from || !date.to || !bookingTime}
                    >
                        Check Availability
                    </AddToCart>
                </div>
            ) : (
                <div className="listBtn">
                    <StyledBookingTimeError>
                        We are unable to check live availability for this fare right now. Please try
                        again later.
                    </StyledBookingTimeError>
                </div>
            )}
        </Content>
    );
});

const Content = styled.div`
    background-color: #fff;
    display: flex;
    width: 100%;
    padding: 25px;
    border-bottom: 1.5px solid #e5e5e5;
    justify-content: space-between;
    align-items:end;

    .fareType,
    .date,
    .listBtn {
        width: 32%;
    }

    .listBtn {
        .MuiFormLabel-root {
            font-weight: bold;
            color: #090a18;
            font-size: 16px;
            ${breakpoint.breakIpad` 
                    font-size: 12px;
                `}
            ${breakpoint.breakIpadPro`
                    font-size: 16px;
                `}
        }
        .MuiFormControl-root {
            width: 100%;
            position: relative;
            left: 6px;
        }
        
`;

const Fare = styled.div`
    background-color: transparent;
    .title {
        font-weight: bold;
        color: #090a18;
        margin-bottom: 8px;
        font-size: 16px;
        ${breakpoint.breakIpad` 
                    font-size: 12px;
                `}
        ${breakpoint.breakIpadPro`
                    font-size: 16px;
                `}
    }
    .text {
        font-weight: normal;
        line-height: 21px;
        font-size: 16px;
        width: 400px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        ${breakpoint.breakIpad` 
                    font-size: 12px;
                `}
        ${breakpoint.breakIpadPro`
                    font-size: 16px;
                `}
    }
    .travel-time {
        font-weight: normal;
        line-height: 21px;
        font-size: 14px;
        color: ${(props) => props.theme.colors.gray_3};
        ${breakpoint.breakIpad` 
                    font-size: 10px;
                `}
        ${breakpoint.breakIpadPro`
                    font-size: 14px;
                `}
    }
`;

const Price = styled.div`
    color: #828282;
    font-size: 16px;
    ${breakpoint.breakIpad` 
                    font-size: 12px;
                `}
    ${breakpoint.breakIpadPro`
                    font-size: 16px;
                `}
                
    span {
        color: ${(props) => props.theme.colors.main};
        font-weight: 600;
        display: inline-block;
        transform: scale(1.2);
        margin-left: 10px;
        font-size: 24px;
        ${breakpoint.breakIpad` 
                        font-size: 16px;
                    `}
        ${breakpoint.breakIpadPro`
                        font-size: 24px;
                    `}
    }
    .note {
        font-size: 14px;
        margin-top: 7px;
        width: 150px;
    }
`;
