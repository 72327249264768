import styled from "styled-components";

export const StyledTourInfo = styled.div`
    padding: 0 18px 22px 18px;

    .place {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        color: ${(props) => props.theme.colors.black};
        margin-top: 20px;

        span {
            margin-left: 16px;
            max-width: 80%;
        }
    }

    .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        margin-top: 13px;
        max-width: 87%;
    }

    .durationNPrice {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 13px;

        .duration {
            color: ${(props) => props.theme.colors.black};
            display: flex;
            align-items: center;

            span {
                margin-left: 10px;
                font-size: 16px;
            }
        }

    }

    .supplier {
        margin-top: 14px;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 7px;

        span {
            color: ${(props) => props.theme.colors.main};
            line-height: 20px;
        }
    }

    .tripID,
    .tourCode {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
`;

// export const Styled
