import styled from "styled-components";

export const StyledNavBar = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
    overflow-x: scroll;
    top: 95px;
    left: 0;
    background-color: #fff;
    z-index: 2;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
`;
