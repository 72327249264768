import { memo } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { IDetailTour, IImage } from "@interfaces";
import {
    StyledButton,
    StyledSlideBannerMobile,
    StyledTourInfo,
    StyledRatio34Image,
} from "@components";
import { InfoBoxModule } from "@module";

interface IDetailTourMobileComponent {
    tour: IDetailTour;
    onCheckFare: () => void;
    handleClickSupplier?: () => void;
}

export const DetailTourMobileComponent = memo((props: IDetailTourMobileComponent) => {
    const { tour, onCheckFare, handleClickSupplier } = props;
    return (
        <>
            <StyledSlideBannerMobile>
                <Carousel infiniteLoop showThumbs={false}>
                    {[
                        ...(!tour.images.some((item: IImage) => item.path === tour.productImagePath)
                            ? [{ mediaId: "productImage", path: tour.productImagePath }]
                            : []),

                        ...tour.images.filter(
                            (item: IImage) => item.path === tour.productImagePath,
                        ),

                        ...tour.images.filter(
                            (item: IImage) => item.path !== tour.productImagePath,
                        ),
                    ].map((item: IImage, index) => (
                        <StyledRatio34Image key={index} img={item.path} />
                    ))}
                </Carousel>
            </StyledSlideBannerMobile>

            <Main>
                {/* <div className="heart">
                    <HeartTourModule tour={tour}/>
                </div> */}
                <StyledTourInfo>
                    <h1 className="title">{tour.name}</h1>
                    <p className="place">
                        <img src="/images/fi_map-pin.png" alt="mapIcon" className="mapIcon" />
                        <span>{tour.location}</span>
                    </p>
                    <hr style={{ marginTop: "16px" }} />
                    <p className="supplier" onClick={handleClickSupplier}>
                        Supplier: <span>{tour.supplier}</span>
                    </p>
                    <p className="tripID">Trip ID: {tour.productId}</p>
                    <p className="tourCode">Tour Code: {tour.tourCode}</p>
                    <hr style={{ margin: "16px 0" }} />
                    <div className="durationNPrice">
                        <div className="duration">
                            <img src="/images/fi_clock.png" alt="fi_clock" />
                            <span>{tour.duration}</span>
                        </div>
                    </div>
                </StyledTourInfo>
                <InfoBoxModule data={tour.description} />
                <div className="checkAvailBtn">
                    <div className="price">
                        From <span>${Number(tour.rrp).toFixed(2)}</span>
                    </div>
                    <StyledButton
                        borderRadius="4px"
                        color={["#436CB2 0%", "#28B7C3 100%"]}
                        onClick={onCheckFare}
                    >
                        Check Availability
                    </StyledButton>
                </div>
            </Main>
        </>
    );
});

const Main = styled.div`
    position: relative;
    background-color: #fff;
    .heart {
        position: absolute;
        top: 0;
        right: 15px;
    }

    .checkAvailBtn {
        position: fixed;
        display: flex;
        align-items: center;
        gap: 50px;
        bottom: 0;
        left: 0;
        background-color: #fff;
        width: 100%;
        padding: 24px 18px;
        box-shadow: 0px -2px 19px rgba(0, 0, 0, 0.06);

        button {
            margin-top: 0;
        }
        .price {
            color: ${(props) => props.theme.colors.gray_3};
            font-size: 16px;

            @media (max-width: 321px) {
                padding-bottom: 5px;
            }

            span {
                font-weight: 600;
                font-size: 24px;
                margin-top: 10px;
                color: ${(props) => props.theme.colors.main};
            }
        }
    }
`;
