import { useState, useEffect } from "react";

import {
    BreadcrumbsCommon,
    MenuAccountComponent,
    StyledBorderButton,
    StyledFavoritesPage,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_FAVORITES, PATH_HOME } from "@routes";
import { selectFavoriteTours, useAppSelector } from "@redux";
import { theme } from "@utilities";
import { IFavoriteTour, IPagination } from "@interfaces";
import { PAGINATION } from "@constants";
import styled from "styled-components";

export const Favorites = () => {
    const favoritesTour = useAppSelector(selectFavoriteTours).tours;
    const [, setPagingFavoritesTour] = useState<IFavoriteTour[]>(
        favoritesTour.slice(0, PAGINATION),
    );
    const [currentPage, setCurrentPage] = useState<number>(1);
    const listPagePoints: IPagination[] = [];

    const pages = Math.ceil(favoritesTour.length / PAGINATION);
    // xử lý số page cần có để hiển thị dữ liệu
    for (let i = 1; i <= pages; i++) {
        listPagePoints.push({ id: i });
    }

    useEffect(() => {
        if (favoritesTour.length <= 10) {
            setCurrentPage(1);
        }
    }, [favoritesTour]);

    useEffect(() => {
        const startPoint = (currentPage - 1) * PAGINATION;
        const endPoint = currentPage * PAGINATION;
        setPagingFavoritesTour([...favoritesTour.slice(startPoint, endPoint)]);
    }, [currentPage, favoritesTour]);

    const breadcrumbsData = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Favourites",
            link: PATH_FAVORITES,
        },
    ];

    return (
        <AppModuleLayout>
            <StyledFavoritesPage>
                <div className="wrapper">
                    <div className="breadCrumbs">
                        <BreadcrumbsCommon data={breadcrumbsData} />
                    </div>
                    <div className="subWrapper">
                        <div className="menu">
                            <MenuAccountComponent />
                        </div>
                        <div className="listProduct">
                            <div className="heading">
                                <p>Favourites</p>
                            </div>
                            {favoritesTour.length > 0 ? (
                                favoritesTour.map((tour) => (
                                    <StyledSavedCartWrapper key={tour.productId}>
                                        <StyledTopSection>
                                            <StyledQuoteTitle>{tour.name}</StyledQuoteTitle>
                                            <StyledVoucherInfo></StyledVoucherInfo>
                                        </StyledTopSection>

                                        <StyledSavedDate>Saved: </StyledSavedDate>

                                        <StyledBottomWrapper>
                                            <StyledTotalPrice>
                                                <div className="title">Total Price</div>
                                                <div className="price"></div>
                                            </StyledTotalPrice>
                                            <StyledActionsWrapper>
                                                <div className="ButtonContainer">
                                                    <button className="CancelButton">Cancel</button>
                                                    <StyledBorderButton
                                                        style={{
                                                            padding: "0px 20px",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            border: "1px solid #D9D9D9",
                                                            cursor: "pointer",
                                                        }}
                                                        textColor="#000000"
                                                        color="transparent"
                                                        borderRadius="4px"
                                                    >
                                                        Open details
                                                    </StyledBorderButton>
                                                </div>
                                            </StyledActionsWrapper>
                                        </StyledBottomWrapper>
                                    </StyledSavedCartWrapper>
                                ))
                            ) : (
                                <div className="heading">
                                    <p>You don't have any favorites</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </StyledFavoritesPage>
        </AppModuleLayout>
    );
};

const StyledTopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
`;

const StyledQuoteTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #333;
`;

const StyledSavedCartWrapper = styled.div`
    background-color: white;
    margin-bottom: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const StyledSavedDate = styled.div`
    padding: 0 20px 16px;
    color: #6e7375;
    font-size: 14px;
`;

const StyledBottomWrapper = styled.div`
    padding: 16px 20px;
    border-top: 1px solid #e0e0e0;
    gap: 10px;
    flex-direction: column;
    border-bottom: 1px solid #e0e0e0;
    position: relative;

    .content {
        color: #4f4f4f;
        font-size: 14px;
    }

    .bookingReference {
        color: #131214;
        font-size: 18px;
        font-weight: bold;
    }
`;

const StyledVoucherInfo = styled.div`
    background-color: #e9f8f9;
    padding: 6px 12px;
    border-radius: 50px;
    font-size: 14px;
    color: #31b4b9;
`;

const StyledTotalPrice = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1px;

    .title {
        font-family: DM Sans;
        font-weight: 700;
        font-size: 16px;
        color: #090a18;
    }

    .price {
        color: ${theme.colors.main || "#31B4B9"};
        font-weight: 500;
        font-size: 16px;
    }
`;

const StyledActionsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    .ButtonContainer {
        display: flex;
        align-items: center;
        gap: 30px;
    }

    .CancelButton {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 600;
        color: #31b4b9;
        background: none;
        border: none;
        cursor: pointer;
    }
`;
