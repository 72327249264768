import { Link } from "react-scroll";
import styled from "styled-components";
import parse from "html-react-parser";
import { IDescription } from "@interfaces";
import { StyledDescription, StyledNavBar } from "@components";

interface IProps {
    data: IDescription[];
    currentTagIndex: number;
    _onEnter: (index: number) => void; 
}

interface INavItemProps {
    active: number;
}

export const InfoBoxComponent = (props: IProps) => {
    const { data, currentTagIndex, _onEnter } = props;

    const activeItem = data[currentTagIndex];
    return (
        <div>
            <StyledNavBar>
                {data.map((item, index) => {
                    const titleMapping: Record<string, string> = {
                        Description: "Description",
                        Itinerary: "Itinerary",
                        "Departures/Pickups": "Departure",
                        "Agent Information": "Agent",
                        "Cancellation Policy": "Policy",
                    };

                    const normalizedTitle = titleMapping[item.title] || item.title;

                    return (
                        <div key={index}>
                            <StyledNavItem
                                to={item.title}
                                smooth={true}
                                duration={500}
                                offset={-120}
                                active={currentTagIndex === index ? 1 : 0}
                                onClick={() => _onEnter(index)}
                            >
                                <div className="title">
                                    {normalizedTitle}
                                    <div className="line"></div>
                                </div>
                            </StyledNavItem>
                        </div>
                    );
                })}
            </StyledNavBar>

            {activeItem && (
                <StyledDescription name={activeItem.title}>
                    <div className="content">{parse(activeItem.content!)}</div>
                </StyledDescription>
            )}
        </div>
    );
};

const StyledNavItem = styled(Link)<INavItemProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #eff4fb;
    cursor: pointer;
    transition:
        background-color 0.3s ease-in-out,
        color 0.3s ease-in-out;
    padding: 0 16px;

    .title {
        font-size: 14px;
        color: #898d8f;
        font-weight: 500;
        line-height: 26px;
        position: relative;
    }

    ${({ active }) =>
        active &&
        `
        background-color: #31B4B9;
        .title {
            color: white;
        }
    `}
`;
