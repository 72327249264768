import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { quoteApi } from "src/Api/quoteApi";
import {
    IAddItemToExsitingQuote,
    IAddItemToNewQuoteData,
    // Availability,
    // BookingDetails,
    IQuote,
    IQuoteCustomers,
    IQuotesState,
    IUpdateQuoteCustomers,
    // IQuoteFareCart,
} from "src/Interfaces/quotes";
import { LoadingStage } from "src/Interfaces/shared";
import { RootState } from ".";
import _ from "lodash";
import { ISetCartBookingData } from "@interfaces";
import { submitItems } from "src/Utilities/cartHelper";

// export interface IAddQuoteToCart {
//     quoteItems: IQuoteCart[];
//     replaceExisting?: boolean;
// }

// interface IQuote {
//     quotes: IQuoteCart[];
//     quotesItemsLoadingStage: LoadingStage;
//     commission: number;
//     customers: ICustomerDetail[];
//     customersLoadingStage: LoadingStage;
//     date?: Date | string;
//     id?: string;
//     totalRRP: number;
//     totalNet: number;
//     totalPrice: number;
//     isShare?: boolean;
//     bookingReference?: string;
// }

// export interface IQuoteCart extends IQuoteFareCart {
//     metadata: any;
//     paymentGateway: any;
//     items: IQuoteFareCart[];
//     title: string;
//     availability: Availability;
//     availability_last_updated_at: string;
//     booking_date: Date | string;
//     booking_details: BookingDetails | null;
//     created_at: string;
//     days: number;
//     id: string | number;
//     product_price_details_id: string | number | null;
//     selected_index: number;
//     start_date: string;
//     tdms_product_id: number;
//     time_id: string;
//     updated_at: string;
//     user_id: string;
//     booking_quantity: number;
//     bookingData: any | undefined;
//     booking_data: any; // api response comes in this field
// }

// // Initial state structure for the  quotes
const initialState: IQuotesState = {
    quotes: [],
    loadingStage: LoadingStage.NOT_INITIALIZED,
    quotesDetails: {},
    quotesCustomers: {},
};

export const loadQuotes = createAsyncThunk<IQuote[]>(
    "quotes/loadQuotes",
    async (_, { rejectWithValue }) => {
        try {
            const response = await quoteApi.getQuotes();
            return response.data.data;
        } catch (error) {
            console.error("Failed to load quotes:", error);
            return rejectWithValue("Failed to load quotes");
        }
    },
);

export const loadQuoteDetails = createAsyncThunk<IQuote, string>(
    "quotes/loadQuoteDetails",
    async (quoteId, { rejectWithValue }) => {
        try {
            const getDetailsResponse = await quoteApi.getQuoteDetails(quoteId);
            return getDetailsResponse.data.data;
        } catch (error) {
            console.error(`Failed to load quote details ${quoteId}:`, error);
            return rejectWithValue("Failed to load quote details");
        }
    },
);

export const loadQuoteCustomers = createAsyncThunk<IQuoteCustomers, string>(
    "quotes/loadQuoteCustomers",
    async (quoteId, { rejectWithValue }) => {
        try {
            const getCustomersResponse = await quoteApi.getCustomers(quoteId);
            return {
                quoteId,
                customers: getCustomersResponse.data.data
            };
        } catch (error) {
            console.error(`Failed to load quote customers ${quoteId}:`, error);
            return rejectWithValue("Failed to load quote customers");
        }
    },
);

export const updateQuoteCustomersAndReload = createAsyncThunk<IQuoteCustomers, IUpdateQuoteCustomers>(
    "quotes/updateQuoteCustomersAndReload",
    async (loadQuoteCustomers, { rejectWithValue }) => {
        try {
            await quoteApi.setCustomers(loadQuoteCustomers.quoteId, loadQuoteCustomers.customers);
            const getCustomersResponse = await quoteApi.getCustomers(loadQuoteCustomers.quoteId);
            return {
                quoteId: loadQuoteCustomers.quoteId,
                customers: getCustomersResponse.data.data
            };
        } catch (error) {
            console.error(error);
            return rejectWithValue(`Failed to update qoute customers`);
        }
    },
);

// Async thunk for adding a quote and fetching updated quotes
export const createNewQuoteAndReload = createAsyncThunk<IQuote[], IAddItemToNewQuoteData>(
    "quotes/createNewQuoteAndReload",
    async (newQuoteItem, { rejectWithValue }) => {
        try {
            // Add the new quote item
            await quoteApi.newQuote(newQuoteItem);

            // Fetch updated quotes after adding the new quote
            const response = await quoteApi.getQuotes();
            return response.data.data; // Returning the quotes data
        } catch (error) {
            console.error("Failed to add item to cart or fetch updated cart:", error);
            return rejectWithValue("Unable to add item to cart and fetch updated cart data.");
        }
    },
);

// Async thunk for adding an item to an existing quote
export const addItemToExistingQuoteAndReload = createAsyncThunk<IQuote[], IAddItemToExsitingQuote>(
    "quotes/addItemToExistingQuoteAndReload",
    async (
        addQuoteItem,
        { rejectWithValue },
    ) => {
        try {
            // Make the PUT request with the required data
            await quoteApi.addQuoteItem(addQuoteItem.quoteId, addQuoteItem);

            // Fetch the updated quotes after adding the item
            const response = await quoteApi.getQuotes();
            return response.data?.data; // Return the updated quotes list
        } catch (error) {
            console.error("Error adding item to existing quote:", error);
            return rejectWithValue("Unable to add item to existing quote.");
        }
    },
);

export const deleteQuoteAndReload = createAsyncThunk<IQuote[], string>(
    "quotes/deleteQuoteAndReload",
    async (
        quoteId,
        { rejectWithValue },
    ) => {
        try {
            // Make the PUT request with the required data
            await quoteApi.deleteQuote(quoteId);

            // Fetch the updated quotes after adding the item
            const response = await quoteApi.getQuotes();
            return response.data?.data; // Return the updated quotes list
        } catch (error) {
            console.error(`Error deleting quote ${quoteId}:`, error);
            return rejectWithValue("Failed to delete quote");
        }
    },
);

export const submitQuoteItemsAndFetch = createAsyncThunk<IQuote, { quoteId: string, items: ISetCartBookingData[] }>(
    "quotes/submitQuoteItemsAndFetch",
    async (itemsBookingData, { getState, rejectWithValue }) => {
        const state = getState() as RootState;

        try {
            const cartItemIds = itemsBookingData.items.map((i) => i.itemId);
            const responses = await submitItems(itemsBookingData.items, 'quote');

            const hasFailed = responses.some((r) => r.status === "rejected");
            if (hasFailed) {
                throw new Error("Failed to update quote");
            }

            const deletedCartItemIds = state.cart.carts
                .filter((c) => !_.includes(cartItemIds, c.id))
                .map((c) => c.id);
            const deleteCartItemsResponse = await Promise.allSettled(
                deletedCartItemIds.map((cartItemId) => quoteApi.deleteQuoteItem(cartItemId.toString())),
            );
            const hasDeleteFailed = deleteCartItemsResponse.some((r) => r.status === "rejected");
            if (hasDeleteFailed) {
                throw new Error("Failed to delete cart items");
            }
            const response = await quoteApi.getQuoteDetails(itemsBookingData.quoteId);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    },
);

export const removeQuoteItemAndFetch = createAsyncThunk<IQuote, { quoteId: string | number, itemId: string | number }>(
    "quotes/removeQuoteItemAndFetch",
    async (itemRemoveData, { rejectWithValue }) => {
        try {
            // Remove item from the cart via API
            await quoteApi.deleteQuoteItem(itemRemoveData.itemId.toString());

            // Fetch updated cart data
            const response = await quoteApi.getQuoteDetails(itemRemoveData.quoteId);
            return response.data.data;
        } catch (error) {
            console.error("Failed to remove item from quote:", error);
            return rejectWithValue("Unable to remove item from quote and fetch updated quote data.");
        }
    },
);

// const recalculateTotals = (state: IQuote) => {
//     state.totalRRP = state.quotes.reduce((total, quote) => total + quote.rrp * quote.quantity, 0);
//     state.totalNet = state.quotes.reduce(
//         (total, quote) => total + (quote.net || 0) * quote.quantity,
//         0,
//     );
//     state.commission = state.totalRRP - state.totalNet;
//     state.totalPrice = state.totalRRP;
// };

const quotesSlice = createSlice({
    name: "quote",
    initialState,
    reducers: {
        resetQuote: () => initialState, // Reset state action
        loadQuoteData: (state, action: PayloadAction<string>) => {
            const existingQuote = state.quotes.find(q => q.id === action.payload);
            if (!_.isNil(existingQuote)) { // load from cached list
                state.quotesDetails[action.payload] = existingQuote;
            }
            const existingQuoteCustomers = state.quotesCustomers[action.payload];
            if (!_.isNil(existingQuoteCustomers)) { // load from cached list
                state.quotesCustomers[action.payload] = existingQuoteCustomers;
            }

            loadQuoteDetails(action.payload);
            loadQuoteCustomers(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle fetchQuote actions
            .addCase(loadQuotes.pending, (state) => {
                state.loadingStage = LoadingStage.IS_LOADING;
            })
            .addCase(loadQuotes.fulfilled, (state, action) => {
                state.quotes = action.payload;
                state.loadingStage = LoadingStage.LOADING_COMPLETE;
                // recalculateTotals(state);
            })
            .addCase(loadQuotes.rejected, (state, action) => {
                state.loadingStage = LoadingStage.LOADING_COMPLETE;
                console.error("Error fetching fetchQuote:", action.payload);
            })
            // Handle addToQuoteAndFetch actions
            .addCase(createNewQuoteAndReload.rejected, (_, action) => {
                console.error("Error adding to quote:", action.payload);
            })
            // Handle addItemToExistingQuote actions
            .addCase(addItemToExistingQuoteAndReload.rejected, (_, action) => {
                console.error("Error adding item to existing quote:", action.payload);
            })
            .addCase(loadQuoteDetails.fulfilled, (state, action) => {
                const existingQuoteIndex = _.findIndex(state.quotes, q => q.id === action.payload.id);
                if (existingQuoteIndex > -1) {
                    state.quotes[existingQuoteIndex] = action.payload;
                }
                state.quotesDetails[action.payload.id] = action.payload;
            })
            .addCase(loadQuoteDetails.rejected, (_, action) => {
                console.error("Error loading quote details:", action.payload);
            })
            .addCase(loadQuoteCustomers.fulfilled, (state, action) => {
                state.quotesCustomers[action.payload.quoteId] = action.payload;
            })
            .addCase(loadQuoteCustomers.rejected, (_, action) => {
                console.error("Error loading quote customers:", action.payload);
            })
            .addCase(submitQuoteItemsAndFetch.rejected, (_, action) => {
                console.error("Failed to submit cart data and fetch", action.payload);
            })
            .addCase(submitQuoteItemsAndFetch.fulfilled, (state, action) => {
                const existingQuoteIndex = _.findIndex(state.quotes, q => q.id === action.payload.id);
                if (existingQuoteIndex > -1) {
                    state.quotes[existingQuoteIndex] = action.payload;
                }
                state.quotesDetails[action.payload.id] = action.payload;
            })
            .addCase(removeQuoteItemAndFetch.fulfilled, (state, action) => {
                const existingQuoteIndex = _.findIndex(state.quotes, q => q.id === action.payload.id);
                if (existingQuoteIndex > -1) {
                    state.quotes[existingQuoteIndex] = action.payload;
                }
                state.quotesDetails[action.payload.id] = action.payload;
            })
            .addCase(updateQuoteCustomersAndReload.fulfilled, (state, action) => {
                state.quotesCustomers[action.payload.quoteId] = action.payload;
            });
    },
});
export const selectQuotes = (state: RootState) => state.quotes;

export const { resetQuote, loadQuoteData } = quotesSlice.actions;
export default quotesSlice.reducer;