import { IAddCart, IDirectPurchaseData, ICreateOrderData, IFareCart, ISetCustomerData } from "@interfaces";
import ftApiClient from "./ftApiClient";
import wtApiClient from "./wtApiClient";
import _ from "lodash";
import { IAPIResult } from "src/Interfaces/api";

export const cartApi = {
    addCart: (params: IAddCart | IAddCart[]) => {
        const url = "/cart/items";
        return ftApiClient.post(url, params);
    },
    addCartDryRun: (params: IAddCart) => {
        const url = "/cart/items?dry=1";
        return ftApiClient.post<IAPIResult<IFareCart[]>>(url, params);
    },
    getCart: () => {
        const url = "/cart/items";
        return ftApiClient.get(url);
    },
    saveCart: (
        cartItemId: number | string,
        params: { bookingData: { pickupId?: string }; quantity: number },
    ) => {
        const url = `cart/items/${cartItemId}`;
        return ftApiClient.put(url, params);
    },
    removeCart: (cartItemId: number | string) => {
        const url = `cart/items/${cartItemId}`;
        return ftApiClient.delete(url);
    },
    updateCustomer: (params: ISetCustomerData[]) => {
        const url = "cart/customers";
        return ftApiClient.put(url, params);
    },
    getCustomer: () => {
        const url = "cart/customers";
        return ftApiClient.get(url);
    },
    createOrder: (data: ICreateOrderData) => {
        const url = "cart/order/v2";
        return ftApiClient.post(url, data);
    },
    confirmAvailability: (productPriceDetailsId: string, bookingDate: Date, timeId?: number) => {
        const url = _.isNil(timeId)
            ? `bookingdetails/detailsToConfirmAvailability/${productPriceDetailsId}/${bookingDate}`
            : `bookingdetails/detailsToConfirmAvailability/${productPriceDetailsId}/${bookingDate}/${timeId}`;
        return wtApiClient.get(url);
    },
    purchaseDirect: (data: IDirectPurchaseData) => {
        const url = `cart/order/v2/direct-purchase`;
        return ftApiClient.post(url,data);
    }
};
