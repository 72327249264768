import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { SearchFilterItemComponent, StyledButton, StyledFilterDesktop } from "@components";
import {
    removeExceptCountry,
    removeExceptRegions,
    removeExceptState,
    removeExceptTowns,
    setAccommodation,
    setAccommodationCategory,
    setActivity,
    setActivityCategory,
    setCountries,
    setCountriesCategory,
    setRegions,
    setRegionsCategory,
    setStates,
    setStatesCategory,
    setSupplier,
    setSupplierCategory,
    setTowns,
    setTownsCategory,
    setTransport,
    setTransportCategory,
    setDay,
    resetSearch,
    setCurrentPage,
    useAppSelector,
    selectSearchCategories,
    setLengthOfTrip,
    setLengthOfTripCategory,
    setSortBy,
    setName,
    setSortDirection,
    AppDispatch,
} from "@redux";
import { categoriesApi } from "@api";
import { IOption } from "@interfaces";
import { PATH_SEARCH_RESULT } from "@routes";
import { useWindowDimensions } from "@utilities";
import { filterConstants } from "@constants";

interface IProps {
    onToggleShowFilter?: () => void;
    onApplyFilter?: () => void;
    onResetSearch?: () => void;
}

export const FilterModule = (props: IProps) => {
    const {
        COUNTRIES,
        STATES,
        REGIONS,
        LOCALITIES,
        ACCOMMODATION,
        ACTIVITIES,
        TRANSPORTS,
        SUPPLIERS,
        LENGTH_OF_TRIP,
    } = filterConstants;
    const filterConstantsArr = [
        COUNTRIES,
        STATES,
        REGIONS,
        LOCALITIES,
        ACCOMMODATION,
        ACTIVITIES,
        SUPPLIERS,
        TRANSPORTS,
        LENGTH_OF_TRIP,
    ];
    const [currentOptionIndex, setCurrentOptionIndex] = useState(-1);
    const searchCategories = useAppSelector(selectSearchCategories);
    const dispatch = useDispatch<AppDispatch>();
    const [lengthOfTrip, setLengthOfTripState] = useState<number | null | string | undefined>(null);
    const isSearchResultPage = window.location.pathname.includes(PATH_SEARCH_RESULT);
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const filterTagModel = [
        {
            icon: "/images/filter/fi_map-pin.svg",
            title: "Choose Countries",
            name: "countries",
        },
        {
            icon: "/images/filter/fi_map-pin.svg",
            title: "Choose States",
            name: "states",
        },
        {
            icon: "/images/filter/fi_map-pin.svg",
            title: "Choose Regions",
            name: "regions",
        },
        {
            icon: "/images/filter/fi_map-pin.svg",
            title: "Choose Towns",
            name: "localities",
        },
        {
            icon: "/images/filter/fi_home.png",
            title: "Accommodation Types",
            name: "accommodations",
        },
        {
            icon: "/images/filter/fi_activity.png",
            title: "Activity Types",
            name: "activities",
        },
        {
            icon: "/images/filter/fi_box.png",
            title: "Suppliers",
            name: "suppliers",
        },
        {
            icon: "/images/filter/fi_truck.png",
            title: "Transport Types",
            name: "transports",
        },
        {
            icon: "/images/filter/fi_calendar.png",
            title: "Length of trip (days)",
            name: "duration",
        },
    ];
    const fetchCategories = async (
        actionType: string,
        stateId?: number | string,
        paramType?: string
    ) => {
        //@ts-ignore
        if (actionType === "lengthOfTrip") {
            const lengthOfTrip1: IOption[] = [];
            for (let i = 1; i <= 100; ++i) {
                lengthOfTrip1.push({
                    id: i,
                    text: i.toString(),
                });
            }
            dispatch(setLengthOfTripCategory(lengthOfTrip1));
            return;
        }
        if (actionType === "getCountries") {
            const res = await categoriesApi[actionType](stateId, paramType);
            dispatch(
                setCountriesCategory(
                    res.data.results.filter((country: any) => {
                        return country?.text !== "Accommodation";
                    })
                )
            );
        } else if (actionType === "getStates") {
            const res = await categoriesApi[actionType](stateId, paramType);
            dispatch(setStatesCategory(res.data.results));
        } else if (actionType === "getRegions") {
            const res = await categoriesApi[actionType](stateId, paramType);
            dispatch(setRegionsCategory(res.data.results));
        } else if (actionType === "getTowns") {
            const res = await categoriesApi[actionType](stateId, paramType);
            dispatch(setTownsCategory(res.data.results));
        } else if (actionType === "getAccommodation") {
            const res = await categoriesApi[actionType](stateId, paramType);
            dispatch(setAccommodationCategory(res.data.results));
        } else if (actionType === "getActivity") {
            const res = await categoriesApi[actionType](stateId, paramType);
            dispatch(setActivityCategory(res.data.results));
        } else if (actionType === "getTransport") {
            const res = await categoriesApi[actionType](stateId, paramType);
            dispatch(setTransportCategory(res.data.results));
        } else if (actionType === "getSupplier") {
            const res = await categoriesApi[actionType]();
            dispatch(setSupplierCategory(res.data.results));
        }
    };

    const handleClickTag = (categoryIndex: number) => {
        let prevCategoryId;
        let paramType;
        // if (currentOptionIndex === -1) {
        switch (categoryIndex) {
            case 0:
                if (!searchCategories.categories.countries.length) {
                    fetchCategories("getCountries");
                }
                break;
            case 1:
                if (!searchCategories.categories.states.length) {
                    if (searchCategories.countries) {
                        fetchCategories("getStates", searchCategories.countries.id, "countries");
                    } else {
                        fetchCategories("getStates");
                    }
                }
                break;
            case 2:
                if (searchCategories.states) {
                    prevCategoryId = searchCategories.states.id;
                    paramType = "states";
                } else if (searchCategories.countries) {
                    prevCategoryId = searchCategories.countries.id;
                    paramType = "countries";
                }
                if (!searchCategories.categories.regions.length) {
                    if (prevCategoryId) {
                        fetchCategories("getRegions", prevCategoryId, paramType);
                    } else {
                        fetchCategories("getRegions");
                    }
                }
                break;
            case 3:
                if (searchCategories.regions) {
                    prevCategoryId = searchCategories.regions.id;
                    paramType = "regions";
                } else if (searchCategories.states) {
                    prevCategoryId = searchCategories.states.id;
                    paramType = "states";
                } else if (searchCategories.countries) {
                    prevCategoryId = searchCategories.countries.id;
                    paramType = "countries";
                }
                if (!searchCategories.categories.localities.length) {
                    if (prevCategoryId) {
                        fetchCategories("getTowns", prevCategoryId, paramType);
                    } else {
                        fetchCategories("getTowns");
                    }
                }
                break;
            case 4:
                if (searchCategories.localities) {
                    prevCategoryId = searchCategories.localities.id;
                    paramType = "localities";
                } else if (searchCategories.regions) {
                    prevCategoryId = searchCategories.regions.id;
                    paramType = "regions";
                } else if (searchCategories.states) {
                    prevCategoryId = searchCategories.states.id;
                    paramType = "states";
                } else if (searchCategories.countries) {
                    prevCategoryId = searchCategories.countries.id;
                    paramType = "countries";
                }
                if (!searchCategories.categories.accommodation.length) {
                    if (prevCategoryId) {
                        fetchCategories("getAccommodation", prevCategoryId, paramType);
                    } else {
                        fetchCategories("getAccommodation");
                    }
                }
                break;
            case 5:
                if (searchCategories.localities) {
                    prevCategoryId = searchCategories.localities.id;
                    paramType = "localities";
                } else if (searchCategories.regions) {
                    prevCategoryId = searchCategories.regions.id;
                    paramType = "regions";
                } else if (searchCategories.states) {
                    prevCategoryId = searchCategories.states.id;
                    paramType = "states";
                } else if (searchCategories.countries) {
                    prevCategoryId = searchCategories.countries.id;
                    paramType = "countries";
                }
                if (!searchCategories.categories.activities.length) {
                    if (prevCategoryId) {
                        fetchCategories("getActivity", prevCategoryId, paramType);
                    } else {
                        fetchCategories("getActivity");
                    }
                }
                break;
            case 7:
                if (searchCategories.localities) {
                    prevCategoryId = searchCategories.localities.id;
                    paramType = "localities";
                } else if (searchCategories.regions) {
                    prevCategoryId = searchCategories.regions.id;
                    paramType = "regions";
                } else if (searchCategories.states) {
                    prevCategoryId = searchCategories.states.id;
                    paramType = "states";
                } else if (searchCategories.countries) {
                    prevCategoryId = searchCategories.countries.id;
                    paramType = "countries";
                }
                if (!searchCategories.categories.transports.length) {
                    if (prevCategoryId) {
                        fetchCategories("getTransport", prevCategoryId, paramType);
                    } else {
                        fetchCategories("getTransport");
                    }
                }
                break;
            case 6:
                if (!searchCategories.categories.suppliers.length) {
                    fetchCategories("getSupplier");
                }
                break;
            case 8: {
                fetchCategories("lengthOfTrip");
                break;
            }
        }

        if (currentOptionIndex === categoryIndex) {
            setCurrentOptionIndex(-1);
        } else {
            setCurrentOptionIndex(categoryIndex);
        }
        // } else {
        //     setCurrentOptionIndex(-1);
        // }
    };

    const handleCloseOptions = () => {
        setCurrentOptionIndex(-1);
    };

    const handleClickItem = (categoryItem: IOption) => {
        if (currentOptionIndex === 0) {
            if (
                (searchCategories.countries && categoryItem.id !== searchCategories.countries.id) ||
                !searchCategories.countries
            ) {
                dispatch(setCountries(categoryItem));
                dispatch(removeExceptCountry());
            } else {
                dispatch(removeExceptCountry());
                dispatch(setCountries(null));
            }
        } else if (currentOptionIndex === 1) {
            if (
                (searchCategories.states && categoryItem.id !== searchCategories.states.id) ||
                !searchCategories.states
            ) {
                dispatch(setStates(categoryItem));
                dispatch(removeExceptState());
            } else {
                dispatch(removeExceptState());
                dispatch(setStates(null));
            }
        } else if (currentOptionIndex === 2) {
            if (
                (searchCategories.regions && categoryItem.id !== searchCategories.regions.id) ||
                !searchCategories.regions
            ) {
                dispatch(setRegions(categoryItem));
                dispatch(removeExceptRegions());
            } else {
                dispatch(removeExceptRegions());
                dispatch(setRegions(null));
            }
        } else if (currentOptionIndex === 3) {
            if (
                (searchCategories.localities &&
                    categoryItem.id !== searchCategories.localities.id) ||
                !searchCategories.localities
            ) {
                dispatch(setTowns(categoryItem));
                dispatch(removeExceptTowns());
            } else {
                dispatch(removeExceptTowns());
                dispatch(setTowns(null));
            }
        } else if (currentOptionIndex === 4) {
            if (
                (searchCategories.accommodation &&
                    categoryItem.id !== searchCategories.accommodation.id) ||
                !searchCategories.accommodation
            ) {
                dispatch(setAccommodation(categoryItem));
            } else {
                dispatch(setAccommodation(null));
            }
        } else if (currentOptionIndex === 5) {
            if (
                (searchCategories.activities &&
                    categoryItem.id !== searchCategories.activities.id) ||
                !searchCategories.activities
            ) {
                dispatch(setActivity(categoryItem));
            } else {
                dispatch(setActivity(null));
            }
        } else if (currentOptionIndex === 6) {
            if (
                (searchCategories.suppliers && categoryItem.id !== searchCategories.suppliers.id) ||
                !searchCategories.suppliers
            ) {
                dispatch(setSupplier(categoryItem));
            } else {
                dispatch(setSupplier(null));
            }
        } else if (currentOptionIndex === 7) {
            if (
                (searchCategories.transports &&
                    categoryItem.id !== searchCategories.transports.id) ||
                !searchCategories.transports
            ) {
                dispatch(setTransport(categoryItem));
            } else {
                dispatch(setTransport(null));
            }
        } else if (currentOptionIndex === 8) {
            if (
                (searchCategories.duration && categoryItem.id !== searchCategories.duration.id) ||
                !searchCategories.duration
            ) {
                dispatch(setLengthOfTrip(categoryItem));
                setLengthOfTripState(categoryItem.text);
            } else {
                dispatch(setLengthOfTrip(null));
                setLengthOfTripState(null);
            }
        }
        setCurrentOptionIndex(-1);
    };

    const handleSearch = () => {
        dispatch(setDay(Number(lengthOfTrip)));
        if (props.onApplyFilter) {
            // gọi hàm handleApplyFilter bên page/searchResult
            props.onApplyFilter();
        }
        dispatch(setCurrentPage(1));
        if (props.onToggleShowFilter) props.onToggleShowFilter();
    };

    const handleResetSearchResult = () => {
        dispatch(setName(""));
        dispatch(resetSearch());
        dispatch(setSortBy("rrp"));
        dispatch(setSortDirection("asc"));
        if (props.onToggleShowFilter && props.onApplyFilter) props.onToggleShowFilter();
        if (props.onApplyFilter) {
            // gọi hàm handleApplyFilter bên page/searchResult
            props.onApplyFilter();
        }
    };
    return (
        <StyledFilterDesktop isSearchResultPage={isSearchResultPage} width={width}>
            <div className="heading mobile">
                <span className="text">Filter</span>
                <button className="closeBtn" onClick={props.onToggleShowFilter}>
                    <img src="/images/fi_x.png" alt="" />
                </button>
            </div>
            <div className="searchResultPage">
                <div className="heading">
                    <span className="text">Filter</span>
                    <button className="resetBtn" onClick={handleResetSearchResult}>
                        Reset
                    </button>
                </div>
            </div>
            <div className="filterRow">
                {filterConstantsArr.map((constant: string, index: number) => {
                    const classExternal =
                        index === 0 || index === 4 || index === 8
                            ? "filterCol-338"
                            : "filterCol-258";
                    return index === 8 ? (
                        <React.Fragment key={index}>
                            <div className="filterItem filterCol-338">
                                <SearchFilterItemComponent
                                    isShowListOption={currentOptionIndex === index}
                                    tagName={filterTagModel[index].title}
                                    icon={filterTagModel[index].icon}
                                    //@ts-ignore
                                    categoryData={searchCategories.categories[constant]}
                                    onClickTag={() => {
                                        handleClickTag(index);
                                    }}
                                    onCloseOptions={handleCloseOptions}
                                    onClickItem={handleClickItem}
                                    //@ts-ignore
                                    selectedCategoryName={searchCategories[constant]?.text}
                                    name={filterTagModel[index].name}
                                />
                            </div>
                            <div className={`filterItem filterCol-258 `}>
                                {!isSearchResultPage ? (
                                    <ResetButton onClick={handleResetSearchResult}>
                                        Reset
                                    </ResetButton>
                                ) : (
                                    ""
                                )}
                                <div className="resetBtn mobile" onClick={handleResetSearchResult}>
                                    <span className="icon">
                                        <img src="/images/fi_refresh.png" alt="" />
                                    </span>
                                    <span className="text">Reset</span>
                                </div>
                            </div>
                            <div
                                className={`filterItem filterCol-514 ${
                                    isSearchResultPage ? "searchResult" : ""
                                }`}
                            >
                                <SearchButton
                                    onClick={() => {
                                        handleSearch();
                                        navigate(PATH_SEARCH_RESULT);
                                    }}
                                >
                                    Search
                                </SearchButton>
                            </div>
                            <div
                                className={`filterItem applyFilterBtn mobile ${
                                    isSearchResultPage ? "searchResult" : ""
                                }`}
                            >
                                <StyledButton borderRadius="4px" color={["#436CB2 0%", "#28B7C3 100%"]} onClick={handleSearch}>Apply Filter</StyledButton>
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className={`filterItem ${classExternal}`} key={index}>
                            <SearchFilterItemComponent
                                isShowListOption={currentOptionIndex === index}
                                tagName={filterTagModel[index].title}
                                icon={filterTagModel[index].icon}
                                //@ts-ignore
                                categoryData={searchCategories.categories[constant]}
                                onClickTag={() => {
                                    handleClickTag(index);
                                }}
                                onCloseOptions={handleCloseOptions}
                                onClickItem={handleClickItem}
                                //@ts-ignore
                                selectedCategoryName={searchCategories[constant]?.text}
                                name={filterTagModel[index].name}
                            />
                        </div>
                    );
                })}
            </div>
        </StyledFilterDesktop>
    );
};

const ResetButton = styled(StyledButton)`
    margin-top: 0;
    background-color: ${(p) => p.theme.colors.gray_3};
    height: 100%;
    border-radius: none;

    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        display: none;
    }
`;

const SearchButton = styled(StyledButton)`
    margin-top: 0;
    height: 100%;
    background: linear-gradient(90deg, #436CB2 0%, #28B7C3 100%);


    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        display: none;
    }
`;
