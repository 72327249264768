import queryString from "query-string";
import axios from "axios";
import { IOrderData } from "@interfaces";
import wtApiClient from "./wtApiClient";
import ftApiClient from "./ftApiClient";

export const bookingApi = {
    directPurchase: (params: any) => {
        const url = "/cart/order/v2/direct-purchase/";
        return ftApiClient.post(url, params);
    },
    getBookingReference: () => {
        const url = "/bookingreference";
        return wtApiClient.post(url);
    },
    getPaymentMethod: () => {
        const url = "/agent/paymentmethods";
        return wtApiClient.get(url);
    },
    order: (body: IOrderData) => {
        const url = "/order";
        return wtApiClient.post(url, body);
    },
    completeOrder: (params: string) => {
        const url = `/cart/order/complete${params}`;
        return ftApiClient.put(url, {});
    },
    getPaymentGatewayUrl: (
        bookingReference: string,
        paymentAmount: string | number,
        returnUrl: string,
    ) => {
        const dataRaw = { showProducts: 1 };
        const url = `pgw/authenticate/${bookingReference}?paymentAmount=${paymentAmount}&returnUrl=${returnUrl}`;
        return wtApiClient({
            method: "GET",
            url,
            data: dataRaw,
        });
    },
    getListBooking: () => {
        const url = `/cart/bookings`;
        return ftApiClient.get(url);
    },
    getListCustomerOrder: () => {
        const url = "/customerOrderDetail?since=9-jun-2021&searchOnlyStatus=Quote";
        return wtApiClient.get(url);
    },
    getBookingDetail: (id: string | number) => {
        const url = `voucher/${id}.json`;
        return wtApiClient.get(url);
    },
    getBookingDetailWithToken: (id: string | number, token: string) => {
        const url = `voucher/${id}.json`;
        const axiosClientBookingDetail = axios.create({
            baseURL: process.env.REACT_APP_API_URL,

            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${token}`,
            },

            paramsSerializer: (params) => queryString.stringify(params),
        });
        return axiosClientBookingDetail.get(url);
    },
    convertQuoteToOrder: (bookingReference: string) => {
        const url = `/order/${bookingReference}/convertQuote`;
        return wtApiClient.post(url);
    },
    convertQuoteToOrderWithToken: (bookingReference: string, token: string) => {
        const url = `/order/${bookingReference}/convertQuote`;
        const axiosClientConvertQuote = axios.create({
            baseURL: process.env.REACT_APP_API_URL,

            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${token}`,
            },

            paramsSerializer: (params) => queryString.stringify(params),
        });
        return axiosClientConvertQuote.post(url);
    },
};
