import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { categoriesApi, toursApi } from "@api";
import { ITour } from "@interfaces";
import { concat, map, mapValues, keyBy, chain, get, flatMap, find, uniqBy, keys } from "lodash";
import { RootState } from ".";

// Define our slice state interface.
interface ToursCategoryState {
    finalToursByCategory: Record<string, ITour[]>;
    isFetchingComplete: boolean;
    loading: boolean;
    error?: string;
}

const initialState: ToursCategoryState = {
    finalToursByCategory: {},
    isFetchingComplete: false,
    loading: false,
    error: undefined,
};

export interface CategoriesResponse {
    data: {
        data: Record<string, string[]>;
    };
}

export interface ResultsResponse {
    data: {
        results: any[];
    };
}

export const fetchToursByCategory = createAsyncThunk<
    Record<string, ITour[]>, // Return type
    void,
    { rejectValue: string }
>("toursCategory/fetchToursByCategory", async (_, thunkAPI) => {
    try {
        // 1. Fetch initial data concurrently.
        const [categoryRes, accommodationData, activitiesData, transportData] = await Promise.all([
            categoriesApi.getCategories().then((res) => {
                return (res.data as CategoriesResponse["data"]).data;
            }),
            categoriesApi.getAccommodation("20", "countries").then((res) => {
                return (res.data as ResultsResponse["data"]).results;
            }),
            categoriesApi.getActivity("20", "countries").then((res) => {
                return (res.data as ResultsResponse["data"]).results;
            }),
            categoriesApi.getTransport("20", "countries").then((res) => {
                return (res.data as ResultsResponse["data"]).results;
            }),
        ]);

        const categoryData: Record<string, string[]> = categoryRes || {};
        const combinedData = {
            accommodation: accommodationData,
            activities: activitiesData,
            transport: transportData,
        };

        if (
            !combinedData.accommodation.length ||
            !combinedData.activities.length ||
            !combinedData.transport.length ||
            keys(categoryData).length === 0
        ) {
            return {};
        }

        // 2. Merge and map category data to IDs.
        const mergedDataArray = concat(
            map(combinedData.accommodation, (item: any) => ({ ...item, type: "accommodation" })),
            map(combinedData.activities, (item: any) => ({ ...item, type: "activities" })),
            map(combinedData.transport, (item: any) => ({ ...item, type: "transport" })),
        );

        // Create a lookup keyed by the item's "text" property.
        const combinedDataMap = mapValues(keyBy(mergedDataArray, "text"), (item: any) => ({
            id: item.id,
            type: item.type,
        }));

        const categoryIdData: Record<string, number[]> = mapValues(
            categoryData,
            (items: string[]) =>
                chain(items)
                    .map((item) => get(combinedDataMap, [item, "id"]))
                    .filter((val): val is number => typeof val === "number")
                    .value(),
        );

        // 3. Build the API call list from categoryIdData.
        const processedIds = new Set<number>();
        const apiCallList = flatMap(categoryIdData, (ids, categoryName) =>
            chain(ids)
                .filter((id) => {
                    if (processedIds.has(id)) return false;
                    processedIds.add(id);
                    return true;
                })
                .map((id) => {
                    const foundItem = find(mergedDataArray, { id });
                    return foundItem ? { categoryName, id, type: foundItem.type } : null;
                })
                .compact()
                .value(),
        );

        // 4. Process the API calls in batches of 4 concurrently.
        const finalData: Record<string, ITour[]> = {};
        const queue = [...apiCallList];

        while (queue.length > 0) {
            const batch = queue.splice(0,4);
            const promises = batch.map(({ categoryName, id, type }) => {
                const searchData = { [type]: id, countries: 20 };
                return toursApi.search(searchData, {}).then((res) => {
                    const tours: ITour[] = (res?.data?.results ?? []).map((tour: any) => ({
                        productId: tour.productId,
                        name: tour.name,
                        location: tour.country,
                        durationDays: tour.durationDays,
                        durationNight: tour.durationNight,
                        rrp: Number(tour.rrp).toFixed(2),
                        productImagePath: tour.productLargeSizeImagePath,
                    }));
                    return { categoryName, tours };
                });
            });

            // Process the current batch concurrently
            // eslint-disable-next-line no-await-in-loop
            const batchResults = await Promise.all(promises);

            batchResults.forEach(({ categoryName, tours }) => {
                if (!finalData[categoryName]) {
                    finalData[categoryName] = [];
                }
                // Merge and deduplicate tours by productId.
                finalData[categoryName] = uniqBy(
                    [...finalData[categoryName], ...tours],
                    "productId",
                );
            });

            thunkAPI.dispatch(updatePartialTours(finalData));
        }

        return finalData;
    } catch (error) {
        console.error(error);
        return thunkAPI.rejectWithValue("Failed to fetch tours by category");
    }
});

const toursCategorySlice = createSlice({
    name: "toursCategory",
    initialState,
    reducers: {
        updatePartialTours: (state, action: PayloadAction<Record<string, ITour[]>>) => {
            keys(action.payload).forEach((category) => {
                if (!state.finalToursByCategory[category]) {
                    state.finalToursByCategory[category] = [];
                }
                // Merge and deduplicate tours.
                state.finalToursByCategory[category] = uniqBy(
                    [...state.finalToursByCategory[category], ...action.payload[category]],
                    "productId",
                );
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchToursByCategory.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            fetchToursByCategory.fulfilled,
            (state, action: PayloadAction<Record<string, ITour[]>>) => {
                state.loading = false;
                // Use deduplicated final data.
                state.finalToursByCategory = {};
                keys(action.payload).forEach((category) => {
                    state.finalToursByCategory[category] = uniqBy(
                        action.payload[category],
                        "productId",
                    );
                });
                state.isFetchingComplete = true;
            },
        );
        builder.addCase(fetchToursByCategory.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
    },
});

export const { updatePartialTours } = toursCategorySlice.actions;
export const selectCategoryTours = (state: RootState) => state.toursCategory;
export default toursCategorySlice.reducer;
