import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ITour } from "@interfaces";
import { breakpoint } from "@utilities";
import { TourElementModule } from "@module";
import { PATH_FAVORITES } from "@routes";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

interface IProps {
    data: ITour[];
    type?: string | null;
}

export const PromoTourModule = (props: IProps) => {
    const { data, type } = props;
    const navigate = useNavigate();

    // Initialize Keen Slider with settings and breakpoints
    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        breakpoints: {
            "(min-width: 1024px)": {
                slides: {
                    perView: 3,
                    spacing: 22,
                },
            },
            "(min-width: 1200px)": {
                slides: {
                    perView: 3,
                    spacing: 22,
                },
            },
            "(min-width: 768px)": {
                slides: {
                    perView: 3,
                    spacing: 10,
                },
            },
            "(max-width: 475px)": {
                slides: {
                    perView: 1,
                    spacing: 12,
                },
            },
        },
    });

    const handlePrev = () => {
        instanceRef.current?.prev();
    };

    const handleNext = () => {
        instanceRef.current?.next();
    };

    const handleSeeAll = () => {
        if (type === "favorite") {
            navigate(PATH_FAVORITES);
        }
    };

    return (
        <Wrapper>
            {data.length > 3 && (
                <button className="prevBtn" onClick={handlePrev}>
                    <img src="/images/website/destinations/fi_chevron-left.png" alt="prev" />
                </button>
            )}
            <div className="sliderWrapper">
                {type && data.length > 3 && (
                    <button className="seeAll" onClick={handleSeeAll}>
                        See all
                    </button>
                )}
                <div ref={sliderRef} className="keen-slider">
                    {data.map((tour, index) => (
                        <div className="keen-slider__slide" key={`${tour.productId}-${index}`}>
                            <TourElementModule data={tour} />
                        </div>
                    ))}
                </div>
            </div>
            {data.length > 3 && (
                <button className="nextBtn" onClick={handleNext}>
                    <img src="/images/website/destinations/fi_chevron-right.png" alt="next" />
                </button>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin: 0 auto;
    position: relative;
    z-index: 0;

    ${breakpoint.breakMobileMedium`
    width: ${(props) => props.theme.widths.wide_700};
  `}

    ${breakpoint.breakIpadPro`
    width: ${(props) => props.theme.widths.wide_900};

  `}

   ${breakpoint.breakTablet`
    width: ${(props) => props.theme.widths.wide_768};   
    padding: 0 70px;
  `}

  ${breakpoint.breakIpadPro`
    width: ${(props) => props.theme.widths.wide_1024}; 
    padding: 0 0px;
  `}

  ${breakpoint.breakLarge`
    width: ${(props) => props.theme.widths.wide_1110};
  `}

  .seeAll {
        position: absolute;
        right: 0;
        bottom: calc(100% + 36px);
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        color: ${(p) => p.theme.colors.main};
        z-index: 5;
        background: transparent;
        border: none;
        cursor: pointer;
    }

    .sliderWrapper {
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
    }

    .keen-slider {
        display: flex;
    }

    .keen-slider__slide {
        min-width: 0;
    }

    .prevBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        border-radius: 50%;
        top: 40%;
        left: -5%;
        height: 32px;
        width: 32px;
        ${breakpoint.breakMobile`
            background: #fff;
        `}
        background: transparent;
        border: none;
        cursor: pointer;
        z-index: 10;
    }

    .nextBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        border-radius: 50%;
        top: 40%;
        right: -5%;
        height: 32px;
        width: 32px;
        ${breakpoint.breakMobile`
            background: #fff;
        `}
        background: transparent;
        border: none;
        cursor: pointer;
        z-index: 10;
    }
`;

export default PromoTourModule;
