import { IAddItemToExistingQuoteData, IAddItemToNewQuoteData, IQuote } from "src/Interfaces/quotes";
import ftApiClient from "./ftApiClient";
import { ICustomerInfo, ISetCustomerData } from "@interfaces";
import { IAPIResult } from "src/Interfaces/api";

export const quoteApi = {
    newQuote: (params: IAddItemToNewQuoteData) => {
        const url = "/quotes/new";
        return ftApiClient.post(url, params);
    },
    getQuotes: () => {
        const url = "/quotes";
        return ftApiClient.get<IAPIResult<IQuote[]>>(url);
    },
    getQuoteDetails: (quoteId: number | string) => {
        const url = `/quotes/${quoteId}`;
        return ftApiClient.get<IAPIResult<IQuote>>(url);
    },
    deleteQuote: (quoteId: number | string) => {
        const url = `quotes/${quoteId}`;
        return ftApiClient.delete(url);
    },
    addQuoteItem: (quoteId: string, itemData: IAddItemToExistingQuoteData) => {
        const url = `/quotes/${quoteId}/items/add`;
        return ftApiClient.put(url, itemData); // Send PUT request with the item data
    },
    updateQuoteItem: (
        quoteItemId: number | string,
        params: { bookingData: { pickupId?: string }; quantity: number },
    ) => {
        const url = `quotes/items/${quoteItemId}`;
        return ftApiClient.put(url, params);
    },
    deleteQuoteItem: (itemId: string) => {
        const url = `/quotes/items/${itemId}`;
        return ftApiClient.delete(url);
    },
    setCustomers: (quoteId: number | string, params: ISetCustomerData[]) => {
        const url = `quotes/${quoteId}/customers`;
        return ftApiClient.put(url, params);
    },
    getCustomers: (quoteItemId: number | string) => {
        const url = `quotes/${quoteItemId}/customers`;
        return ftApiClient.get<IAPIResult<ICustomerInfo[]>>(url);
    },
    createOrder: (quoteId: string) => {
        const url = `quotes/${quoteId}/order`;
        return ftApiClient.post(url);
    },
};
