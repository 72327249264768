import {
    Login,
    Home,
    Term,
    ForgotPassword,
    SearchResult,
    DetailTour,
    CheckAvailability,
    Cart,
    BookNow,
    Booking,
    AccountSetting,
    Favorites,
    Quotes,
    BookingDetail,
    LinkAgentAccount,
    // Accounting,
    MyAccount,
    MyAccountSetting,
    NotFound,
    UpdatePassword,
    ShareOrderSuccess,
    ShareSuccess,
    ShareBookingDetail,
    PaymentWrapper,
    SignUp,
    OrderSuccess,
    QuoteDetails,
} from "@pages";
import { IBreadcrumbs, IRoute } from "@interfaces";
import PaymentFail from "src/Pages/PaymentFail";
import { SetupOrderCheckComponent } from "@components";

export const PATH_HOME = "/";
export const PATH_LOGIN = "/login";
export const PATH_SIGNUP = "/signup";
export const PATH_TERM = "/term";
export const PATH_FORGOT_PASSWORD = "/forgot-password";
export const PATH_SEARCH_RESULT = "/search-result";
export const PATH_DETAIL_TOUR = "/detail-tour";
export const PATH_CHECK_AVAILABILITY = "/check-availability";
export const PATH_CART = "/cart";
export const PATH_BOOK_NOW = "/book-now";
export const PATH_BOOKING = "/booking";
export const PATH_MENU_SETTING = "/menu-setting";
export const PATH_FAVORITES = "/favorites";
export const PATH_QUOTES = "/quotes";
export const PATH_BOOKING_DETAIL = "/booking-detail";
export const PATH_MY_ACCOUNT = "/my-account";
export const PATH_ACCOUNT = "/account";
export const PATH_UPDATE_PASSWORD = "/update-password";
export const PATH_SHARE_ORDER_SUCCESS = "/share/order-success";
export const PATH_SHARE_SUCCESS = "/share/success";
export const PATH_SHARE_BOOKING_DETAIL = "/share/book-detail";
export const PATH_PAYMENT_WRAPPER = "/payment-wrapper";
export const PATH_ORDER_SUCCESS = "/order/success";
export const PATH_LINK_AGENT_ACCOUNT = "/link-agent-account";
export const PATH_PAYMENT_FAIL = "/paymentfail";
export const PATH_ORDER_CHECK = "/order/check";

export const authPath = [
    PATH_LOGIN,
    PATH_SIGNUP,
    PATH_TERM,
    PATH_FORGOT_PASSWORD,
    PATH_SHARE_ORDER_SUCCESS,
    PATH_SHARE_SUCCESS,
    PATH_SHARE_BOOKING_DETAIL,
    PATH_PAYMENT_FAIL,
];

export const normalPath = [
    PATH_HOME,
    PATH_DETAIL_TOUR,
    PATH_SEARCH_RESULT,
    PATH_CHECK_AVAILABILITY,
];

export const protectedPath = [
    PATH_CART,
    PATH_BOOK_NOW,
    PATH_BOOKING,
    PATH_ACCOUNT,
    PATH_MENU_SETTING,
    PATH_FAVORITES,
    PATH_BOOKING_DETAIL,
    PATH_QUOTES,
    PATH_MY_ACCOUNT,
    PATH_UPDATE_PASSWORD,
    PATH_ORDER_SUCCESS,
    PATH_LINK_AGENT_ACCOUNT,
];

export const public_route: Array<IRoute> = [
    { path: PATH_HOME, element: Home },
    { path: PATH_SEARCH_RESULT, element: SearchResult },
    { path: PATH_DETAIL_TOUR + "/:id", element: DetailTour },
    {
        path: PATH_DETAIL_TOUR + "/:id" + "/:fareTypeId",
        element: CheckAvailability,
    },
    { path: PATH_ORDER_CHECK, element: SetupOrderCheckComponent },
];

export const protected_route: Array<IRoute> = [
    { path: PATH_CART + "/:idSaveCart", element: Cart },
    { path: PATH_CART, element: Cart },
    { path: PATH_BOOK_NOW, element: BookNow },
    { path: PATH_BOOKING, element: Booking },
    { path: PATH_MENU_SETTING, element: AccountSetting },
    { path: PATH_FAVORITES, element: Favorites },
    { path: PATH_QUOTES, element: Quotes },
    { path: PATH_QUOTES + "/:quoteId", element: QuoteDetails },
    { path: PATH_BOOKING_DETAIL + "/:id" + "/:tripId", element: BookingDetail },
    { path: PATH_MY_ACCOUNT, element: MyAccountSetting },
    { path: PATH_ACCOUNT, element: MyAccount },
    { path: PATH_UPDATE_PASSWORD, element: UpdatePassword },
    { path: PATH_PAYMENT_WRAPPER, element: PaymentWrapper },
    { path: PATH_ORDER_SUCCESS, element: OrderSuccess },
    { path: PATH_LINK_AGENT_ACCOUNT, element: LinkAgentAccount },
    { path: "**", element: NotFound },
];

export const auth_route: Array<IRoute> = [
    { path: PATH_LOGIN, element: Login },
    { path: PATH_SIGNUP, element: SignUp },
    { path: PATH_TERM, element: Term },
    { path: PATH_FORGOT_PASSWORD, element: ForgotPassword },
    { path: PATH_SHARE_SUCCESS + "/:token", element: ShareSuccess },
    { path: PATH_SHARE_ORDER_SUCCESS, element: ShareOrderSuccess },
    { path: PATH_SHARE_BOOKING_DETAIL + "/:id", element: ShareBookingDetail },
    { path: PATH_PAYMENT_WRAPPER, element: PaymentWrapper },
    { path: PATH_PAYMENT_FAIL, element: PaymentFail },
    { path: "**", element: NotFound },
];

// breadcrumbs
export const CartBreadcrumbs: IBreadcrumbs[] = [
    {
        title: "Home",
        link: PATH_HOME,
    },
    {
        title: "Cart",
        link: PATH_CART,
    },
];
export const QuotesBreadcrumbs: IBreadcrumbs[] = [
    { title: "Home", link: PATH_HOME },
    { title: "Quotes", link: PATH_QUOTES },
];

export const BookNowBreadcrumbs: IBreadcrumbs[] = [
    { title: "Home", link: PATH_HOME },
    { title: "Book Now", link: PATH_BOOK_NOW },
];
