import { useEffect, useState } from "react";
import moment from "moment";
import {
    BreadcrumbsCommon,
    ClipLoaderCommon,
    MenuAccountComponent,
    StyledBorderButton,
    StyledSavedCartPage,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_QUOTES, QuotesBreadcrumbs } from "@routes";
import styled from "styled-components";
import {
    fetchCart,
    resetCart,
    useAppDispatch,
    useAppSelector,
} from "@redux";
import { LoadingStage } from "src/Interfaces/shared";
import { theme } from "@utilities";
import { deleteQuoteAndReload, loadQuotes } from "src/Redux/Slices/quotes";
import { calculatePricingInfo } from "src/Utilities/cartHelper";
import { IQuote } from "src/Interfaces/quotes";
import { useNavigate } from "react-router-dom";

export const Quotes = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const quotes = useAppSelector((state) => state.quotes.quotes);
    const quotesLoadingStage = useAppSelector((state) => state.quotes.loadingStage);
    const isLoading = quotesLoadingStage === LoadingStage.IS_LOADING;

    // Local loading state for delete operation
    const [isDeleting, setIsDeleting] = useState(false);

    // Modal state with type tracking and selected quoteId
    const [modalState, setModalState] = useState<{
        isOpen: boolean;
        type?: string;
        quoteId?: string;
    }>({
        isOpen: false,
    });

    useEffect(() => {
        dispatch(resetCart());
        dispatch(fetchCart());
        if (quotes.length === 0) {
            dispatch(loadQuotes());
        }
    }, [dispatch, quotes.length]);

    const closeModal = () => {
        setModalState({ isOpen: false });
    };

    const openCancelModal = (quoteId: string) => {
        setModalState({
            isOpen: true,
            type: "cancel",
            quoteId,
        });
    };

    const handleDeleteQuote = () => {
        if (!modalState.quoteId) return;

        try {
            setIsDeleting(true);
            dispatch(deleteQuoteAndReload(modalState.quoteId));
            closeModal();
        } catch (error) {
            console.error("Error deleting quote:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleOpenDetail = (quote: IQuote): void => {
        navigate(`${PATH_QUOTES}/${quote.id}`);
        // if (!quote || !quote.items || !Array.isArray(quote.items) || quote.items.length === 0) {
        //     console.error("❌ No valid quote or items found:", quote);
        //     return;
        // }

        // try {
        //     setIsAddingToCart(true);

        //     // Dispatch action to add all quote items to cart (without API call)
        //     dispatch(
        //         addQuoteToCartAndFetch({
        //             quoteItems: quote.items,
        //             quoteId: quote.id,
        //             replaceExisting: true,
        //         }),
        //     );
        //     // Navigate to cart page
        //     navigate("/cart");
        // } catch (error) {
        //     console.error("Error adding quote to cart:", error);
        // } finally {
        //     setIsAddingToCart(false);
        // }
    };

    return (
        <AppModuleLayout>
            {isLoading ? (
                <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
            ) : (
                <StyledSavedCartPage>
                    <div className="wrapper">
                        <div className="breadCrumbs">
                            <BreadcrumbsCommon data={QuotesBreadcrumbs} />
                        </div>
                        <div className="subWrapper">
                            <div className="menu">
                                <MenuAccountComponent />
                            </div>
                            <div className="listCarts">
                                <div className="heading">
                                    <p>Quotes</p>
                                </div>
                                {quotes.length > 0 ? (
                                    quotes.map((quote) => (
                                        <StyledSavedCartWrapper key={quote.id}>
                                            <StyledTopSection>
                                                <StyledQuoteTitle>{quote.title}</StyledQuoteTitle>
                                                <StyledVoucherInfo>
                                                    {quote.items.length}{" "}
                                                    {quote.items.length === 1 ? "item" : "items"}
                                                </StyledVoucherInfo>
                                            </StyledTopSection>

                                            <StyledSavedDate>
                                                Saved:{" "}
                                                {moment(quote.created_at).format(
                                                    "MMM DD, YYYY, hh:mm A",
                                                )}
                                            </StyledSavedDate>

                                            <StyledBottomWrapper>
                                                <StyledTotalPrice>
                                                    <div className="title">Total Price</div>
                                                    <div className="price">
                                                        ${calculatePricingInfo(quote.items).totalRRP}
                                                    </div>
                                                </StyledTotalPrice>

                                                <StyledActionsWrapper>
                                                    <div className="ButtonContainer">
                                                        <button
                                                            onClick={() =>
                                                                openCancelModal(quote.id.toString())
                                                            }
                                                            className="CancelButton"
                                                        >
                                                            Cancel
                                                        </button>
                                                        <StyledBorderButton
                                                            onClick={() => handleOpenDetail(quote)}
                                                            style={{
                                                                padding: "0px 20px",
                                                                fontSize: "16px",
                                                                fontWeight: "500",
                                                                border: "1px solid #D9D9D9",
                                                                cursor: "pointer",
                                                            }}
                                                            textColor="#000000"
                                                            color="transparent"
                                                            borderRadius="4px"
                                                        >
                                                            Open details
                                                        </StyledBorderButton>
                                                    </div>
                                                </StyledActionsWrapper>
                                            </StyledBottomWrapper>
                                        </StyledSavedCartWrapper>
                                    ))
                                ) : (
                                    <div className="heading">
                                        <p>You don't have any quotes</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Modal directly in the JSX */}
                    {modalState.isOpen && modalState.type === "cancel" && (
                        <ModalOverlay onClick={isDeleting ? undefined : closeModal}>
                            <ModalContainer onClick={(e) => e.stopPropagation()}>
                                <CloseButton
                                    onClick={isDeleting ? undefined : closeModal}
                                    disabled={isDeleting}
                                >
                                    ×
                                </CloseButton>
                                <ModalHeader>
                                    <h2>Cancel quote?</h2>
                                </ModalHeader>
                                <ModalContent>
                                    Are you sure you want to cancel this quote?
                                </ModalContent>
                                {isDeleting ? (
                                    <ClipLoaderCommon color="#31B4B9" size={30} margin={0} />
                                ) : (
                                    <ButtonGroup>
                                        <YesButton
                                            onClick={handleDeleteQuote}
                                            disabled={isDeleting}
                                        >
                                            Yes, Cancel
                                        </YesButton>
                                        <NoButton onClick={closeModal}>No</NoButton>
                                    </ButtonGroup>
                                )}
                            </ModalContainer>
                        </ModalOverlay>
                    )}{" "}
                </StyledSavedCartPage>
            )}
        </AppModuleLayout>
    );
};

/* Styled Components */
const StyledTopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
`;

const StyledQuoteTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #333;
`;

const StyledSavedCartWrapper = styled.div`
    background-color: white;
    margin-bottom: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const StyledSavedDate = styled.div`
    padding: 0 20px 16px;
    color: #6e7375;
    font-size: 14px;
`;

const StyledBottomWrapper = styled.div`
    padding: 16px 20px;
    border-top: 1px solid #e0e0e0;
    gap: 10px;
    flex-direction: column;
    border-bottom: 1px solid #e0e0e0;
    position: relative;

    .content {
        color: #4f4f4f;
        font-size: 14px;
    }

    .bookingReference {
        color: #131214;
        font-size: 18px;
        font-weight: bold;
    }
`;

const StyledVoucherInfo = styled.div`
    background-color: #e9f8f9;
    padding: 6px 12px;
    border-radius: 50px;
    font-size: 14px;
    color: #31b4b9;
`;

const StyledTotalPrice = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1px;

    .title {
        font-family: DM Sans;
        font-weight: 700;
        font-size: 16px;
        color: #090a18;
    }

    .price {
        color: ${theme.colors.main || "#31B4B9"};
        font-weight: 500;
        font-size: 16px;
    }
`;

const StyledActionsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    .ButtonContainer {
        display: flex;
        align-items: center;
        gap: 30px;
    }

    .CancelButton {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 600;
        color: #31b4b9;
        background: none;
        border: none;
        cursor: pointer;
    }
`;

// Modal styled components - updated layout but original colors
const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(9, 10, 24, 0.4);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContainer = styled.div`
    background: #fff;
    width: 100%;
    max-width: 400px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 20px 60px;
    text-align: center;
`;

const ModalHeader = styled.div`
    margin-bottom: 30px;

    h2 {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
    }
`;

const ModalContent = styled.div`
    color: #6e7375;
    font-size: 14px;
    margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const YesButton = styled.button`
    width: 50%;
    padding: 12px;
    background: linear-gradient(to right, #436cb2, #28b7c3);
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 auto;
    &:hover {
        background: linear-gradient(to right, #28b7c3, #436cb2);
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
`;

const NoButton = styled.button`
    width: 50%;
    padding: 12px;
    background: transparent;
    color: #31b4b9;
    font-weight: bold;
    border: 1px solid #31b4b9;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 auto;
`;

const CloseButton = styled.button<{ disabled?: boolean }>`
    background: transparent;
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    color: ${({ disabled }) => (disabled ? "#ccc" : "#000")}; /* Gray when disabled */
    font-size: 24px;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")}; /* Prevent clicking */
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    line-height: 1;
`;
