import styled from "styled-components";
import { ITag } from "./index";
import { NavLink } from "react-router-dom";

const FooterTag = (props: ITag) => {
    const { Icon, text, link, active } = props;

    //@ts-ignore
    const IconTag = () => <Icon />;

    return (
        <StyledNavLink to={link} className={active ? "active" : ""}>
            <IconTag />
            <span>{text}</span>
        </StyledNavLink>
    );
};

const StyledNavLink = styled(NavLink)`
    display: flex;
    width: 100%;
    font-size: clamp(1rem, 3vw, 1.8rem);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    transition: background-color 0.3s ease;

    span {
        margin-top: 8px;
        color: #898d8f;
    }

    &.active {
        svg {
            path {
                stroke: #37445d;
            }
        }
        span {
            color: #37445d;
            font-weight: 700;
        }
    }

    &:hover {
        background-color: #e0e0e0;
    }
`;


export default FooterTag;
