import styled from "styled-components";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useCallback, useState } from "react";

import {
    CheckBoxComponent,
    StyledChecker,
    StyledButton,
    TotalPriceComponent,
    ClipLoaderCommon,
} from "@components";
import { breakpoint } from "@utilities";
import { PAYMENT_OPTION, SHARE_OPTION } from "@constants";
import { IFareCart } from "@interfaces";
import { ErrorToast } from "src/Components/toasts/ErrorToast";
import { calculatePricingInfo } from "src/Utilities/cartHelper";

interface IProps {
    items: IFareCart[]
    handleOrderCreate: (paymentType: string) => Promise<void>
    onOrderCreateSuccess: () => void
}

export const PaymentModule = (props: IProps) => {
    //page hook
    //props
    const { items, handleOrderCreate, onOrderCreateSuccess } = props;
    //redux state
    const pricingInfo = calculatePricingInfo(items);
    const availablePrice = pricingInfo.totalPrice >= pricingInfo.totalNet;
    //page state
    const [paymentOption, setPaymentOption] = useState(PAYMENT_OPTION);
    const [isAgreeTerm, setIsAgreeTerm] = useState<boolean>(false);
    const [isOrderInProgress, setOrderInProgress] = useState<boolean>(false);

    const onChangePaymentOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentOption((event.target as HTMLInputElement).value);
    };

    const handleSwitchPayment = useCallback(() => {
        // TODO: remove
        // dispatch(
        //     share({
        //         isShare: false,
        //         redirectUrl: "",
        //     }),
        // );
    }, []);

    const handleCreateOrder = async (paymentType: string) => {
        const createOrderFailedmessage = "Failed to create order";
        try {
            setOrderInProgress(true);
            await handleOrderCreate(paymentType);
            onOrderCreateSuccess();
            // const createOrderResponse = await cartApi.createOrder({ paymentType });
            // if (createOrderResponse.status !== 200) {
            //     throw new Error(createOrderFailedmessage);
            // }
            // if (paymentType === "pay-now") {
            //     const quoteUrl = (
            //         createOrderResponse.data.data as IPayNowResponseData
            //     ).payNow.redirectUrl.replaceAll("\\", "");
            //     window.location.href = quoteUrl;
            // } else {
            //     moveTo(CartConstants.ORDER_SUCCESS);
            // }
        } catch (error) {
            console.error(error);
            ErrorToast({ Message: createOrderFailedmessage });
        } finally {
            setOrderInProgress(false);
            // dispatch(fetchCart);
            // dispatch(fetchCustomer);
        }
    };

    const handleToggleAgree = () => {
        setIsAgreeTerm(!isAgreeTerm);
    };

    return (
        <Container>
            <div className="payment-wrapper">
                <div className="priceWrapper">
                    <TotalPriceComponent
                        cartItems={items}
                        disableNext={false}
                        paymentOption={paymentOption}
                        page="payment"
                    />
                </div>
                <ListOptions>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            value={paymentOption}
                            onChange={onChangePaymentOption}
                        >
                            <FormControlLabel
                                value={PAYMENT_OPTION}
                                control={<Radio onClick={handleSwitchPayment} />}
                                label="Pay now by credit card"
                            />
                        </RadioGroup>
                    </FormControl>
                    <label htmlFor="check" className="checkBox">
                        <StyledChecker>
                            <CheckBoxComponent
                                id="check"
                                isChecked={isAgreeTerm}
                                onChange={handleToggleAgree}
                            />
                        </StyledChecker>
                        <p className="checkBoxText">
                            I accept the{" "}
                            <a
                                href="https://freelancetravel.com/booking-terms-and-conditions/"
                                target="_blank"
                            >
                                booking terms and conditions.
                            </a>
                        </p>
                    </label>
                    {paymentOption === PAYMENT_OPTION ? (
                        <PaymentButton
                            borderRadius="4px"
                            color={["#436CB2 0%", "#28B7C3 100%"]}
                            onClick={() => handleCreateOrder("pay-now")}
                            disabled={!isAgreeTerm || !availablePrice || isOrderInProgress}
                        >
                            {isOrderInProgress ? (
                                <>
                                    <ClipLoaderCommon color="#31B4B9" size={35} margin={5} />
                                </>
                            ) : (
                                PAYMENT_OPTION
                            )}
                        </PaymentButton>
                    ) : (
                        <PaymentButton
                            borderRadius="4px"
                            color={["#436CB2 0%", "#28B7C3 100%"]}
                            onClick={() => handleCreateOrder("email-quote")}
                            disabled={!isAgreeTerm || !availablePrice || isOrderInProgress}
                        >
                            {isOrderInProgress ? (
                                <>
                                    <ClipLoaderCommon color="#31B4B9" size={35} margin={5} />
                                </>
                            ) : (
                                SHARE_OPTION
                            )}
                        </PaymentButton>
                    )}
                </ListOptions>
            </div>
        </Container>
    );
};

const Container = styled.div`
    background-color: #f2f2f2;
    padding: 16px 16px 0 16px;
    height: 100%;
    flex: 1;
    width: 100%;

    ${breakpoint.breakTablet`
        background-color: #F2F2F2;
        padding: 0px 16px 60px 16px;
    `}

    /* ${breakpoint.breakLarge`
        padding: 16px 16px 60px 16px;
    `} */

    .payment-wrapper {
        ${breakpoint.breakTablet`
            margin: 0 auto;
            width: 530px;
            display: flex;
            flex-direction: row-reverse;

            .priceWrapper {
                flex: 350;
            }
        `}

        ${breakpoint.breakIpadPro`
            width: 800px;
        `}

        ${breakpoint.breakLarge`
            width: 920px;
        `}

        .priceWrapper {
            margin-bottom: 17px;

            ${breakpoint.breakTablet`
                margin-bottom: 0;
            `}

            ${breakpoint.breakLarge`
                min-width: 350px;
            `}
        }
    }

    .checkBox {
        margin-top: 40px;
        display: flex;
        align-items: center;

        .checkBoxText {
            font-size: 13px;
            font-weight: normal;
            line-height: 18px;
            color: ${(p) => p.theme.colors.gray_2};
            position: relative;
            top: -5px;

            ${breakpoint.breakIpadPro`
                font-size: 14px;
            `}

            ${breakpoint.breakLarge`
                .checkBoxText {
                    margin-left: 14px;
                }
            `}
        }
        a {
            color: ${(props) => props.theme.colors.main};
            font-weight: 600;
        }
    }
`;

const ListOptions = styled.ul`
    background-color: #fff;
    margin: 0 -16px;
    padding: 0 16px 1px 16px;

    ${breakpoint.breakTablet`
        flex: 610;
        margin-right: 30px;
        padding: 0 25px;
        padding-top: 30px;
    `}

    ${breakpoint.breakIpadPro`
        flex: 410;
        margin-right: 100px;
        padding: 0 35px;
        padding-top: 25px;
    `}

    ${breakpoint.breakLarge`
        margin-right: 76px;
        padding: 34px 65px 0 65px;
    `}
    @media (min-width: 768px) and (max-width: 1023px) {
        padding: 0 20px;
        padding-top: 30px;
    }

    .MuiRadio-colorSecondary.Mui-checked {
        color: ${(props) => props.theme.colors.main} !important;
    }
    .MuiTypography-body1 {
        font-family: DM Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;

        ${breakpoint.breakTablet`
            font-size: 14px;
        `}
    }

    .PrivateSwitchBase-root-1 {
        padding: 9px 12px;
    }
`;

const PaymentButton = styled(StyledButton)`
    margin-bottom: 45px;
    margin-top: 20px;

    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) and (max-width: ${(p) =>
            p.theme.breakPoints.breakLarge}) {
        height: 40px;
        margin-bottom: 20px;
        margin-top: 15px;
    }
`;
