import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import {
    IAddCart,
    IDirectPurchaseData,
    IFareCart,
    IDirectPurchaseItemData,
    ISetCustomerData,
    ISetCartBookingData,
} from "@interfaces";
import { cartApi } from "@api";
import { LoadingStage } from "src/Interfaces/shared";
import _ from "lodash";
import { convertCartItemToDirectPurchaseData } from "src/Utilities/cartHelper";

export const addToBookNow = createAsyncThunk<IFareCart[], IAddCart>(
    "bookNow/addToBookNow",
    async (addBookNowItem, { rejectWithValue }) => {
        try {
            const response = await cartApi.addCartDryRun(addBookNowItem);
            return response.data.data.map(item => ({
                ...item,
                booking_quantity: 1, // pass one as it is not available from the dry run
            }));
        } catch (error) {
            const errorMessage = `Failed to add item in book now`;
            console.error(errorMessage, error);
            return rejectWithValue(errorMessage);
        }
    },
);

export const completeBookNowPurchase = createAsyncThunk<void, IDirectPurchaseData>(
    "bookNow/completeBookNowPurchase",
    async (directPurchaseData, { rejectWithValue }) => {
        try {
            const response = await cartApi.purchaseDirect(directPurchaseData);
            return response.data.data;
        } catch (error) {
            const errorMessage = `Failed to book`;
            console.error(errorMessage, error);
            return rejectWithValue(errorMessage);
        }
    },
);

interface BookNowState {
    item?: IFareCart | null;
    directPurchaseItemData?: IDirectPurchaseItemData | null;
    customers: ISetCustomerData[];
    loadingStage: LoadingStage;
}

const initialState: BookNowState = {
    item: null,
    customers: [],
    loadingStage: LoadingStage.NOT_INITIALIZED,
};
const bookNowSlice = createSlice({
    name: "bookNow",
    initialState,
    reducers: {
        setBookNowItemData: (state, action: PayloadAction<ISetCartBookingData>) => {
            const itemData = state.directPurchaseItemData!;
            state.directPurchaseItemData = {
                ...itemData,
                bookingData: {
                    ...itemData.bookingData,
                    ...action.payload,
                },
            };
            if (!_.isNil(state.item)) {
                const booking_data = state.item.booking_data ?? {};
                state.item = {
                    ...state.item,
                    booking_data: {
                        ...booking_data,
                        optionalData: action.payload.bookingData,
                    },
                };
            }
        },
        setBookNowCustomerData: (state, action: PayloadAction<ISetCustomerData[]>) => {
            state.customers = action.payload;
        },
        clearBookNowItem: (state) => {
            state.loadingStage = LoadingStage.NOT_INITIALIZED;
            state.item = null;
            state.directPurchaseItemData = null;
            state.customers = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addToBookNow.pending, (state) => {
                state.loadingStage = LoadingStage.IS_INITIALIZING;
            })
            .addCase(addToBookNow.rejected, (_, action) => {
                console.error(`Failed to dry run book now item`, action.payload);
            })
            .addCase(addToBookNow.fulfilled, (state, action) => {
                state.item = _.first(action.payload);
                state.directPurchaseItemData = convertCartItemToDirectPurchaseData(state.item!);
                state.loadingStage = LoadingStage.INITIALIZE_COMPLETE;
            });
    },
});

export const { setBookNowItemData, setBookNowCustomerData, clearBookNowItem } =
    bookNowSlice.actions;

export const selectBookNow = (state: RootState) => state.bookNow;

export default bookNowSlice.reducer;
