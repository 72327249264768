import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { Container as PromisedContainer } from "react-modal-promise";
import "react-toastify/dist/ReactToastify.css";
import { theme, storageVersion } from "@utilities";
import {
    store,
    persistor,
    useAppDispatch,
    useIsLoggedIn,
    useShouldVerifyEmail,
    useIsProfileSetupInProgress,
    useNeedsRealEmail,
} from "@redux";
import { auth_route, public_route, protected_route } from "@routes";
import ProtectedRoute from "./Routes/ProtectedRoute";
import { IRoute } from "@interfaces";
import AuthRoute, { SetupProfileRoute } from "./Routes/AuthRoute";
import PublicRoute from "./Routes/PublicRoute";
import { SetupLoaderComponent } from "@components";
import { ForgotPassword } from "@pages";

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <PersistGate loading={null} persistor={persistor}>
                    <div className="App ">
                        <BrowserRouter>
                            <Main />
                        </BrowserRouter>
                    </div>
                </PersistGate>
            </ThemeProvider>
        </Provider>
    );
}

function Main() {
    // Redux state
    const dispatch = useAppDispatch();
    const isLoggedIn = useIsLoggedIn();
    const needsRealEmail = useNeedsRealEmail();
    const shouldVerifyEmail = useShouldVerifyEmail();
    const isProfileSetupInProgress = useIsProfileSetupInProgress();

    // App version check
    const appVersion = storageVersion.getItem();
    const currentVersion = "2320061530";
    if (!appVersion || appVersion !== currentVersion) {
        localStorage.clear();
        localStorage.removeItem("persist:root");
        dispatch({ type: "RESET" });
        storageVersion.setItem(currentVersion);
    }

    const allRoute = (
        <>
            <Route element={<ProtectedRoute />}>
                {protected_route.map((route: IRoute, key) => (
                    <Route key={key} path={route.path} element={<route.element />} />
                ))}
            </Route>
            <Route element={<PublicRoute />}>
                {public_route.map((route: IRoute, key) => (
                    <Route key={key} path={route.path} element={<route.element />} />
                ))}
            </Route>
            <Route element={<AuthRoute />}>
                {auth_route.map((route: IRoute, key) => (
                    <Route key={key} path={route.path} element={<route.element />} />
                ))}
            </Route>
        </>
    );

    return (
        <>
            <Routes>
                {!isLoggedIn ? (
                    allRoute
                ) : shouldVerifyEmail || needsRealEmail ? (
                    <Route element={<SetupProfileRoute />}>
                        <Route path="*" element={<ForgotPassword />} />
                    </Route>
                ) : isProfileSetupInProgress ? (
                    <Route element={<SetupProfileRoute />}>
                        <Route path="*" element={<SetupLoaderComponent />} />
                    </Route>
                ) : (
                    allRoute
                )}
            </Routes>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                closeButton={false}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <PromisedContainer />
        </>
    );
}
export default App;
