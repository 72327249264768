import { Formik, FieldArray, Form, FormikHelpers } from "formik";
import styled from "styled-components";
import { useEffect, useState } from "react";
import {
    AddMyDetailsComponent,
    BinSymbolCommon,
    CustomerDetailFormComponent,
    MyDetailsComponent,
    StyledButton,
} from "@components";
import { BookNowConstants } from "@constants";
import {
    selectAccount,
    selectBookNow,
    useAppSelector,
} from "@redux";
import * as Yup from "yup";
import { externalApi } from "@api";
import { ErrorToast } from "src/Components/toasts/ErrorToast";
import { customerDetailSchema } from "@validations";
import moment from "moment";
import _ from "lodash";
import { breakpoint } from "@utilities";
import { ISetCustomerData } from "@interfaces";

interface IProps {
    moveTo: (step: number) => void;
    handleCustomersSave: (customerData: ISetCustomerData[]) => Promise<void>
}

export const BookNowCheckoutModule = (props: IProps) => {
    const { moveTo, handleCustomersSave } = props;
    const BookedNowItem = useAppSelector(selectBookNow);
    const { account } = useAppSelector(selectAccount);
    const [countriesData, setCountriesData] = useState([]);
    const [isShowErrorDate, setIsShowErrorDate] = useState<boolean>(false);
    const rrp = BookedNowItem.item?.rrp ?? 0;

    const initialValues: { customers: ISetCustomerData[] } = {
        customers: [],
    };

    useEffect(() => {
        (async () => {
            const res = await externalApi.getCountriesDate();
            setCountriesData(res.data);
        })();
    }, []);

    const getDateValue = (dateOfBirth: string | Date | null | undefined): Date | null => {
        if (!dateOfBirth) return null;
        return typeof dateOfBirth === "string" ? new Date(dateOfBirth) : dateOfBirth;
    };
    const isAccountIncomplete = !(
        account?.first_name &&
        account?.last_name &&
        account?.email &&
        account?.phone_number &&
        account?.post_code &&
        account?.country_code
    );

    const handleSubmit = async (
        values: { customers: ISetCustomerData[] },
        { setSubmitting, validateForm }: FormikHelpers<{ customers: ISetCustomerData[] }>,
    ) => {
        try {
            const validationResult = await validateForm();
            if (!_.isEmpty(validationResult)) {
                return;
            }
            const formattedValues = {
                ...values,
                customers: values.customers.map((customer) => ({
                    ...customer,
                    dateOfBirth: moment(customer.dateOfBirth).format("DD-MMM-YYYY"),
                })),
            };
            await handleCustomersSave(formattedValues.customers);
            moveTo(BookNowConstants.PURCHASE);
        } catch (error) {
            console.error(error);
            ErrorToast({ Message: "Failed to update customers" });
        } finally {
            setSubmitting(false);
        }
    };
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
                customers: Yup.array().of(customerDetailSchema),
            })}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, handleChange, handleBlur, handleSubmit }) => (
                <Container>
                    <Form className="wrapper">
                        <Info>
                            <FieldArray
                                name="customers"
                                render={(arrayHelpers) => (
                                    <User>
                                        {!isAccountIncomplete ? (
                                            <MyDetailsComponent
                                                account={account}
                                                countriesData={countriesData}
                                            />
                                        ) : (
                                            <AddMyDetailsComponent />
                                        )}

                                        {values.customers.map((customer, index) => {
                                            const dateValue = getDateValue(customer.dateOfBirth);

                                            const handleChangeDate = (
                                                date: Date | null,
                                                index: number,
                                            ) => {
                                                setIsShowErrorDate(true);
                                                const formattedDate = date
                                                    ? moment(date).format("DD-MMM-YYYY")
                                                    : null;
                                                setFieldValue(
                                                    `customers[${index}].dateOfBirth`,
                                                    formattedDate,
                                                );
                                            };

                                            return (
                                                <div
                                                    className="listCustomerDetail"
                                                    key={customer.customerIndex || index}
                                                >
                                                    <Container2>
                                                        <Header>
                                                            <p className="heading">
                                                                Additional Traveller
                                                            </p>
                                                            <div
                                                                className="bin"
                                                                onClick={() =>
                                                                    arrayHelpers.remove(index)
                                                                }
                                                            >
                                                                <BinSymbolCommon />
                                                            </div>
                                                        </Header>
                                                        <FormContainer>
                                                            <CustomerDetailFormComponent
                                                                name={`customers[${index}]`}
                                                                values={customer}
                                                                countriesData={countriesData}
                                                                dateValue={dateValue}
                                                                handleChangeDate={(date) =>
                                                                    handleChangeDate(date, index)
                                                                }
                                                                isShowErrorDate={isShowErrorDate}
                                                                handleChange={handleChange}
                                                                handleBlur={handleBlur}
                                                                handleSubmit={handleSubmit}
                                                            />
                                                        </FormContainer>
                                                        <div
                                                            className="btnRefresh"
                                                            onClick={() => {
                                                                values.customers.forEach(
                                                                    (_, index) => {
                                                                        setFieldValue(
                                                                            `customers[${index}].firstName`,
                                                                            "",
                                                                        );
                                                                        setFieldValue(
                                                                            `customers[${index}].lastName`,
                                                                            "",
                                                                        );
                                                                        setFieldValue(
                                                                            `customers[${index}].phoneNumber`,
                                                                            "",
                                                                        );
                                                                        setFieldValue(
                                                                            `customers[${index}].email`,
                                                                            "",
                                                                        );
                                                                        setFieldValue(
                                                                            `customers[${index}].postalCode`,
                                                                            "",
                                                                        );
                                                                        setFieldValue(
                                                                            `customers[${index}].dateOfBirth`,
                                                                            null,
                                                                        );
                                                                        setFieldValue(
                                                                            `customers[${index}].countryCode`,
                                                                            "",
                                                                        );
                                                                    },
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src="/images/fi_refresh-ccw.svg"
                                                                alt="fi_refresh-ccw"
                                                            />
                                                            <span>Reset</span>
                                                        </div>
                                                    </Container2>
                                                </div>
                                            );
                                        })}
                                        {!isAccountIncomplete && (
                                            <>
                                                <div className="secondaryActions">
                                                    <div className="btnAddCustomer">
                                                        <img
                                                            src="/images/fi_plus-circle.png"
                                                            alt="fi_plus-circle"
                                                        />
                                                        <span
                                                            onClick={() => {
                                                                const newIndex =
                                                                    values.customers.length;
                                                                arrayHelpers.push({
                                                                    customerIndex: newIndex,
                                                                    id: Date.now(),
                                                                    firstName: "",
                                                                    lastName: "",
                                                                    phoneNumber: "",
                                                                    email: "",
                                                                    postalCode: "",
                                                                    dateOfBirth: "",
                                                                    countryCode: "",
                                                                });
                                                            }}
                                                        >
                                                            Add Additional Traveller
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </User>
                                )}
                            />
                        </Info>
                        <div className="totalPrice">
                            <Price totalPrice={rrp.toFixed(2)}>
                                <div className="wrapper">
                                    <div className="totalPrice">
                                        <span className="text">Total Price</span>
                                        <span className="value">
                                            <div className="value_input">${rrp.toFixed(2)}</div>
                                        </span>
                                    </div>
                                    <div className="line"></div>
                                    <div className="dash"></div>
                                    <ButtonCheckout
                                        type="submit"
                                        borderRadius="4px"
                                        color={["#436CB2 0%", "#28B7C3 100%"]}
                                    >
                                        Purchase
                                    </ButtonCheckout>
                                </div>
                            </Price>
                        </div>
                    </Form>
                </Container>
            )}
        </Formik>
    );
};
const Container = styled.div`
    background-color: #f2f2f2;
    width: 100%;
    .secondaryActions {
        display: flex;
        justify-content: start;
        margin-top: 19px;

        .btnAddCustomer {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 21px;
            color: ${(props) => props.theme.colors.main};
            cursor: pointer;
            span {
                margin-left: 14px;
            }
        }
    }

    .totalPrice {
        display: flex;
        justify-content: flex-end;
        position: relative; /* Ensure position is relative by default */

        ${breakpoint.breakOnlyMobile`
            position: sticky;
            bottom: 0;
            background-color: white;
            z-index: 100; 
            width: 100%;
        `}
    }

    ${breakpoint.breakTablet`
        background-color: #F2F2F2;
        & > .wrapper {
            display: flex;
            width: ${(p) => p.theme.widths.wide_700};
            margin: 0 auto;
        }
        .totalPrice {
            flex: 350;
        }
        .bottom {
            background-color: transparent;
            padding: 15px 0 0 0;
            .title {
                font-size: 15px;
            }
        }
    `}
    ${breakpoint.breakIpadPro`
        & > .wrapper {
            width: ${(p) => p.theme.widths.wide_900};
        }
    `}
    
    ${breakpoint.breakLarge`
        & > .wrapper {
            width: ${(p) => p.theme.widths.wide_1110};
        }
        .bottom {
            padding: 32px 0 0 0;
            .title {
                font-size: 18px;
            }
        }
    `}
`;

const Info = styled.div`
    padding: 16px;
    ${breakpoint.breakTablet`
        padding: 0 16px 44px 0;
    `}
    ${breakpoint.breakLarge`
            width: ${(p) => p.theme.widths.wide_1110};
    `}
`;

const User = styled.div`
    .listCustomerDetail {
        display: block;
        margin-bottom: 16px;
    }

    .userItem,
    .editUser {
        display: none;

        ${breakpoint.breakTablet`
            display: flex;
        `}
    }
    .saveButton {
        ${breakpoint.breakOnlyMobile`
            display: none;
        `}
    }
`;

const Container2 = styled.div`
    background-color: #fff;
    padding: 28px 0;
    .btnRefresh {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: bold;
        margin: 10px 28px 0;
        span {
            margin-left: 13px;
        }
        color: ${(props) => props.theme.colors.black};
        cursor: pointer;
    }
`;

const Header = styled.div`
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;

    .bin {
        display: block;
        cursor: pointer;
    }

    .heading {
        font-size: 20px;
        font-weight: 700;
    }
`;
const Price = styled.div<{ totalPrice: string }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    .saveButton {
        display: none;
        ${breakpoint.breakOnlyMobile`
            display: block;
        `}
    }

    ${breakpoint.breakIpad`
            width: 260px;
    `}

    .wrapper {
        background-color: #fff;
        padding: 20px 16px;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding-bottom: 12px;
            width: ${(p) => (p.totalPrice.length > 10 ? "315px" : "285px")};
        }
        ${breakpoint.breakLarge`
            width: 350px;
        `}
    }

    .line {
        height: 1px;
        border-bottom: 1px solid #bdbdbd;
        margin: 20px 0;
    }

    .totalPrice {
        display: flex;
        align-items: center;
        justify-content: space-between !important;
        .text {
            font-size: 16px;
            font-weigh: 500;
            line-height: 32px;
        }

        .value {
            font-size: 20px;
            font-weight: 700;
            line-height: 32px;
            color: ${(props) => props.theme.colors.main};
            background-color: #f2f2f2;
            display: flex;
            padding: 8px 10px 8px 10px;
            ${breakpoint.breakIpad`
                padding: 8px 10px 8px 30px;
                height:34px;
                display: flex;
                align-items: center;
            `}
            .value_input {
                display: inline-block;
                min-width: 61px;
                align-items: end;
                input {
                    width: ${(p) => p.totalPrice.length * 13 + "px"};
                    color: ${(props) => props.theme.colors.main};
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }

        .textFooter {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            background-color: #fff;
            color: #4f4f4f;
            padding: 19px 16px 31px 16px;
            ${breakpoint.breakOnlyMobile`
                flex: 1;
            `}
        }

        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) and (max-width: ${(p) =>
                p.theme.breakPoints.breakLarge}) {
            flex: 350;

            table {
                .field {
                    min-width: 100px;
                }

                .field,
                .value {
                    font-size: 12px;
                }

                .commission {
                    font-size: 12px;
                    min-width: 44px;
                }
            }

            .line {
                margin: 10px 0;
            }

            .totalPrice {
                margin: 10px 0;

                .text,
                .value {
                    font-size: 13px;
                }
            }

            .textFooter {
                font-size: 12px;
                line-height: 19px;
                padding: 0px 16px 19px 16px;
            }
        }
    }
`;

const ButtonCheckout = styled(StyledButton)`
    margin-top: 18px;
    margin-bottom: 18px;
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) and (max-width: ${(p) =>
            p.theme.breakPoints.breakLarge}) {
        height: 35px;
    }
`;
const FormContainer = styled.div`
    overflow-y: scroll;

    ${breakpoint.breakTablet`
        overflow-y: unset;
    `}
    ${breakpoint.breakOnlyMobile`
        overflow-y: unset;
    `}
`;
